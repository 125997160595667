import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, View } from "react-native";
import { Button } from "react-native-paper";
import { useQuery } from "react-query";
import MyQuizList from "../components/quiz/MyQuizList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuizRecord } from "../types/Quiz";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function MyQuizesScreen({ navigation }: Props) {
  const { user } = useProfile();

  const getQuizes = async () => {
    const response = await GetRequest("/getQuizes", {
      createdBy: user?._id,
      status: "any",
    });

    return response.data;
  };

  const { isLoading: quizLoading, data: quizes }: { isLoading: boolean; data?: QuizRecord[] } =
    useQuery(`MyQuizes${user?._id}`, getQuizes, {
      enabled: typeof user?._id !== "undefined",
    }); // Should not have stale time

  if (quizLoading) {
    return (
      <View style={{ backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ flex: 1, backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Skeleton width="100%" height={50} />
        </View>
      </View>
    );
  }

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          borderRadius: 20,
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          paddingTop: shouldShowSideBar() ? 0 : 20,
        }}
      >
        {quizes && quizes.length === 0 && (
          <View style={{ marginTop: 20 }}>
            <Text style={{ textAlign: "center" }}>You haven't created any quiz</Text>
            <Button
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              onPress={() => navigation.navigate("QuizCreate")}
              style={{ marginTop: 20 }}
            >
              Start New Quiz
            </Button>
          </View>
        )}
        {quizes && quizes.length > 0 && <MyQuizList quizes={quizes} />}
      </View>
    </Container>
  );
}

export default MyQuizesScreen;

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { Avatar, TouchableRipple } from "react-native-paper";
import { QuestionRecord } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";
import Skeleton from "../utils/Skeleton";

function QuestionList({ questions }: { questions: QuestionRecord[] }) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  if (!questions) {
    return <Skeleton />;
  }

  return (
    <View
      testID="LatestQuestionList"
      style={{
        backgroundColor: "#C4D0FB",
        borderRadius: 20,
        padding: 16,
        paddingTop: 24,
        marginTop: 20,
      }}
    >
      {questions.map((question, index) => (
        <TouchableRipple
          key={question._id}
          rippleColor="rgba(0, 0, 0, .32)"
          style={{
            borderRadius: 20,
          }}
          onPress={() => navigation.navigate("Question", { questionIds: [question._id] })}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              // gap: 10,
              padding: 10,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <View>
              <Avatar.Text
                size={24}
                label={`${index + 1}`}
                color="#6A5AE0"
                style={{ backgroundColor: "#fff" }}
                labelStyle={{ fontWeight: "bold" }}
              />
            </View>
            <View style={{ flex: 1, flexWrap: "wrap" }}>
              <Text
                style={{
                  fontWeight: "500",
                  paddingLeft: 10,
                  maxWidth: "100%",
                }}
              >
                {question.title}
              </Text>
            </View>
            <View>
              <MaterialIcons
                name="arrow-forward-ios"
                size={15}
                color="#6A5AE0"
                style={{ marginTop: 3 }}
              />
            </View>
          </View>
        </TouchableRipple>
      ))}
    </View>
  );
}

export default QuestionList;

const styles = StyleSheet.create({});

import { FontAwesome } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { timeAgo } from "../components/utils/TimeAgo";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen, shouldShowSideBar } from "../scripts/ScreenSize";
import { QuestionRecord } from "../types/Question";
import { RootNativeStackParamList } from "../types/Screens";
import { VoteUserRecord } from "../types/Vote";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuestionPerformances">;
function QuestionPerformanceScreen({ route, navigation }: Props) {
  const [voteStat, setVoteStat] = useState<{
    totalVote: number;
    totalCorrect: number;
    totalWrong: number;
  }>({
    totalVote: 0,
    totalCorrect: 0,
    totalWrong: 0,
  });
  const [optionsSummary, setOptionsSummary] = useState<number[]>([0, 0, 0, 0]);

  const {
    questionId,
  }: {
    questionId?: string;
  } = route.params;

  const getQuestion = async () => {
    const response = await GetRequest("/getQuestion", {
      questionId,
    });
    return response.data;
  };

  const getQuestionVotes = async () => {
    const response = await GetRequest("/getQuestionVotes", {
      questionId,
    });
    return response.data;
  };

  const { isLoading: votesLoading, data: votes }: { isLoading: boolean; data?: VoteUserRecord[] } =
    useQuery(`Votes${questionId}`, getQuestionVotes, {
      enabled: typeof questionId !== "undefined",
      refetchInterval: 5000,
    });

  const {
    isLoading: questionLoading,
    data: question,
  }: { isLoading: boolean; data?: QuestionRecord } = useQuery(
    `Question${questionId}`,
    getQuestion,
    {
      enabled: typeof questionId !== "undefined",
    }
  );

  useEffect(() => {
    const tempOptionsSummary = [...optionsSummary];
    const tempVoteStat = { ...voteStat };

    if (votes) {
      for (const vote of votes) {
        if (typeof vote.vote !== "undefined") {
          tempOptionsSummary[vote.vote]++;
        }

        tempVoteStat.totalVote++;
        if (vote.result === true) {
          tempVoteStat.totalCorrect++;
        } else {
          tempVoteStat.totalWrong++;
        }
      }

      setVoteStat(tempVoteStat);

      setOptionsSummary(tempOptionsSummary);
    }
  }, [votes]);

  if (questionLoading || votesLoading) {
    return (
      <Container>
        <ScrollView
          style={{
            flex: 1,
            backgroundColor: "#FFF",
            padding: 20,
            margin: 20,
            marginTop: shouldShowSideBar() ? 0 : 20,
            borderRadius: 20,
          }}
        >
          <Skeleton width="100%" height={500} />
        </ScrollView>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View testID="QuestionPerformanceScreen" style={{ flex: 1, gap: 10 }}>
          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Question Performance</Text>
          </View>

          <View style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <View style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Text style={{ fontWeight: "bold" }}>Question</Text>
              <Text>{question?.title}</Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Text style={{ fontWeight: "bold" }}>Correct Answer</Text>
              <Text>{question?.answerText}</Text>
            </View>

            <Text style={{ fontWeight: "bold" }}>Vote Result Summary</Text>

            <View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <View style={{ flex: 1, borderWidth: 1, borderColor: "#CCC" }}>
                <Text
                  style={{
                    flex: 1,
                    backgroundColor: "#E8E5FA",
                    padding: 10,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total Vote
                </Text>
                <Text style={{ textAlign: "center", padding: 10 }}>{voteStat.totalVote}</Text>
              </View>

              <View style={{ flex: 1, borderWidth: 1, borderColor: "#CCC" }}>
                <Text
                  style={{
                    flex: 1,
                    backgroundColor: "#E8E5FA",
                    padding: 10,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total Correct
                </Text>
                <Text style={{ textAlign: "center", padding: 10 }}>{voteStat.totalCorrect}</Text>
              </View>

              <View style={{ flex: 1, borderWidth: 1, borderColor: "#CCC" }}>
                <Text
                  style={{
                    flex: 1,
                    backgroundColor: "#E8E5FA",
                    padding: 10,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total Wrong
                </Text>
                <Text style={{ textAlign: "center", padding: 10 }}>{voteStat.totalWrong}</Text>
              </View>
            </View>

            <View style={{ display: "flex", gap: 8 }}>
              <Text style={{ fontWeight: "bold" }}>Options Votes</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  borderWidth: 1,
                  borderColor: `${question?.answer === 0 ? "#53DF83" : "#CCC"}`,
                }}
              >
                <Text
                  style={{
                    flex: 10,
                    backgroundColor: `${question?.answer === 0 ? "#B5F1C9" : "#E8E5FA"}`,
                    padding: 10,
                  }}
                >
                  {question?.options[0]}
                </Text>
                <Text style={{ flex: 1, padding: 10, textAlign: "center" }}>
                  {" "}
                  x {optionsSummary[0]}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  borderWidth: 1,
                  borderColor: `${question?.answer === 1 ? "#53DF83" : "#CCC"}`,
                }}
              >
                <Text
                  style={{
                    flex: 10,
                    backgroundColor: `${question?.answer === 1 ? "#B5F1C9" : "#E8E5FA"}`,
                    padding: 10,
                  }}
                >
                  {question?.options[1]}
                </Text>
                <Text style={{ flex: 1, padding: 10, textAlign: "center" }}>
                  {" "}
                  x {optionsSummary[1]}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  borderWidth: 1,
                  borderColor: `${question?.answer === 2 ? "#53DF83" : "#CCC"}`,
                }}
              >
                <Text
                  style={{
                    flex: 10,
                    backgroundColor: `${question?.answer === 2 ? "#B5F1C9" : "#E8E5FA"}`,
                    padding: 10,
                  }}
                >
                  {question?.options[2]}
                </Text>
                <Text style={{ flex: 1, padding: 10, textAlign: "center" }}>
                  {" "}
                  x {optionsSummary[2]}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  borderWidth: 1,
                  borderColor: `${question?.answer === 3 ? "#53DF83" : "#CCC"}`,
                }}
              >
                <Text
                  style={{
                    flex: 10,
                    backgroundColor: `${question?.answer === 3 ? "#B5F1C9" : "#E8E5FA"}`,
                    padding: 10,
                  }}
                >
                  {question?.options[3]}
                </Text>
                <Text style={{ flex: 1, padding: 10, textAlign: "center" }}>
                  {" "}
                  x {optionsSummary[3]}
                </Text>
              </View>
            </View>
          </View>

          <View>
            <Text style={{ fontWeight: "bold" }}>Latest Votes</Text>
            <Text style={{ color: "#CCC", fontSize: 10 }}>
              *Points earn from each question depend on how fast user correctly answer the question
            </Text>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                // backgroundColor: "#E8E5FA",
                padding: 2,
                borderRadius: 20,
                justifyContent: "space-around",
                marginTop: 10,
                gap: 2,
              }}
            >
              <View style={styles.card}>
                <Text style={styles.cardValue}>#</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>User</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Vote</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Result</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Points</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Date</Text>
              </View>
            </View>
            {votesLoading && <Skeleton width={"100%"} />}
            {votes &&
              votes
                .sort((a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1))
                .map((vote, index) => (
                  <View
                    key={vote._id}
                    style={{
                      flexDirection: "row",
                      padding: 2,
                      justifyContent: "space-around",
                      gap: 2,
                    }}
                  >
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{index + 1}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{vote.user?.username}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>
                        {typeof vote.vote !== "undefined" ? question?.options[vote.vote] : "-"}
                      </Text>
                    </View>
                    <View style={[styles.card]}>
                      <Text style={styles.cardLabel}>
                        {vote.result ? (
                          isWebBigScreen() ? (
                            `Correct`
                          ) : (
                            <FontAwesome name="check" size={18} color="#53DF83" />
                          )
                        ) : isWebBigScreen() ? (
                          `Wrong`
                        ) : (
                          <FontAwesome name="close" size={18} color="#FF6666" />
                        )}
                      </Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{Math.round(vote.points)}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{timeAgo(vote.createdAt)}</Text>
                    </View>
                  </View>
                ))}
          </View>
          {votes && votes.length === 0 && (
            <View>
              <Text style={{ textAlign: "center" }}>No one have taken test yet</Text>
            </View>
          )}
        </View>
      </ScrollView>
    </Container>
  );
}

export default QuestionPerformanceScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
  card: {
    backgroundColor: "#E8E5FA",
    padding: 5,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-paper";
import useProfile from "../../scripts/userProfile";
import { Leaderboard } from "../../types/Leaderboard";
import { RootNativeStackParamList } from "../../types/Screens";

interface Props {
  leaderboard: Leaderboard[];
}

function LeaderboardList({ leaderboard }: Props) {
  const [myStat, setMyStat] = useState<Leaderboard>();
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  const { user } = useProfile();

  useEffect(() => {
    if (leaderboard) {
      const myLeaderboard = leaderboard.find((stat) => stat.userId === (user?._id || ""));
      setMyStat(myLeaderboard);
    }
  }, [leaderboard]);

  const Item = ({ stat }: { stat: Leaderboard }) => {
    if (!stat) {
      return <></>;
    }
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate("Profile", { userId: stat.userId })}
        key={stat.userId}
        style={{
          flexDirection: "row",
          backgroundColor: "#fff",
          borderRadius: 20,
          gap: 20,
          alignItems: "center",
          padding: 15,
          borderWidth: stat.userId === user?._id ? 2 : 0,
          borderColor: stat.userId === user?._id ? "#6A5AE0" : "#FFF",
        }}
      >
        <View>
          <Avatar.Text
            label={stat.rank?.toString() || ""}
            size={20}
            style={{ borderWidth: 1, borderColor: "#E6E6E6", backgroundColor: "#fff" }}
          />
        </View>
        <View>
          <Avatar.Text
            size={50}
            label={
              stat.username?.slice(0, 2).toLocaleUpperCase() ||
              stat.userId.slice(-2).toLocaleUpperCase()
            }
            labelStyle={{ marginTop: -3 }}
          />
        </View>
        <View>
          <Text style={{ fontWeight: "700" }}>{stat.username || stat.userId.slice(-5)}</Text>
          <Text style={{ marginTop: 5, color: "#858494" }}>
            {Math.round(stat.correctPoints)} points
          </Text>
        </View>
        {stat.userId === user?._id && (
          <View style={{ marginLeft: "auto" }}>
            <Text style={{ fontWeight: "bold", color: "#6A5AE0" }}>YOU</Text>
          </View>
        )}
      </TouchableOpacity>
    );
  };

  if (!leaderboard || leaderboard.length === 0) {
    return (
      <View testID="NoLeaderBoardList">
        <Text>No Records of leaderboard</Text>
      </View>
    );
  }

  return (
    <View testID="LeaderboardList" style={{ gap: 10 }}>
      {myStat && <Item stat={myStat} />}
      {leaderboard.map((stat) => (
        <Item key={stat.userId} stat={stat} />
      ))}
    </View>
  );
}

export default LeaderboardList;

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import { Button, Switch } from "react-native-paper";
import { useMutation } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";
import { UserUpdate } from "../types/User";

const SCREEN_WIDTH = Dimensions.get("window").width;

interface UserForm {
  language?: string;
  autoTranslate?: boolean;
}

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function OtherSettingsScreen({ navigation }: Props) {
  const [userForm, setUserForm] = useState<UserForm>({});

  const languages = [
    {
      label: "Afghanistan",
      value: "af",
    },
    {
      label: "AK",
      value: "ak",
    },
    {
      label: "Armenia",
      value: "am",
    },
    {
      label: "Argentina",
      value: "ar",
    },
    {
      label: "American Samoa",
      value: "as",
    },
    {
      label: "AY",
      value: "ay",
    },
    {
      label: "Azerbaijan",
      value: "az",
    },
    {
      label: "Belgium",
      value: "be",
    },
    {
      label: "Bulgaria",
      value: "bg",
    },
    {
      label: "Bermuda",
      value: "bm",
    },
    {
      label: "Brunei",
      value: "bn",
    },
    {
      label: "Bahamas",
      value: "bs",
    },
    {
      label: "Canada",
      value: "ca",
    },
    {
      label: "Colombia",
      value: "co",
    },
    {
      label: "Serbia",
      value: "cs",
    },
    {
      label: "Cyprus",
      value: "cy",
    },
    {
      label: "DA",
      value: "da",
    },
    {
      label: "Germany",
      value: "de",
    },
    {
      label: "DV",
      value: "dv",
    },
    {
      label: "Estonia",
      value: "ee",
    },
    {
      label: "EL",
      value: "el",
    },
    {
      label: "English",
      value: "en",
    },
    {
      label: "EO",
      value: "eo",
    },
    {
      label: "Spain",
      value: "es",
    },
    {
      label: "Ethiopia",
      value: "et",
    },
    {
      label: "European Union",
      value: "eu",
    },
    {
      label: "FA",
      value: "fa",
    },
    {
      label: "Finland",
      value: "fi",
    },
    {
      label: "France",
      value: "fr",
    },
    {
      label: "FY",
      value: "fy",
    },
    {
      label: "Gabon",
      value: "ga",
    },
    {
      label: "Grenada",
      value: "gd",
    },
    {
      label: "Greenland",
      value: "gl",
    },
    {
      label: "Guinea",
      value: "gn",
    },
    {
      label: "Guam",
      value: "gu",
    },
    {
      label: "HA",
      value: "ha",
    },
    {
      label: "HE",
      value: "he",
    },
    {
      label: "HI",
      value: "hi",
    },
    {
      label: "Croatia",
      value: "hr",
    },
    {
      label: "Haiti",
      value: "ht",
    },
    {
      label: "Hungary",
      value: "hu",
    },
    {
      label: "HY",
      value: "hy",
    },
    {
      label: "Indonesia",
      value: "id",
    },
    {
      label: "IG",
      value: "ig",
    },
    {
      label: "Iceland",
      value: "is",
    },
    {
      label: "Italy",
      value: "it",
    },
    {
      label: "IW",
      value: "iw",
    },
    {
      label: "JA",
      value: "ja",
    },
    {
      label: "JV",
      value: "jv",
    },
    {
      label: "JW",
      value: "jw",
    },
    {
      label: "KA",
      value: "ka",
    },
    {
      label: "KK",
      value: "kk",
    },
    {
      label: "Comoros",
      value: "km",
    },
    {
      label: "St. Kitts & Nevis",
      value: "kn",
    },
    {
      label: "KO",
      value: "ko",
    },
    {
      label: "KU",
      value: "ku",
    },
    {
      label: "Cayman Islands",
      value: "ky",
    },
    {
      label: "Laos",
      value: "la",
    },
    {
      label: "Lebanon",
      value: "lb",
    },
    {
      label: "LG",
      value: "lg",
    },
    {
      label: "LN",
      value: "ln",
    },
    {
      label: "LO",
      value: "lo",
    },
    {
      label: "Lithuania",
      value: "lt",
    },
    {
      label: "Latvia",
      value: "lv",
    },
    {
      label: "Madagascar",
      value: "mg",
    },
    {
      label: "MI",
      value: "mi",
    },
    {
      label: "North Macedonia",
      value: "mk",
    },
    {
      label: "Mali",
      value: "ml",
    },
    {
      label: "Mongolia",
      value: "mn",
    },
    {
      label: "Mauritania",
      value: "mr",
    },
    {
      label: "Montserrat",
      value: "ms",
    },
    {
      label: "Malta",
      value: "mt",
    },
    {
      label: "Malaysia",
      value: "my",
    },
    {
      label: "Niger",
      value: "ne",
    },
    {
      label: "Netherlands",
      value: "nl",
    },
    {
      label: "Norway",
      value: "no",
    },
    {
      label: "NY",
      value: "ny",
    },
    {
      label: "Oman",
      value: "om",
    },
    {
      label: "OR",
      value: "or",
    },
    {
      label: "Panama",
      value: "pa",
    },
    {
      label: "Poland",
      value: "pl",
    },
    {
      label: "Palestine",
      value: "ps",
    },
    {
      label: "Portugal",
      value: "pt",
    },
    {
      label: "QU",
      value: "qu",
    },
    {
      label: "Romania",
      value: "ro",
    },
    {
      label: "Russia",
      value: "ru",
    },
    {
      label: "Rwanda",
      value: "rw",
    },
    {
      label: "Saudi Arabia",
      value: "sa",
    },
    {
      label: "Sudan",
      value: "sd",
    },
    {
      label: "Slovenia",
      value: "si",
    },
    {
      label: "Slovakia",
      value: "sk",
    },
    {
      label: "Sierra Leone",
      value: "sl",
    },
    {
      label: "San Marino",
      value: "sm",
    },
    {
      label: "Senegal",
      value: "sn",
    },
    {
      label: "Somalia",
      value: "so",
    },
    {
      label: "SQ",
      value: "sq",
    },
    {
      label: "Suriname",
      value: "sr",
    },
    {
      label: "São Tomé & Príncipe",
      value: "st",
    },
    {
      label: "Russia",
      value: "su",
    },
    {
      label: "El Salvador",
      value: "sv",
    },
    {
      label: "SW",
      value: "sw",
    },
    {
      label: "Tristan da Cunha",
      value: "ta",
    },
    {
      label: "TE",
      value: "te",
    },
    {
      label: "Togo",
      value: "tg",
    },
    {
      label: "Thailand",
      value: "th",
    },
    {
      label: "TI",
      value: "ti",
    },
    {
      label: "Tokelau",
      value: "tk",
    },
    {
      label: "Timor-Leste",
      value: "tl",
    },
    {
      label: "Türkiye",
      value: "tr",
    },
    {
      label: "TS",
      value: "ts",
    },
    {
      label: "Trinidad & Tobago",
      value: "tt",
    },
    {
      label: "Uganda",
      value: "ug",
    },
    {
      label: "United Kingdom",
      value: "uk",
    },
    {
      label: "UR",
      value: "ur",
    },
    {
      label: "Uzbekistan",
      value: "uz",
    },
    {
      label: "U.S. Virgin Islands",
      value: "vi",
    },
    {
      label: "XH",
      value: "xh",
    },
    {
      label: "YI",
      value: "yi",
    },
    {
      label: "YO",
      value: "yo",
    },
    {
      label: "ZH",
      value: "zh",
    },
    {
      label: "ZU",
      value: "zu",
    },
  ];

  const { user, setUser, showSnackBar } = useProfile();

  useEffect(() => {
    setUserForm({
      language: user?.language || "en",
      autoTranslate: typeof user?.autoTranslate === "undefined" ? false : user?.autoTranslate,
    });
  }, [user]);

  const updateProfileMutation = useMutation({
    mutationFn: async (userProfile: UserUpdate) => {
      return await PostRequest(`/updateUser?_id=${user?._id}`, userProfile);
    },
    onSuccess: () => {
      showSnackBar({ type: "SUCCESS", message: "Setting updated!" });
    },
  });

  const onUpdatePress = () => {
    updateProfileMutation.mutate(userForm);

    if (user) {
      setUser({
        ...user,
        language: userForm.language,
        autoTranslate: userForm.autoTranslate,
      });
    }
  };

  const onAutoTranslateChange = () => {
    const tempAutoTranslate = !userForm.autoTranslate;
    const updatedForm = { ...userForm, autoTranslate: tempAutoTranslate };
    setUserForm(updatedForm);
  };

  if (typeof user === "undefined") {
    return (
      <View
        style={{
          flex: 1,
          gap: 10,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 60 : 120,
        }}
      >
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={20} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={20} />
      </View>
    );
  }

  return (
    <Container>
      <View
        testID="OtherSettingsScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Translation Language</Text>
            <Dropdown
              placeholder="Select language"
              style={[styles.dropdown]}
              data={languages}
              labelField="label"
              valueField="value"
              value={userForm.language}
              onChange={(item) => {
                setUserForm({ ...userForm, language: item.value });
              }}
              search
              searchPlaceholder="Search..."
            />
          </View>
          <View>
            <Text style={styles.label}>Auto Translate?</Text>
            <Switch value={userForm.autoTranslate} onValueChange={onAutoTranslateChange} />
          </View>

          <View>
            <Button
              testID="UpdateBtn"
              style={styles.button}
              mode="contained"
              buttonColor="#6A5AE0"
              labelStyle={{ color: "#fff" }}
              onPress={() => onUpdatePress()}
            >
              Update
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
}

export default OtherSettingsScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "#fff",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },

  dropdown: {
    height: 50,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
});

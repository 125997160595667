import { StyleSheet, Text, View } from "react-native";
import Svg, { Defs, LinearGradient, Path, Rect, Stop } from "react-native-svg";
import { Leaderboard } from "../../types/Leaderboard";
import Skeleton from "../utils/Skeleton";

interface Props {
  loading: boolean;
  leaderboard: Leaderboard[];
}

const UsernamePoint = ({ leaderboard, index }: { leaderboard: Leaderboard[]; index: number }) => {
  if (!leaderboard || !leaderboard[index]) {
    return (
      <>
        <Text style={styles.username}>-</Text>
        <Text style={styles.point}>- P</Text>
      </>
    );
  }

  return (
    <>
      <Text style={styles.username}>
        {leaderboard[index].username || leaderboard[index].userId.slice(-5)}
      </Text>
      <Text style={styles.point}>{Math.round(leaderboard[index].correctPoints || 0)} P</Text>
    </>
  );
};

function LeaderboardStanding({ leaderboard, loading }: Props) {
  return (
    <View style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "center" }}>
      <View style={{ alignItems: "center" }}>
        {loading ? (
          <Skeleton width={80} height={20} />
        ) : (
          <UsernamePoint leaderboard={leaderboard} index={1} />
        )}
        <Svg width="90" height="180" viewBox="0 0 104 180" fill="none">
          <Rect y="16" width="104" height="164" fill="#9087E5" />
          <Path d="M14.1818 0H104V16H0L14.1818 0Z" fill="#AEA7EC" />
          <Path
            d="M29.2491 98H72.4491V88.16H50.2091L61.0091 77.6C67.6491 71.2 72.0491 64.96 72.0491 57.36C72.0491 47.04 64.8491 40 52.2091 40C39.4091 40 31.4091 47.2 30.7691 60.4H42.5291C43.4091 52.88 46.6091 49.92 51.6491 49.92C56.6091 49.92 59.2491 52.96 59.2491 57.68C59.2491 62.88 55.7291 67.92 50.2091 73.76L29.2491 95.92V98Z"
            fill="white"
          />
        </Svg>
      </View>
      <View style={{ alignItems: "center" }}>
        {loading ? (
          <Skeleton width={80} height={20} />
        ) : (
          <UsernamePoint leaderboard={leaderboard} index={0} />
        )}
        <Svg width="90" height="262" viewBox="0 0 110 262" fill="none">
          <Rect y="16" width="110" height="246" fill="url(#paint0_linear_135_5933)" />
          <Path d="M16 0H94L110 16H0L16 0Z" fill="#CDC9F3" />
          <Path
            d="M51.1668 118H67.3668V46.5H53.1668L36.6668 58.2V72L51.1668 62.5V118Z"
            fill="white"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear_135_5933"
              x1="55"
              y1="16"
              x2="55"
              y2="189"
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#9087E5" />
              <Stop offset="1" stopColor="#CDC9F3" />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
      <View style={{ alignItems: "center" }}>
        {loading ? (
          <Skeleton width={60} height={20} />
        ) : (
          <UsernamePoint leaderboard={leaderboard} index={2} />
        )}
        <Svg width="90" height="180" viewBox="0 0 104 180" fill="none">
          <Rect y="16" width="104" height="164" fill="#9087E5" />
          <Path d="M0 0H88.8727L104 16H0L0 0Z" fill="#AEA7EC" />
          <Path
            d="M51.237 77.66C61.677 77.66 67.857 72.56 67.857 64.52C67.857 59.06 64.497 55.52 59.517 54.44C63.777 52.94 66.597 49.94 66.597 45.14C66.597 38.42 61.077 33.5 51.657 33.5C41.877 33.5 36.297 39.26 35.817 47.12H44.397C44.877 43.1 47.397 40.64 51.477 40.64C55.437 40.64 57.417 42.92 57.417 45.98C57.417 49.64 55.377 51.68 50.877 51.68H47.757V58.16H51.117C55.737 58.16 58.017 60.26 58.017 64.34C58.017 68.18 55.737 70.52 50.877 70.52C45.717 70.52 43.497 67.52 43.137 63.38H34.377C34.737 72.08 40.317 77.66 51.237 77.66Z"
            fill="white"
          />
        </Svg>
      </View>
    </View>
  );
}

export default LeaderboardStanding;

const styles = StyleSheet.create({
  tab: {
    padding: 5,
    borderRadius: 15,
    color: "#fff",
    flex: 1,
  },
  tabText: {
    color: "#fff",
    textAlign: "center",
  },
  tabActive: {
    backgroundColor: "#9087E5",
  },
  username: {
    color: "#fff",
    textAlign: "center",
  },
  point: {
    color: "#fff",
    textAlign: "center",
    backgroundColor: "#9087E5",
    borderRadius: 10,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
  },
});

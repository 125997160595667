import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { isWebBigScreen } from "../../scripts/ScreenSize";
import { QuizQuestionsVotesRecord } from "../../types/Quiz";
import { RootNativeStackParamList } from "../../types/Screens";

function QuestionsPerformance({ questionsVotes }: { questionsVotes: QuizQuestionsVotesRecord[] }) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  return (
    <View>
      <View>
        <View
          style={{
            flexDirection: "row",
            padding: 2,
            borderRadius: 20,
            justifyContent: "space-around",
            marginTop: 10,
            gap: 2,
          }}
        >
          <View style={styles.card}>
            <Text style={styles.cardValue}>#</Text>
          </View>
          <View style={[styles.card, { flex: 4 }]}>
            <Text style={styles.cardValue}>Question</Text>
          </View>
          <View style={styles.card}>
            <Text style={styles.cardValue}>
              {isWebBigScreen() ? (
                `Vote`
              ) : (
                <MaterialCommunityIcons name="vote" size={18} color="black" />
              )}
            </Text>
          </View>
          <View style={styles.card}>
            <Text style={styles.cardValue}>
              {isWebBigScreen() ? `Correct` : <FontAwesome name="check" size={18} color="black" />}
            </Text>
          </View>
          <View style={styles.card}>
            <Text style={styles.cardValue}>
              {isWebBigScreen() ? `Wrong` : <FontAwesome name="close" size={18} color="black" />}
            </Text>
          </View>
          <View style={styles.card}>
            <Text style={styles.cardValue}></Text>
          </View>
        </View>
        {questionsVotes &&
          questionsVotes.map((questionsVote, index) => (
            <View
              key={questionsVote.questions._id}
              style={{
                flexDirection: "row",
                padding: 2,
                justifyContent: "space-around",
                gap: 2,
              }}
            >
              <View style={styles.card}>
                <Text style={styles.cardLabel}>{index + 1}</Text>
              </View>
              <View style={[styles.card, { flex: 4 }]}>
                <Text style={styles.cardLabel}>{questionsVote.questions.title}</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>{questionsVote.performance?.totalVote}</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>{questionsVote.performance?.totalCorrect}</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>{questionsVote.performance?.totalWrong}</Text>
              </View>
              <View style={[styles.card]}>
                <TouchableOpacity
                  style={
                    {
                      // borderColor: "#6A5AE0",
                      // borderWidth: 1,
                      // borderRadius: 10,
                      // padding: 3,
                    }
                  }
                  onPress={() =>
                    navigation.navigate("QuestionPerformances", {
                      questionId: questionsVote.questions._id,
                    })
                  }
                >
                  {isWebBigScreen() ? (
                    <Text
                      style={[
                        styles.cardLabel,
                        {
                          color: "#6A5AE0",
                          borderColor: "#6A5AE0",
                          borderWidth: 1,
                          borderRadius: 10,
                          padding: 3,
                        },
                      ]}
                    >
                      View Detail
                    </Text>
                  ) : (
                    <MaterialCommunityIcons name="file-search-outline" size={18} color="black" />
                  )}
                </TouchableOpacity>
              </View>
            </View>
          ))}
      </View>
    </View>
  );
}

export default QuestionsPerformance;

const styles = StyleSheet.create({
  card: {
    backgroundColor: isWebBigScreen() ? "#E8E5FA" : "#E8E5FA",
    padding: 5,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

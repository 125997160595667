import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Dimensions, Linking, Platform, StyleSheet, Text } from "react-native";
import { PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "sentry-expo";
import VersionCheck from "./components/commons/VersionCheck";
import { StackScreens } from "./screens/Screens";
import { logEvent } from "./scripts/analytic";
import { shouldHideTabBar } from "./scripts/ScreenSize";
import { ProfileProvider } from "./scripts/UserContext";

const SCREEN_WIDTH = Dimensions.get("window").width;

Sentry.init({
  dsn: "https://365080fd07b2b6fc241e9e79e4fc91d4@o136128.ingest.sentry.io/4505910363029505",
  enableInExpoDevelopment: true,
  debug: process.env.NODE_ENV === "development" ? true : false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

const PERSISTENCE_KEY = "NAVIGATION_STATE_V1"; // Navigation state persistance during dev https://reactnavigation.org/docs/state-persistence/

const queryClient = new QueryClient();

const Tab = createBottomTabNavigator();

const HomeStackScreens = () => {
  return <StackScreens initialRoute="Home" />;
};

const SettingStackScreens = () => {
  return <StackScreens initialRoute="Setting" />;
};

const LearnStackScreens = () => {
  return <StackScreens initialRoute="LearnTags" />;
};

const TrueFalseStackScreens = () => {
  return <StackScreens initialRoute="TrueFalseTags" />;
};

const LeaderboardStackScreens = () => {
  return <StackScreens initialRoute="UserLeaderboard" />;
};

export default function App() {
  // Nav State persistance during dev start https://reactnavigation.org/docs/state-persistence/
  const [isReady, setIsReady] = React.useState(__DEV__ ? false : true);
  const [initialState, setInitialState] = React.useState();
  const routeNameRef = React.useRef(null);
  const navigationRef = React.useRef<any>(null);

  // https://reactnavigation.org/docs/configuring-links/
  const linking = {
    prefixes: [
      /* your linking prefixes */
    ],
    config: {
      /* configuration for matching screens with paths */
      screens: {
        HomeTab: {
          initialRouteName: "Home",
          screens: {
            Quiz: "quiz/:_id",
            QuizNew: "quiz/new",
            QuizCreate: "quiz/create",
            QuizEdit: "quiz/edit/:_id",
            QuizJoin: "quiz/join",
            QuizLeaderboard: "quiz/leaderboard/:quizId",
            QuizPerformances: "quiz/performance/:quizId",
            QuestionPerformances: "question/performance/:questionId",
            MyQuizes: "myquizes",
            Profile: "profile/:userId",
          },
        },
      },
    },
  };

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        // if (Platform.OS !== "web" && initialUrl == null) { // It seems we don't need to exclude this and it seems to work.
        // Only restore state if there's no deep link and we're not on web
        const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
        const state = savedStateString ? JSON.parse(savedStateString) : undefined;

        if (state !== undefined) {
          setInitialState(state);
        }
        // }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }
  // nav state persistance during dev end

  return (
    <QueryClientProvider client={queryClient}>
      <ProfileProvider>
        <PaperProvider>
          <SafeAreaProvider style={Platform.OS === "web" ? styles.web : styles.app}>
            {Platform.OS !== "web" && <VersionCheck />}
            <Helmet>
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1287311612016406"
                crossOrigin="anonymous"
              ></script>
            </Helmet>

            <NavigationContainer
              //@ts-expect-error
              linking={linking}
              fallback={<Text>Loading...</Text>}
              ref={navigationRef}
              initialState={initialState} // https://reactnavigation.org/docs/state-persistence/
              onReady={() => {
                routeNameRef.current = navigationRef.current.getCurrentRoute().name;
              }}
              onStateChange={(state) => {
                AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));

                // https://reactnavigation.org/docs/screen-tracking/

                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute().name;

                if (previousRouteName !== currentRouteName) {
                  logEvent("view_screen", {
                    screen: currentRouteName,
                    prevScreen: previousRouteName,
                  });
                }

                // Save the current route name for later comparison
                routeNameRef.current = currentRouteName;
              }}
            >
              <Tab.Navigator
                screenOptions={{
                  headerShown: false,
                  headerStyle: {
                    backgroundColor: "#f4511e",
                  },
                }}
              >
                <Tab.Screen
                  name="HomeTab"
                  component={HomeStackScreens}
                  options={{
                    title: "",
                    tabBarIcon: ({ focused, color, size }) => (
                      <Ionicons
                        name="home-outline"
                        size={24}
                        color={focused ? "#0C092A" : "#CCC"}
                      />
                    ),
                    tabBarStyle: {
                      display: shouldHideTabBar() ? "none" : "flex",
                    },
                  }}
                />
                <Tab.Screen
                  name="LearnTab"
                  component={LearnStackScreens}
                  options={{
                    title: "",
                    tabBarIcon: ({ focused, color, size }) => (
                      <MaterialCommunityIcons
                        name="school-outline"
                        size={30}
                        color={focused ? "#0C092A" : "#CCC"}
                      />
                    ),
                  }}
                />
                <Tab.Screen
                  name="LeaderboardTab"
                  component={LeaderboardStackScreens}
                  options={{
                    title: "",
                    tabBarIcon: ({ focused, color, size }) => (
                      <MaterialIcons
                        name="leaderboard"
                        size={30}
                        color={focused ? "#0C092A" : "#CCC"}
                      />
                    ),
                  }}
                />
                <Tab.Screen
                  name="Truefalse"
                  component={TrueFalseStackScreens}
                  options={{
                    title: "",
                    tabBarIcon: ({ focused, color, size }) => (
                      <Feather name="check-circle" size={24} color={focused ? "#0C092A" : "#CCC"} />
                    ),
                  }}
                />
                <Tab.Screen
                  name="SettingTab"
                  component={SettingStackScreens}
                  options={{
                    title: "",
                    tabBarIcon: ({ focused, color, size }) => (
                      <Ionicons
                        name="settings-outline"
                        size={24}
                        color={focused ? "#0C092A" : "#CCC"}
                      />
                    ),
                  }}
                />
              </Tab.Navigator>
            </NavigationContainer>
          </SafeAreaProvider>
        </PaperProvider>
      </ProfileProvider>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  web: {
    width: SCREEN_WIDTH > 1024 ? 1024 : SCREEN_WIDTH,
    alignSelf: "center",
    overflow: "hidden",
  },
  app: {},
});

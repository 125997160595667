import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "ChangePassword">;
const ChangePasswordScreen = ({ navigation, route }: Props) => {
  const [resetCode, setResetCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const { email }: { email: string } = route.params;

  const { showSnackBar, setUser } = useProfile();

  const onChangePasswordPress = async () => {
    if (password.length === 0) {
      return showSnackBar({ type: "WARNING", message: "Please enter password" });
    }

    if (confirmPassword.length === 0) {
      return showSnackBar({ type: "WARNING", message: "Please enter confirm password" });
    }

    if (password !== confirmPassword) {
      return showSnackBar({
        type: "ERROR",
        message: "Password and Confirm Password should be same",
      });
    }

    if (resetCode.length === 0) {
      return showSnackBar({ type: "WARNING", message: "Please enter reset code" });
    }

    try {
      const response = await PostRequest("/changePassword", { password, email, resetCode });

      await AsyncStorage.setItem("accessToken", response.data.accessToken);
      setUser(response.data.user);

      showSnackBar({ type: "SUCCESS", message: "Password Updated Successfully" });

      navigation.navigate("Setting");
    } catch (e: any) {
      showSnackBar({ type: "ERROR", messageCode: e.response?.data?.errorCode || "" });
    }
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 0 : 120,
        }}
        testID="ChangePasswordScreen"
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Email</Text>
            <View
              style={{
                padding: 15,
                borderColor: "#6A5AE0",
                borderWidth: 1,
                borderRadius: 15,
                marginTop: 10,
              }}
            >
              <Text style={{ color: "#CCC" }}>{email}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.label}>Reset Code</Text>
            <TextInput
              left={<TextInput.Icon icon="lastpass" iconColor="#6A5AE0" />}
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Reset Code"
              value={resetCode}
              testID="ResetCodeInput"
              onChangeText={(text) => setResetCode(text)}
            />
            <Text style={{ color: "#CCC" }}>
              Reset code you receive in your email. If you did not receive, please request again
              from{" "}
              <Text
                onPress={() => navigation.navigate("ForgetPassword")}
                style={{ color: "#6A5AE0" }}
              >
                here
              </Text>
            </Text>
          </View>
          <View>
            <Text style={styles.label}>New Password</Text>
            <TextInput
              left={<TextInput.Icon icon="eye" iconColor="#6A5AE0" />}
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="New Password"
              value={password}
              testID="NewPasswordInput"
              onChangeText={(text) => setPassword(text)}
            />
          </View>
          <View>
            <Text style={styles.label}>Confirm Password</Text>
            <TextInput
              left={<TextInput.Icon icon="eye" iconColor="#6A5AE0" />}
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Confirm password"
              value={confirmPassword}
              testID="ConfirmPasswordInput"
              onChangeText={(text) => setConfirmPassword(text)}
            />
          </View>
          <View>
            <Button
              style={styles.button}
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              onPress={onChangePasswordPress}
              testID="ChangePassBtn"
            >
              Change Password
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
};

export default ChangePasswordScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "600",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    color: "red",
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

import { AntDesign, Entypo } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { shuffle } from "lodash";
import { useState } from "react";
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useMutation, useQuery } from "react-query";
import Container from "../components/utils/Container";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuizRecord } from "../types/Quiz";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuestionCreateManual">;
function QuestionCreateManualScreen({ navigation, route }: Props) {
  const [title, setTitle] = useState<string>("");
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  const [wrongOptions, setWrongOptions] = useState<string[]>(["", "", "", ""]);
  const { quizId } = route.params;
  const { user, showSnackBar } = useProfile();

  const reset = () => {
    setTitle("");
    setCorrectAnswer("");
    setWrongOptions(["", "", "", ""]);
  };

  const getQuizes = async () => {
    const response = await GetRequest("/getQuizes", { _id: quizId, status: "any" });
    return response.data[0];
  };

  const { isLoading, data: quiz }: { isLoading: boolean; data?: QuizRecord } = useQuery(
    `quizes${quizId}`,
    getQuizes,
    {
      staleTime: 60,
      enabled: typeof quizId !== "undefined",
    }
  );

  const updateQuiz = useMutation({
    mutationFn: async (param: { _id: string; userId: string; questions: string[] }) => {
      return await PostRequest(`/updateQuiz`, param);
    },
  });

  const createQuestion = useMutation({
    mutationFn: async (param: {
      title: string;
      answer: number;
      options: string[];
      createdBy: string;
    }) => {
      return await PostRequest(`/createQuestion`, param);
    },
    onSuccess: (response) => {
      const questions = quiz?.questions.map((question) => question._id) || [];
      questions.push(response.data._id);

      // Update Quiz
      updateQuiz.mutate(
        { _id: quizId, userId: user?._id || "", questions },
        {
          onSuccess: () => {
            navigation.navigate("QuizEdit", { _id: quizId });
          },
        }
      );
    },
  });

  const onWrongOptionsChange = (option: string, index: number) => {
    const tempOptions = [...wrongOptions];
    tempOptions[index] = option;

    setWrongOptions(tempOptions);
  };

  const onAddQuestionPress = () => {
    if (title.length < 10) {
      showSnackBar({ type: "WARNING", message: "Question title too short" });
      return;
    }

    if (correctAnswer.length === 0) {
      showSnackBar({ type: "WARNING", message: "Please provide correct answer" });
      return;
    }

    if (
      wrongOptions[1].length === 0 ||
      wrongOptions[2].length === 0 ||
      wrongOptions[3].length === 0
    ) {
      showSnackBar({ type: "WARNING", message: "Please provide all wrong options" });
      return;
    }

    const options = shuffle([correctAnswer, wrongOptions[1], wrongOptions[2], wrongOptions[3]]);
    const answer = options.findIndex((option) => option === correctAnswer);

    createQuestion.mutate({ title, answer, options, createdBy: user?._id || "" });
  };

  const generateWrongOptions = useMutation({
    mutationFn: async (param: { title: string; answer: string }) => {
      return await PostRequest(`/generateWrongOptions`, param);
    },
    onSuccess: (response) => {
      if (response.data.length === 3) {
        setWrongOptions([
          "",
          response.data[0].trim(),
          response.data[1].trim(),
          response.data[2].trim(),
        ]);
      } else {
        showSnackBar({
          type: "ERROR",
          message: "Ooops, something went wrong. Please fill it manually.",
        });
      }
    },
    onError: (response) => {
      showSnackBar({ type: "ERROR", message: "Ooops, something went wrong." });
    },
  });

  const onGenerateWrongOptionsPress = () => {
    console.log("on generate");
    if (!title || title.length === 0) {
      showSnackBar({ type: "ERROR", message: "Please enter question title first." });
      return;
    }

    if (!correctAnswer || correctAnswer.length === 0) {
      showSnackBar({ type: "ERROR", message: "Please enter correct answer first" });
      return;
    }

    showSnackBar({ type: "WARNING", message: "Generating..." });
    generateWrongOptions.mutate({ title, answer: correctAnswer });
  };

  return (
    <Container>
      <View
        testID="QuestionCreateManualScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: shouldShowSideBar() ? 0 : 20,
        }}
      >
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}
        >
          <View
            style={{
              height: "100%",
            }}
          >
            <View style={{ display: "flex", flex: 1, gap: 20 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  justifyContent: "space-around",
                }}
              >
                <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <Text>
                    {title && title.length > 10 ? (
                      <AntDesign
                        testID="titleCheckMark"
                        name="checkcircle"
                        size={20}
                        color="#53DF83"
                      />
                    ) : (
                      <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                    )}
                  </Text>
                  <Text style={styles.label}>Title</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <Text>
                    {correctAnswer ? (
                      <AntDesign
                        testID="answerCheckMark"
                        name="checkcircle"
                        size={20}
                        color="#53DF83"
                      />
                    ) : (
                      <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                    )}
                  </Text>
                  <Text style={styles.label}>Answer</Text>
                </View>
                <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <Text>
                    {wrongOptions[1].length !== 0 &&
                    wrongOptions[2].length !== 0 &&
                    wrongOptions[3].length !== 0 ? (
                      <AntDesign
                        testID="optionsCheckMark"
                        name="checkcircle"
                        size={20}
                        color="#53DF83"
                      />
                    ) : (
                      <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                    )}
                  </Text>
                  <Text style={styles.label}>Options</Text>
                </View>
              </View>

              <View>
                <Text style={styles.label}>Title</Text>
                <TextInput
                  autoCapitalize="none"
                  inputMode="text"
                  testID="questionTitleInput"
                  outlineColor={"#6A5AE0"}
                  mode="outlined"
                  theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
                  style={styles.inputText}
                  textColor="#858494"
                  label="Question Title"
                  value={title}
                  onChangeText={(text) => setTitle(text)}
                />
              </View>

              <View>
                <Text style={styles.label}>Corrrect Answer</Text>
                <TextInput
                  autoCapitalize="none"
                  inputMode="text"
                  testID="answerTitleInput"
                  outlineColor={"#6A5AE0"}
                  mode="outlined"
                  theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
                  style={styles.inputText}
                  label="Correct Answer"
                  textColor="#858494"
                  value={correctAnswer}
                  onChangeText={(text) => setCorrectAnswer(text)}
                />
              </View>

              <View>
                <Text style={styles.label}>Wrong Options </Text>
                <TouchableOpacity
                  style={{
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    backgroundColor: "#6A5AE0",
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                  onPress={() => onGenerateWrongOptionsPress()}
                >
                  <Text style={{ color: "#FFF", textAlign: "center" }}>
                    Auto Generate Wrong Options By AI
                  </Text>
                </TouchableOpacity>
                <View style={{ display: "flex", gap: 10 }}>
                  {[1, 2, 3].map((key) => (
                    <TextInput
                      key={key}
                      autoCapitalize="none"
                      inputMode="text"
                      testID={`optionTitleInput${key}`}
                      outlineColor={"#6A5AE0"}
                      mode="outlined"
                      theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
                      style={styles.inputText}
                      textColor="#858494"
                      label={`Option ${key}`}
                      value={wrongOptions[key]}
                      onChangeText={(text) => onWrongOptionsChange(text, key)}
                    />
                  ))}
                </View>
              </View>
            </View>
            <View style={{ marginTop: 10, display: "flex", flexDirection: "row", gap: 10 }}>
              <Button
                mode="outlined"
                textColor="#6A5AE0"
                onPress={() => reset()}
                style={{ flex: 1 }}
              >
                Reset
              </Button>
              <Button
                style={{ flex: 4 }}
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                testID="AddQuestionButton"
                onPress={() => onAddQuestionPress()}
              >
                Done
              </Button>
            </View>
          </View>
        </ScrollView>
      </View>
    </Container>
  );
}

export default QuestionCreateManualScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "600",
    color: "#0C092A",
  },
  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
});

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { startCase, sum } from "lodash";
import { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { RootNativeStackParamList } from "../../types/Screens";

interface Props {
  funeQ: Record<string, number>;
}
function ProfileFuneQ(props: Props) {
  const [totalFuneQ, setTotalFuneQ] = useState<number>();
  const [tagFuneQ, setTagFuneQ] = useState<{ tag: string; funeQ: number }[]>();

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  useEffect(() => {
    if (props.funeQ) {
      const totalFuneQ = sum(Object.values(props.funeQ));

      const tempTagFuneQ = Object.keys(props.funeQ)
        .filter((tag) => !tag.includes("grade"))
        .map((tag: string) => {
          return {
            tag: tag,
            funeQ: props.funeQ[tag],
          };
        })
        .sort((a, b) => b.funeQ - a.funeQ)
        .splice(0, 10);

      setTagFuneQ(tempTagFuneQ);
      setTotalFuneQ(totalFuneQ);
    }
  }, [props.funeQ]);

  const onTagClick = (tag: string) => {
    navigation.navigate("Tag", { tags: [tag] });
  };

  if (!tagFuneQ || tagFuneQ.length === 0) {
    return (
      <Text style={{ color: "#CCC" }}>User have not taken enough test. No data Available</Text>
    );
  }

  return (
    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {tagFuneQ.map((tagF: { tag: string; funeQ: number }) => (
        <TouchableOpacity
          key={tagF.tag}
          style={styles.container}
          onPress={() => onTagClick(tagF.tag)}
        >
          <Text>
            {startCase(tagF.tag)} : {tagF.funeQ}
          </Text>
          <ProgressBar
            progress={tagF.funeQ / (totalFuneQ || 1)}
            color="#6A5AE0"
            style={{ backgroundColor: "#EFEEFC", marginTop: 5 }}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
}

export default ProfileFuneQ;

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    width: "50%",
  },
  progressText: {
    fontSize: 8,
    color: "#CCC",
    marginLeft: 10,
  },
});

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { startCase } from "lodash";
import { Text, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { RootNativeStackParamList } from "../../types/Screens";

function GradeTags() {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const gradeTags = [
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  return (
    <View
      style={{
        padding: 20,
        borderRadius: 20,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#FFF",
      }}
    >
      <View>
        <Text style={{ fontSize: 16, fontWeight: "bold" }}>Grades Category</Text>
      </View>
      <View
        testID="GradeTagList"
        style={{
          backgroundColor: "#C4D0FB",
          borderRadius: 20,
          padding: 16,
          paddingTop: 24,
          marginTop: 20,
        }}
      >
        {gradeTags.map((gradeTag, index) => (
          <TouchableRipple
            key={gradeTag}
            rippleColor="rgba(0, 0, 0, .32)"
            style={{
              borderRadius: 20,
            }}
            onPress={() => navigation.navigate("TagList", { tag: gradeTag })}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                // gap: 10,
                padding: 10,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <View style={{ flex: 1, flexWrap: "wrap" }}>
                <Text
                  style={{
                    fontWeight: "500",
                    paddingLeft: 10,
                    maxWidth: "100%",
                  }}
                >
                  {startCase(gradeTag)}
                </Text>
              </View>
              <View>
                <MaterialIcons
                  name="arrow-forward-ios"
                  size={15}
                  color="#6A5AE0"
                  style={{ marginTop: 3 }}
                />
              </View>
            </View>
          </TouchableRipple>
        ))}
      </View>
    </View>
  );
}

export default GradeTags;

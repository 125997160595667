import { Entypo, Feather, MaterialIcons } from "@expo/vector-icons";
import * as Clipboard from "expo-clipboard";
import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import useProfile from "../../scripts/userProfile";

function Greeting() {
  const { user, showSnackBar } = useProfile();
  const [greeting, setGreeting] = useState<string>("");

  useEffect(() => {
    const date = new Date();
    const hour = date.getHours();
    if (hour <= 10) {
      setGreeting("GOOD MORNING");
    } else if (hour > 10 && hour < 15) {
      setGreeting("GOOD AFTERNOON");
    } else {
      setGreeting("GOOD EVENING");
    }
  }, []);

  const copyToClipboard = async () => {
    showSnackBar({ type: "SUCCESS", message: "Copied!" });
    await Clipboard.setStringAsync(user?._id || "");
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row" }}>
        {greeting === "GOOD MORNING" && <Feather name="sunrise" size={18} color="#FFD6DD" />}
        {greeting === "GOOD AFTERNOON" && <Feather name="sun" size={18} color="#FFD6DD" />}
        {greeting === "GOOD EVENING" && <Entypo name="moon" size={24} color="#FFD6DD" />}
        <View>
          <Text style={styles.greet}>{greeting}</Text>
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 15, fontWeight: "bold", color: "#fff" }}>{user?.username}</Text>
        <Text
          style={{
            fontSize: 8,
            opacity: 0.7,
            color: "#FFF",
            marginLeft: 3,
            // marginTop: Platform.OS === "web" ? 2 : 1,
          }}
        >
          ({user && user._id && user._id.slice(-8)}){" "}
        </Text>
        <Text>
          <MaterialIcons
            name="content-copy"
            size={10}
            color="#FFF"
            style={{ top: Platform.OS === "web" ? 0 : 3 }}
            onPress={copyToClipboard}
          />
        </Text>
      </View>
    </View>
  );
}

export default Greeting;

const styles = StyleSheet.create({
  container: {
    padding: Platform.OS === "web" ? 20 : 0,
  },

  greet: {
    color: "#FFD6DD",
    fontSize: 10,
    marginTop: 4,
    marginLeft: 5,
  },
});

import { ScrollView, StyleSheet, View } from "react-native";
import { useQuery } from "react-query";
import QuestionList from "../components/question/QuestionList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import { QuestionRecord } from "../types/Question";

function QuestionListScreen() {
  const limit = 100;
  const getLatestQuestions = async () => {
    const response = await GetRequest("/getLatestQuestions", { limit, status: "approved" });
    return response.data;
  };

  const {
    isLoading: latestQuestionsLoading,
    data: latestQuestions,
  }: { isLoading: boolean; data?: QuestionRecord[] } = useQuery(
    `latestQuestions${limit}`,
    getLatestQuestions,
    { staleTime: 60000 }
  );

  if (latestQuestionsLoading) {
    return <Skeleton />;
  }

  return (
    <Container>
      <View
        testID="QuestionListScreen"
        style={{
          backgroundColor: "#6A5AE0",
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            padding: 20,
            paddingTop: shouldShowSideBar() ? 0 : 20,
            // backgroundColor: "#fff",
            borderRadius: 20,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            flex: 1,
          }}
        >
          <QuestionList questions={latestQuestions || []} />
        </ScrollView>
      </View>
    </Container>
  );
}

export default QuestionListScreen;

const styles = StyleSheet.create({});

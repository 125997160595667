import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import useProfile from "../../scripts/userProfile";
import { RootNativeStackParamList } from "../../types/Screens";

function ProfileIcon({
  size,
  style,
  iconColor,
}: {
  size: number;
  iconColor: string;
  style?: StyleProp<ViewStyle>;
}) {
  const { user } = useProfile();

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => navigation.navigate("Profile", { userId: user?._id || "" })}>
        <MaterialCommunityIcons
          name="account-circle-outline"
          size={size}
          color={iconColor}
          accessibilityLabel="Profile Icon"
        />
      </TouchableOpacity>
    </View>
  );
}

export default ProfileIcon;

const styles = StyleSheet.create({
  container: {},
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { Dimensions, Linking, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useQueryClient } from "react-query";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

const SCREEN_WIDTH = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function ContactUsScreen({ navigation }: Props) {
  const [message, setMessage] = useState<string>("");

  const { user, showSnackBar } = useProfile();

  const queryClient = useQueryClient();

  const onSendPress = async () => {
    if (!message.trim()) {
      showSnackBar({ type: "WARNING", message: "Please select option you want to report" });
      return;
    }

    const feedback = {
      message,
      userId: user?._id,
    };

    try {
      await PostRequest("/sendFeedback", feedback);
      showSnackBar({
        type: "SUCCESS",
        message: "Thanks for report. We will check and fix very soon",
      });

      queryClient.invalidateQueries(`feedbacks${user?._id}`);

      navigation.navigate("Feedbacks");
    } catch (e) {
      showSnackBar({
        type: "ERROR",
        message: "Oops something went wrong. We can't save your feedback report at the moment",
      });
      console.error("Error adding event sendFeedback", e);
    }
  };

  return (
    <Container>
      <View
        testID="ContactUsScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Message</Text>
            <TextInput
              multiline={true}
              testID="MessageInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              style={[styles.inputText, { height: message.split(/\r\n|\r|\n/).length * 20 + 60 }]} // Autogrow height hack
              textColor="#858494"
              label="Your Message"
              value={message || ""}
              onChangeText={(text) => setMessage(text)}
            />
          </View>
          <View>
            <TouchableOpacity onPress={() => Linking.openURL("mailto:admin@fune.app")}>
              <Text style={{ color: "#CCC" }}>You can also send email to admin@fune.app</Text>
            </TouchableOpacity>
          </View>
          <View>
            <Button
              testID="SendBtn"
              style={styles.button}
              mode="contained"
              buttonColor="#6A5AE0"
              labelStyle={{ color: "#fff" }}
              onPress={() => onSendPress()}
            >
              Send
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
}

export default ContactUsScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "#fff",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Constants from "expo-constants";
import * as Device from "expo-device";
import { Dimensions, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;

const DeviceProperties = [
  "manufacturer",
  "modelId",
  "modelName",
  "designName",
  "productName",
  "deviceType",
  "deviceYearClass",
  "osName",
  "osVersion",
  "osBuildId",
  "deviceName",
];

type Props = NativeStackScreenProps<RootNativeStackParamList>;
const DebugScreen = ({ navigation }: Props) => {
  const { user } = useProfile();

  const getAppVersion = async () => {
    const response = await GetRequest("/getAppVersion");

    return response.data;
  };

  const { isLoading, data } = useQuery(`appVersion`, getAppVersion);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container>
      <ScrollView
        testID="LoginScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            gap: 20,
            backgroundColor: "#FFF",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <View>
            <Text style={{ fontWeight: "bold", fontSize: 14 }}>Device</Text>
            {DeviceProperties.map((property) => (
              <View key={property} style={styles.row}>
                <Text>{property} : </Text>
                <Text>
                  {
                    //@ts-ignore
                    Device[property]
                  }
                </Text>
              </View>
            ))}
          </View>
          <View>
            <Text style={{ fontWeight: "bold", fontSize: 14 }}>Platform</Text>
            <View style={styles.row}>
              <Text>OS</Text>
              <Text>{Platform.OS}</Text>
            </View>
            <View style={styles.row}>
              <Text>Version :</Text>
              <Text>{Platform.Version}</Text>
            </View>
            <View style={styles.row}>
              <Text>Screen Width :</Text>
              <Text>{SCREEN_WIDTH}</Text>
            </View>
            <View style={styles.row}>
              <Text>Screen Height :</Text>
              <Text>{SCREEN_HEIGHT}</Text>
            </View>
            <Text style={{ fontWeight: "bold", marginTop: 30 }}>User</Text>
            <View style={styles.row}>
              <Text>ID :</Text>
              <Text>{user?._id}</Text>
            </View>

            <Text style={{ fontWeight: "bold", marginTop: 30 }}>App</Text>
            <View style={styles.row}>
              <Text>Latest Version :</Text>
              <Text>{data[Platform.OS]}</Text>
            </View>
            <View style={styles.row}>
              <Text>Installed Version :</Text>
              <Text>{Constants.expoConfig?.version}</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

export default DebugScreen;

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },

  label: {
    fontWeight: "400",
    color: "#CCC",
  },
  value: {
    color: "#000",
  },
});

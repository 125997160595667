import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";

import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useMutation, useQuery } from "react-query";
import MyQuestionList from "../components/question/MyQuestionList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuizRecord, QuizStatus } from "../types/Quiz";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuizEdit">;
function QuizEditScreen({ navigation, route }: Props) {
  const [title, setTitle] = useState<string>("");
  const [updated, setUpdated] = useState<boolean>(false);
  const { showSnackBar } = useProfile();
  const [quiz, setQuiz] = useState<QuizRecord>();
  const isFocused = useIsFocused();

  const { _id } = route.params;

  const getQuizes = async () => {
    const response = await GetRequest("/getQuizes", { _id, status: "any" });

    return response.data[0];
  };

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused]);

  const {
    isLoading,
    data: existingQuizData,
    refetch,
  }: { isLoading: boolean; data?: QuizRecord; refetch: any } = useQuery(`quizes${_id}`, getQuizes, {
    enabled: typeof _id !== "undefined",
  });

  useEffect(() => {
    if (existingQuizData) {
      setTitle(existingQuizData.title);
      setQuiz(existingQuizData);
    }
  }, [existingQuizData]);

  const updateQuiz = useMutation({
    mutationFn: async (param: {
      _id: string;
      questions?: string[];
      title?: string;
      status?: QuizStatus;
    }) => {
      return await PostRequest(`/updateQuiz`, param);
    },
    onSuccess: (response, variables) => {
      showSnackBar({ type: "SUCCESS", message: "Updated Successfully" });
    },
  });

  const onPublishPress = async () => {
    updateQuiz.mutate(
      { title, _id, status: "published" },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const onDeletePress = async () => {
    updateQuiz.mutate(
      { title, _id, status: "deleted" },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const onUpdatePress = async () => {
    showSnackBar({ type: "WARNING", message: "Updating.." });

    updateQuiz.mutate(
      { title, _id },
      {
        onSuccess: () => {
          setUpdated(false);
          refetch();
        },
      }
    );
  };

  const removeQuestion = (questionId: string) => {
    const questions = existingQuizData?.questions
      .filter((question) => question._id != questionId)
      .map((questionId) => questionId._id);

    updateQuiz.mutate(
      { _id, questions },
      {
        onSuccess: () => {
          showSnackBar({ type: "SUCCESS", message: "Question Removed Successfully" });
          refetch();
        },
      }
    );
  };

  const onTitleUpdate = () => {
    setUpdated(true);
  };

  if (isLoading) {
    return (
      <View style={{ backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Skeleton width={"100%"} height={50} />
        </View>
      </View>
    );
  }

  if (!isLoading && !existingQuizData) {
    return (
      <View style={{ backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Text style={{ textAlign: "center" }}>Quiz Not Found</Text>
        </View>
      </View>
    );
  }

  return (
    <Container>
      <ScrollView
        testID="QuizEditScreen"
        contentContainerStyle={{ flexGrow: 1 }}
        style={{
          backgroundColor: "#FFF",
          borderRadius: 20,
          padding: 20,
          margin: 20,
          marginTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View style={{ flex: 1, gap: 20 }}>
          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Title</Text>
            <TextInput
              autoCapitalize="none"
              inputMode="text"
              testID="quizInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              textColor="#858494"
              style={styles.inputText}
              label="Quiz Title"
              value={title}
              onChangeText={(text) => {
                onTitleUpdate();
                setTitle(text);
              }}
            />
            {updated && (
              <Button
                style={{ flex: 1, marginTop: 10 }}
                mode="outlined"
                textColor="#6A5AE0"
                testID="PublishQuizButton"
                onPress={() => onUpdatePress()}
              >
                Update
              </Button>
            )}
          </View>

          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Status</Text>
            <Text
              style={{
                padding: 10,
                borderWidth: 1,
                borderColor: "#CCC",
                width: 100,
                borderRadius: 10,
                marginTop: 10,
                textAlign: "center",
              }}
            >
              {existingQuizData?.status}
            </Text>
          </View>

          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Questions</Text>

            {quiz?.questions && quiz?.questions.length >= 1 && (
              <MyQuestionList questions={quiz?.questions} onRemove={(_id) => removeQuestion(_id)} />
            )}
            {(!quiz?.questions || quiz?.questions.length <= 0) && (
              <Text style={{ textAlign: "center", marginTop: 20 }}>
                No questions in this quiz. Please add some question
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            display: "flex",
            gap: 10,
            marginTop: 20,
            marginBottom: isWebBigScreen() ? 0 : 50,
          }}
        >
          {updateQuiz.isLoading && (
            <Button mode="outlined" buttonColor="#fff" textColor="#6A5AE0">
              Loading...
            </Button>
          )}
          {!updateQuiz.isLoading && (
            <Button
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              onPress={() => navigation.navigate("QuestionAdd", { quizId: quiz?._id || "" })}
            >
              Add Question
            </Button>
          )}

          <Button
            mode="outlined"
            textColor="#6A5AE0"
            onPress={() => navigation.navigate("QuizPerformances", { quizId: _id || "" })}
          >
            View Quiz Scores
          </Button>
          {quiz && quiz.questions.length > 0 && (
            <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {quiz.status !== "deleted" && (
                <Button
                  style={{ flex: 1 }}
                  mode="outlined"
                  textColor="#6A5AE0"
                  onPress={() => navigation.navigate("QuizShare", { _id })}
                >
                  Share
                </Button>
              )}
              {quiz.status === "deleted" ? (
                <Button
                  style={{ flex: 1 }}
                  mode="outlined"
                  textColor="#6A5AE0"
                  testID="PublishQuizButton"
                  onPress={() => onPublishPress()}
                >
                  Publish
                </Button>
              ) : (
                <Button
                  style={{ flex: 1 }}
                  mode="contained"
                  buttonColor="#FF6666"
                  textColor="#fff"
                  testID="DeleteQuizButton"
                  onPress={() => onDeletePress()}
                >
                  Delete
                </Button>
              )}
            </View>
          )}
        </View>
      </ScrollView>
    </Container>
  );
}

export default QuizEditScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

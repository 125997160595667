import { Entypo } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, View } from "react-native";
import { Avatar, Button, TouchableRipple } from "react-native-paper";
import { isWebBigScreen } from "../../scripts/ScreenSize";
import useProfile from "../../scripts/userProfile";
import { RootNativeStackParamList } from "../../types/Screens";
import Container from "../utils/Container";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function Settings({ navigation }: Props) {
  const { signOut } = useProfile();

  const menus = [
    {
      to: "UpdateProfile",
      title: "Update Profile",
      description: "Update username, age etc.",
    },
    {
      to: "OtherSettings",
      title: "Other Settings",
      description: "Update other app settings.",
    },
    {
      to: "Feedbacks",
      title: "Contact Us",
      description: "We love to hear from you.",
    },
    {
      to: "Debug",
      title: "Debug",
      description: "Your device information",
    },
  ];

  const onSignOutClick = () => {
    signOut();

    navigation.navigate("Setting");
  };

  return (
    <Container>
      <View
        testID="Settings"
        style={{
          backgroundColor: "#6A5AE0",
          flex: 1,
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View style={{ backgroundColor: "#fff", padding: 20, borderRadius: 20 }}>
          <Text style={{ fontWeight: "700", color: "#858394", marginLeft: 3 }}> Account</Text>
          {menus.map((menu, index) => (
            <TouchableRipple
              key={menu.to}
              onPress={
                //@ts-ignore
                () => navigation.navigate(menu.to)
              }
              rippleColor="rgba(0, 0, 0, .32)"
              style={{
                borderRadius: 20,
                marginTop: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  backgroundColor: "#EFEEFC",
                  borderRadius: 20,
                  gap: 20,
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <View>
                  <Avatar.Text
                    size={35}
                    label={`${index + 1}`}
                    labelStyle={{ marginTop: -3 }}
                    style={{ backgroundColor: "#fff" }}
                  />
                </View>
                <View style={{ flex: 2 }}>
                  <Text style={{ fontWeight: "700" }}>{menu.title}</Text>
                  <Text style={{ marginTop: 5, color: "#858494", fontSize: 12 }}>
                    {menu.description}
                  </Text>
                </View>
                <View>
                  <Entypo name="chevron-right" size={24} color="black" />
                </View>
              </View>
            </TouchableRipple>
          ))}

          <Button
            testID="signOut"
            mode="contained"
            buttonColor="#FF6666"
            textColor="#FFF"
            onPress={onSignOutClick}
            style={{ marginTop: 20 }}
          >
            Sign Out
          </Button>
        </View>
      </View>
    </Container>
  );
}

export default Settings;

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { startCase } from "lodash";
import { useEffect, useState } from "react";
import { Dimensions, ScrollView, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { useQuery } from "react-query";
import PeriodSelectorButton from "../components/commons/PeriodSelectorButton";
import LeaderboardList from "../components/leaderboard/LeaderboardList";
import LeaderboardStanding from "../components/leaderboard/LeaderboardStanding";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { LeaderboardRecord } from "../types/Leaderboard";
import { RootNativeStackParamList } from "../types/Screens";
import { Period } from "../types/Stat";

const SCREEN_WIDTH = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList, "TagLeaderboard">;
const TagLeaderboardScreen = ({ route, navigation }: Props) => {
  const { tags }: { tags: string[] } = route.params;
  const { user, showSnackBar } = useProfile();
  const [period, setPeriod] = useState<Period>("last7day");

  useEffect(() => {
    navigation.setOptions({ title: `Leaderboard` });
  }, []);

  const getTagLeaderboard = async () => {
    const response = await GetRequest("/getTagLeaderboard", {
      tags,
      userId: user?._id,
      period: period,
    });
    return response.data;
  };

  const {
    isLoading: leaderboardLoading,
    data: leaderboardRecord,
    refetch,
  }: { isLoading: boolean; data?: LeaderboardRecord; refetch: any } = useQuery(
    `${tags.toString()}${period}tagLeaderboard`,
    getTagLeaderboard,
    {
      staleTime: 60000,
      enabled: typeof user !== "undefined",
    }
  );

  const onPeriodSelectorChange = (newValue: number) => {
    switch (newValue) {
      case 0:
        setPeriod("last7day");
        break;
      case 1:
        setPeriod("last30day");
        break;
      case 2:
        setPeriod("lifetime");
        break;
      default:
        setPeriod("last7day");
        break;
    }
  };

  const refetchCalculateLeaderboard = async () => {
    showSnackBar({ type: "SUCCESS", message: "Updating..." });

    try {
      await PostRequest("/calculateTagLeaderboard", {
        tags,
        period: period,
      });

      showSnackBar({ type: "SUCCESS", message: "Updated, Successfully" });
    } catch (e: any) {
      showSnackBar({ type: "ERROR", message: "Ooops, error updating" });
    }

    refetch();
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          backgroundColor: "#6A5AE0",
        }}
      >
        <View style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Text style={{ color: "#fff", fontSize: 10, textAlign: "center" }}>
            Topic : {startCase(tags.toString())}
          </Text>
        </View>

        <View style={{ padding: 20 }}>
          <PeriodSelectorButton
            borderRadius={15}
            buttonTextColor={"#FFF"}
            containerBackground={"#4E42AA"}
            containerWidth={isWebBigScreen() ? 700 : Dimensions.get("window").width - 40}
            buttonActiveColor={"#9087E5"}
            buttonActiveTextColor={"#FFF"}
            containerPadding={5}
            buttonsLabels={["Weekly", "Monthly", "All Time"]}
            onChange={onPeriodSelectorChange}
          />
        </View>
        <ScrollView>
          <View style={{ position: "absolute", right: 20, zIndex: 9 }}>
            <Button mode="text" onPress={() => refetchCalculateLeaderboard()}>
              <MaterialCommunityIcons name="reload" size={20} color="rgba(255,255,255, 0.5)" />
            </Button>
          </View>
          <View style={{ padding: 20, marginTop: -20 }}>
            <LeaderboardStanding
              loading={leaderboardLoading}
              leaderboard={leaderboardRecord?.leaderboard || []}
            />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              backgroundColor: "#EFEEFC",
              marginRight: 5,
              marginLeft: 5,
              borderRadius: 20,
              marginTop: -70,
              padding: 15,
              gap: 10,
            }}
          >
            {leaderboardLoading ? (
              <Skeleton width={"100%"} height={50} />
            ) : (
              <LeaderboardList leaderboard={leaderboardRecord?.leaderboard || []} />
            )}
          </View>
        </ScrollView>
      </View>
    </Container>
  );
};

export default TagLeaderboardScreen;

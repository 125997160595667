import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { useMutation, useQuery } from "react-query";
import TrueFalseQuestionCard from "../components/truefalse/TrueFalseQuestionCard";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen, shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { Question } from "../types/Question";
import { RootNativeStackParamList } from "../types/Screens";
import { VoteQuestion } from "../types/Vote";

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;

type Props = NativeStackScreenProps<RootNativeStackParamList, "TrueFalse">;

const QuestionTrueFalseScreen2 = ({ route, navigation }: Props) => {
  const [questionIndex, setQuestionIndex] = useState<number>(1);
  const [noMoreCard, setNoMoreCard] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState<boolean>(false);

  const [questionCards, setQuestionCards] = useState<Question[]>([]);
  const [timeStart, setTimeStart] = useState<number>(new Date().getTime() / 1000);

  const { tags }: { tags: string[] } = route.params;
  const { user, showSnackBar } = useProfile();

  const getTagQuestions = async () => {
    const response = await GetRequest("/getUserRecommendedTagQuestions", {
      userId: user?._id,
      tags,
    });
    return response.data;
  };

  const {
    isLoading: questionsLoading,
    data: questions,
  }: { isLoading: boolean; data?: Question[] } = useQuery(
    `TrueFalseQuestions${tags.toString()}`,
    getTagQuestions,
    {
      staleTime: 60000,
      enabled: typeof user !== "undefined",
    }
  );

  const insertVoteMutation = useMutation({
    mutationFn: async (newVote: VoteQuestion) => {
      return await PostRequest("/voteQuestion", newVote);
    },
  });

  useEffect(() => {
    if (questions) {
      setQuestionCards(questions);
    }
  }, [questions]);

  useEffect(() => {
    navigation.setOptions({ title: `Question ${questionIndex} of ${questions?.length || 0}` });
  }, [questionIndex, questions]);

  const removeCard = (_id: string) => {
    const newQuestionCards = [...questionCards];
    newQuestionCards.splice(
      newQuestionCards.findIndex((item) => item._id == _id),
      1
    );
    setQuestionCards(newQuestionCards);

    if (newQuestionCards.length == 0) {
      navigation.setOptions({ title: `All done` });
      setNoMoreCard(true);
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  };

  /**
   *
   * @param questionId questionId
   * @param answerCorrect If user have swipped correctly or not based on the option shown
   */
  const onSwipedComplete = (
    questionId: string,
    isAnswerCorrect: boolean,
    correctAnswerText: string
  ) => {
    setAnswerCorrect(isAnswerCorrect); // To use for styling
    showSnackBar({
      type: isAnswerCorrect ? "SUCCESS" : "ERROR",
      message: isAnswerCorrect ? "Correct!" : `Oops, wrong. Correct answer is ${correctAnswerText}`,
    });

    const secondsTaken = new Date().getTime() / 1000 - timeStart;

    const points = ((isAnswerCorrect ? 1 / secondsTaken : 0) * 100) / 4; // Multiply by 100 just so we have bigger number // Devide by 4 because we want to give lesser points than option votes

    insertVoteMutation.mutate({
      userId: user?._id || "",
      questionId: questionId,
      type: "truefalse",
      vote: undefined,
      result: isAnswerCorrect,
      points: points,
    });

    setTimeStart(new Date().getTime() / 1000); // Reset to current time

    removeCard(questionId);
  };

  if (questionsLoading) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS == "web" ? 60 : 140,
        }}
      >
        <Skeleton width={"100%"} height={"100%"} style={{ borderRadius: 20 }} />
      </View>
    );
  }

  if (noMoreCard) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              backgroundColor: "#FFF",
              borderRadius: 20,
              padding: 20,
            }}
          >
            <Text style={{ textAlign: "center" }}>
              There are no more cards. Click to view result.
            </Text>
            <Button
              onPress={() =>
                navigation.navigate("TagResult", {
                  tags,
                })
              }
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#fff"
              style={{ borderRadius: 15 }}
            >
              View Result
            </Button>
          </View>
        </View>
      </View>
    );
  }

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: shouldShowSideBar() ? 0 : 20,
        }}
      >
        <View style={{ flex: 1 }}>
          {questionCards.map((question, key) => (
            <TrueFalseQuestionCard
              totalQuestions={questions?.length || 0}
              currentQuestionIndex={questionIndex}
              key={key}
              question={question}
              cardNumber={key}
              onSwipedComplete={onSwipedComplete}
            />
          ))}
        </View>
      </View>
    </Container>
  );
};

export default QuestionTrueFalseScreen2;

const styles = StyleSheet.create({
  titleText: {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 20,
  },
  option: {
    marginTop: 16,
    padding: 5,
    borderRadius: 20,
    borderColor: "#EFEEFC",
    borderWidth: 2,
    textAlign: "left",
  },
  card: {
    position: "absolute",
    width: SCREEN_WIDTH - 40,
    height: SCREEN_HEIGHT - (Platform.OS === "web" ? 120 : 240),
    borderRadius: 20,
    backgroundColor: "#fff",
    padding: 20,
    justifyContent: "space-between",
  },
});

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { Avatar, TouchableRipple } from "react-native-paper";
import { QuizRecord } from "../../types/Quiz";
import { RootNativeStackParamList } from "../../types/Screens";
import Skeleton from "../utils/Skeleton";

function QuizList({ quizes, showStatus }: { quizes: QuizRecord[]; showStatus?: boolean }) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  if (!quizes) {
    return <Skeleton />;
  }

  return (
    <View
      testID="quizList"
      style={{
        backgroundColor: "#C4D0FB",
        borderRadius: 20,
        padding: 16,
        marginTop: 20,
        gap: 30,
      }}
    >
      {quizes.map((quiz, index) => (
        <TouchableRipple
          onPress={() => navigation.navigate("Quiz", { _id: quiz._id })}
          key={quiz._id}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            <View style={{ justifyContent: "center" }}>
              <Avatar.Text
                size={24}
                label={`${index + 1}`}
                color="#6A5AE0"
                style={{ backgroundColor: "#fff" }}
                labelStyle={{ fontWeight: "bold" }}
              />
            </View>
            <View style={{ flex: 1, flexWrap: "wrap" }}>
              <Text style={{ fontWeight: "500", paddingLeft: 10, maxWidth: "100%" }}>
                {quiz.title}
              </Text>
              <Text
                style={{
                  fontWeight: "200",
                  paddingLeft: 10,
                  fontSize: 10,
                  marginTop: 4,
                  lineHeight: 15, // Need this line for iOS, otherwise it get cut
                }}
              >
                <Text>{quiz.questions?.length} Questions</Text>
                {showStatus && (
                  <Text
                    style={{
                      marginLeft: 5,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingBottom: 2,
                      backgroundColor: "#Fff",
                      borderRadius: 5,
                    }}
                  >
                    {quiz.status}
                  </Text>
                )}
              </Text>
            </View>
            <View style={{ justifyContent: "center" }}>
              <MaterialIcons name="arrow-forward-ios" size={15} color="#6A5AE0" />
            </View>
          </View>
        </TouchableRipple>
      ))}
    </View>
  );
}

export default QuizList;

const styles = StyleSheet.create({});

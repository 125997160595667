import * as React from "react";
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

// Simplest tutorial about Animated view I found and tought me
// https://www.youtube.com/watch?v=dXVgb3igGJ4&list=PLYxzS__5yYQmdfEyKDrlG5E0F0u7_iIUo

type Props = {
  buttonsLabels: string[];
  onChange: (index: number) => void;
  buttonHeight?: number;
  borderRadius?: number;
  containerPadding?: number;
  containerWidth?: number;
  containerBackground?: string;
  buttonActiveColor?: string;
  buttonActiveTextColor?: string;
  buttonTextColor?: string;
  animateType?: "timing" | "spring";
};

export default function PeriodSelectorButton(props: Props) {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const tabPosition = React.useRef(new Animated.Value(0)).current;

  const BORDER_RADIUS = props.borderRadius || 5;

  const ANIMATE_TYPE = props.animateType || "spring";

  const CONTAINER_WIDTH = props.containerWidth || Dimensions.get("window").width;
  const CONTAINER_PADDING = props.containerPadding || 5;
  const CONTAINER_BACKGROUND = props.containerBackground || "#CCC";

  const BUTTON_ACTIVE_COLOR = props.buttonActiveColor || "#FFF";
  const BUTTON_TEXT_COLOR = props.buttonTextColor || "#000";
  const BUTTON_ACTIVE_TEXT_COLOR = props.buttonActiveTextColor || BUTTON_TEXT_COLOR;
  const BUTTON_COUNT = props.buttonsLabels.length;
  const BUTTON_ACTIVE_WIDTH = (CONTAINER_WIDTH - CONTAINER_PADDING * 2) / BUTTON_COUNT;

  const BUTTON_HEIGHT = props.buttonHeight || 29;

  const CONTAINER_HEIGHT = BUTTON_HEIGHT + CONTAINER_PADDING * 2;

  const moveTab = (buttonIndex: number) => {
    setActiveIndex(buttonIndex);
    props.onChange(buttonIndex);
    const NEW_TAB_POSITION = BUTTON_ACTIVE_WIDTH * buttonIndex;

    if (ANIMATE_TYPE === "timing") {
      Animated.timing(tabPosition, {
        toValue: NEW_TAB_POSITION,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.spring(tabPosition, {
        toValue: NEW_TAB_POSITION,
        useNativeDriver: true,
      }).start();
    }
  };

  const Button = ({ title, btnIndex }: { title: string; btnIndex: number }) => {
    return (
      <TouchableOpacity
        testID={`btn${btnIndex}`}
        style={[
          styles.buttonContainer,
          { height: BUTTON_HEIGHT, justifyContent: "center", alignItems: "center" },
        ]}
        onPress={() => moveTab(btnIndex)}
      >
        <Text
          style={[
            styles.buttonText,
            { color: btnIndex === activeIndex ? BUTTON_ACTIVE_TEXT_COLOR : BUTTON_TEXT_COLOR },
          ]}
        >
          {title}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View
      style={{
        backgroundColor: CONTAINER_BACKGROUND,
        borderRadius: BORDER_RADIUS,
        padding: CONTAINER_PADDING,
        width: CONTAINER_WIDTH,
        height: CONTAINER_HEIGHT,
      }}
    >
      <View style={styles.rowsContainer}>
        <Animated.View
          key={"weekly"}
          style={[
            styles.buttonActive,
            {
              transform: [{ translateX: tabPosition }],
              width: BUTTON_ACTIVE_WIDTH,
              borderRadius: BORDER_RADIUS - 4, // Slightly lesser looks more beautiful
              backgroundColor: BUTTON_ACTIVE_COLOR,
              height: BUTTON_HEIGHT,
            },
          ]}
        />
      </View>
      <View style={styles.buttonsContainer}>
        {props.buttonsLabels.map((title, index) => (
          <Button title={title} btnIndex={index} key={index} />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  rowsContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    position: Platform.OS === "web" ? "absolute" : "relative",
    width: "100%",
  },
  buttonsContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    // textAlign: "center",
  },
  buttonContainer: {
    flex: 1,
    textAlign: "center",
  },
  buttonText: {
    color: "#000",
  },
  buttonActive: {
    cursor: "pointer",
    position: "absolute",
  },
});

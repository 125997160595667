import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
const ForgetPasswordScreen = ({ navigation }: Props) => {
  const { showSnackBar } = useProfile();

  const [email, setEmail] = useState<string>("");

  const sendPasswordResetCode = async () => {
    if (email.trim().length === 0) {
      showSnackBar({ type: "ERROR", message: "Please provide your email you used to register" });
      return;
    }

    if (!email.match(/.+\@.+\..+/)) {
      showSnackBar({ type: "ERROR", message: "Does not seems like this is valid email" });
      return;
    }

    await PostRequest("/resetPassword", { email });

    showSnackBar({ type: "SUCCESS", message: "We have sent password reset code to your email" });

    navigation.navigate("ChangePassword", { email });
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <View>
            <Text style={{ textAlign: "center" }}>
              Enter your email and we will send you a code to reset your password
            </Text>
          </View>
          <View>
            <Text style={styles.label}>Email</Text>
            <TextInput
              left={<TextInput.Icon icon="email-outline" iconColor="#6A5AE0" />}
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Your email address"
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </View>
          <View>
            <Button
              style={styles.button}
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#fff"
              onPress={() => sendPasswordResetCode()}
            >
              Reset Password
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
};

export default ForgetPasswordScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    color: "red",
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { shuffle, startCase } from "lodash";
import { Dimensions, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import { RootNativeStackParamList } from "../types/Screens";
import { TagCount } from "../types/Tag";

const screenWidth = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function LearnTagsScreen({ navigation }: Props) {
  const getTags = async () => {
    const response = await GetRequest("/getTags");
    return response.data;
  };

  const { isLoading: tagsLoading, data: tags }: { isLoading: boolean; data?: TagCount[] } =
    useQuery(`tags`, getTags, {
      staleTime: 60000,
    });

  if (tagsLoading) {
    return (
      <View
        testID="skeletonContainer"
        style={{
          flex: 1,
          gap: 10,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 60 : 120,
        }}
      >
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
      </View>
    );
  }

  return (
    <Container>
      <ScrollView
        testID="LearnTagsScreenContainer"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View testID="LearnTagsScreenList" style={styles.itemsContainer}>
          {tags &&
            shuffle(tags)
              .filter((tag: TagCount) => tag.count > 10)
              .map((tag) => (
                <TouchableRipple
                  key={tag._id}
                  rippleColor="rgba(0, 0, 0, .32)"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#C4D0FB",
                    padding: 10,
                  }}
                  onPress={() => navigation.navigate("Learn", { tags: [tag._id] })}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "500",
                        maxWidth: "100%",
                        color: "#000",
                      }}
                    >
                      {startCase(tag._id)}
                    </Text>
                  </View>
                </TouchableRipple>
              ))}
        </View>
      </ScrollView>
    </Container>
  );
}

export default LearnTagsScreen;

const styles = StyleSheet.create({
  itemsContainer: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 20,
    display: "flex",
  },
  item: {
    backgroundColor: "#EFEEFC",
    width: screenWidth / 2 - 40, //Offset of padding etc.
    height: screenWidth / 2 - 40,
    textAlign: "center",
    borderRadius: 15,
  },

  itemText: {
    color: "#6A5AE0",
    textAlign: "center",
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import { Animated, Dimensions, PanResponder, Text, View } from "react-native";
import { useQuery } from "react-query";
import LearnLoading from "../components/learn/LearnLoading";
import LearnQuestionCard from "../components/learn/LearnQuestionCard";
import NoMoreQuestion from "../components/learn/NoMoreLearnQuestion";
import Container from "../components/utils/Container";
import { logEvent } from "../scripts/analytic";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuestionRecord } from "../types/Question";
import { RootNativeStackParamList } from "../types/Screens";

const screenWidth = Dimensions.get("window").width - 10;
const screenHeight = Dimensions.get("window").height - 10;

type Props = NativeStackScreenProps<RootNativeStackParamList, "Learn">;
const LearnScreen = ({ route, navigation }: Props) => {
  const { tags }: { tags: string[] } = route.params;
  const [activeCard, setActiveCard] = useState<number>(1);
  const [noMore, setNoMore] = useState<boolean>(false);
  const [questionScreens, setQuestionScreens] = useState<QuestionRecord[]>([]);
  const pan = useRef(new Animated.Value(0)).current;

  const { user, showSnackBar } = useProfile();

  const getTagQuestions = async () => {
    const response = await GetRequest("/getUserRecommendedTagQuestions", {
      userId: user?._id,
      tags,
    });

    return response.data;
  };

  const {
    isLoading: questionsLoading,
    data: questions,
  }: { isLoading: boolean; data?: QuestionRecord[] } = useQuery(
    `learnTagQuestions${tags?.toString()}`,
    getTagQuestions,
    {
      // staleTime: 60000,
      enabled: typeof user !== "undefined",
    }
  );

  useEffect(() => {
    if (questions) {
      navigation.setOptions({
        title: `Learn ${activeCard} of ${questions.length}`,
      });
    }
  }, [activeCard, questions]);

  useEffect(() => {
    if (questions) {
      setQuestionScreens([
        {
          _id: "",
          title: "",
          options: [],
          answer: 0,
          answerText: "",
          funeQ: 100,
          tags: [],
          updatedAt: "",
          createdAt: "",
          createdBy: "",
        },
        ...questions,
      ]);
    }
  }, [questions]);

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: Animated.event([null, { dy: pan }], { useNativeDriver: false }),
    onPanResponderRelease: (evt, gestureState) => {
      if (gestureState.dy < -1) {
        slideUp("swipe");
      } else if (gestureState.dy > 1) {
        if (activeCard === 1) {
          // When we already at first question, no question on top
          // Add some snackbar alert to let user know
          slideBack();
        } else {
          slideDown("swipe");
        }
      }
    },
  });

  const slideBack = () => {
    Animated.spring(pan, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
  };

  const slideUp = async (type: string) => {
    if (!questions) {
      showSnackBar({
        type: "ERROR",
        message: "Oops, questions not found",
      });
      return;
    }
    const currentQuestion = questions[activeCard - 1];

    logEvent("learn_swipe", {
      userId: user?._id,
      questionId: currentQuestion._id, // activeCard - 1 is current one
      direction: "up",
      type,
    });

    Animated.spring(pan, {
      toValue: screenHeight * -1,
      useNativeDriver: true,
    }).start(() => {
      let newScreens = [...questionScreens];
      newScreens.push(questions[activeCard + 1]); // Push to the end, mean at 3rd position
      newScreens.shift(); // Remove first
      setQuestionScreens(newScreens);

      pan.setValue(0);
    });

    await sendLearn(currentQuestion);

    const tempActiveCard = activeCard + 1;
    if (tempActiveCard !== questionScreens.length) {
      setActiveCard(tempActiveCard);
    } else {
      setNoMore(true);
    }
  };

  const slideDown = (type: string) => {
    if (!questions) {
      showSnackBar({
        type: "ERROR",
        message: "Oops, questions not found",
      });
      return;
    }

    logEvent("learn_swipe", {
      userId: user?._id,
      questionId: questions[activeCard]._id,
      direction: "down",
      type,
    });

    Animated.spring(pan, {
      toValue: screenHeight,
      useNativeDriver: true,
    }).start(() => {
      let newScreens = [...questionScreens];
      newScreens.unshift(questions[activeCard - 3]); // Insert at the beginning
      newScreens.pop(); // Remove the last
      setQuestionScreens(newScreens);

      pan.setValue(0);
    });

    setActiveCard(activeCard - 1);
  };

  const sendLearn = async (question: QuestionRecord) => {
    if (!question) {
      return;
    }

    await PostRequest("/postLearnQuestion", {
      userId: user?._id,
      questionId: question._id,
    });
  };

  const onPreviousClick = () => {
    slideDown("click");
  };

  const onNextClick = () => {
    slideUp("click");
  };

  if (questionsLoading) {
    return <LearnLoading />;
  }

  if (questions && questions.length === 0) {
    return (
      <Container>
        <View
          testID="LearnScreen"
          style={{
            flex: 1,
            // alignItems: "center",
            backgroundColor: "#6A5AE0",
            padding: 20,
            paddingTop: isWebBigScreen() ? 0 : 20,
          }}
        >
          <View style={{ flex: 1, backgroundColor: "#FFF", padding: 20, borderRadius: 20 }}>
            <Text
              style={{ textAlign: "center" }}
            >{`Oops, could not find any question ${tags?.toString()}`}</Text>
          </View>
        </View>
      </Container>
    );
  }

  if (noMore) {
    return <NoMoreQuestion tags={tags} questions={questions || []} />;
  }

  return (
    <Container>
      <View
        testID="LearnScreenExist"
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <Animated.View
          style={[
            {
              transform: [{ translateY: pan }],
              width: "100%",
            },
          ]}
          {...panResponder.panHandlers}
        >
          {questions &&
            questionScreens.map((questionScreen, index) => (
              <LearnQuestionCard
                isOnscreenQuestion={
                  questions[activeCard - 1] && questions[activeCard - 1]._id === questionScreen?._id
                }
                question={questionScreen}
                index={index}
                key={index}
                onNextClick={onNextClick}
              />
            ))}
        </Animated.View>
      </View>
    </Container>
  );
};

export default LearnScreen;

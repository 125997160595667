import { Text, View } from "react-native";

interface Props {
  message: string;
}

function NotFound(props: Props) {
  return (
    <View
      style={{
        flex: 1,
        gap: 10,
        backgroundColor: "#6A5AE0",
        padding: 20,
      }}
    >
      <View style={{ backgroundColor: "#FFF", borderRadius: 10, padding: 20 }}>
        <Text>{props.message}</Text>
      </View>
    </View>
  );
}

export default NotFound;

import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import QuizList from "../components/quiz/QuizList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import { QuizRecord } from "../types/Quiz";

function QuizListScreen() {
  const limit = 100;

  const getLatestQuizes = async () => {
    const response = await GetRequest("/getLatestQuizes", { limit: limit }); // Let's default limit to 100 first. In future we should implement infinite scroll with default to fewer limit
    return response.data;
  };

  const {
    isLoading: latestQuizesLoading,
    data: latestQuizes,
  }: { isLoading: boolean; data?: QuizRecord[] } = useQuery(
    `latestQuizes${limit}`, // We need to use different key otherwise it use cache data from home screen
    getLatestQuizes,
    {
      staleTime: 60000,
    }
  );

  if (latestQuizesLoading) {
    return <Skeleton />;
  }

  if (!latestQuizes) {
    return (
      <Container>
        <ScrollView
          testID="QuizListScreen"
          style={{
            backgroundColor: "#6A5AE0",
            flex: 1,
            padding: 20,
            paddingTop: isWebBigScreen() ? 0 : 20,
          }}
        >
          <View
            style={{
              padding: 20,
              backgroundColor: "#fff",
              borderRadius: 20,
              flex: 1,
            }}
          >
            <Text>Quizes not found</Text>
          </View>
        </ScrollView>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollView
        testID="QuizListScreen"
        style={{
          backgroundColor: "#6A5AE0",
          flex: 1,
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            padding: 20,
            backgroundColor: "#fff",
            borderRadius: 20,
            flex: 1,
          }}
        >
          <QuizList quizes={latestQuizes} />
        </View>
      </ScrollView>
    </Container>
  );
}

export default QuizListScreen;

const styles = StyleSheet.create({});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
const SignupScreen = ({ navigation }: Props) => {
  const { showSnackBar } = useProfile();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [validForm, setValidForm] = useState<boolean>(false);

  const registerUser = async ({ email, password }: { email: string; password: string }) => {
    const response = await PostRequest("/register", { email, password });
    return response.data;
  };

  const onSignUpPress = async () => {
    if (!email) {
      showSnackBar({ type: "ERROR", messageCode: "EMAIL_REQUIRED" });

      return;
    }

    if (!password) {
      showSnackBar({ type: "ERROR", messageCode: "PASSWORD_REQUIRED" });

      return;
    }

    if (email && password) {
      setLoading(true);
      try {
        const user = await registerUser({ email, password });
        showSnackBar({ type: "SUCCESS", message: "Register successfully. Now you can login." });

        setTimeout(() => {
          navigation.navigate("Login");
        }, 3000);
      } catch (e: any) {
        console.log(e);
        showSnackBar({ type: "ERROR", messageCode: e.response.data?.errorCode || "" });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (email && password) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [email, password]);

  return (
    <Container>
      <View
        testID="SignupScreen"
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: isWebBigScreen() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Email</Text>
            <TextInput
              testID="EmailInput"
              autoCapitalize="none"
              inputMode="email"
              left={
                <TextInput.Icon
                  accessibilityLabel="Email Icon"
                  icon="email-outline"
                  iconColor="#6A5AE0"
                />
              }
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Your email address"
              value={email || ""}
              onChangeText={(text) => setEmail(text)}
              accessibilityLabel="Email Input"
            />
          </View>
          <View>
            <Text style={styles.label}>Password</Text>
            <TextInput
              testID="PasswordInput"
              secureTextEntry={true}
              left={
                <TextInput.Icon accessibilityLabel="Password Icon" icon="eye" iconColor="#6A5AE0" />
              }
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Your password"
              value={password || ""}
              onChangeText={(text) => setPassword(text)}
              accessibilityLabel="Password Input"
            />
          </View>
          <View>
            {loading ? (
              <Button
                style={styles.button}
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                onPress={onSignUpPress}
                labelStyle={{ color: "#CCC" }}
                disabled
              >
                Our app is running at maximum speed. Please wait a bit.
              </Button>
            ) : (
              <Button
                testID="SignUpBtn"
                style={styles.button}
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                onPress={onSignUpPress}
                labelStyle={!validForm && { color: "#CCC" }}
                disabled={!validForm}
              >
                Signup
              </Button>
            )}
          </View>
          <View>
            <Button mode="text" onPress={() => navigation.navigate("Login")}>
              <Text style={{ color: "#858494" }}>Already have an account? </Text>
              <Text style={{ fontWeight: "bold", color: "#6A5AE0" }}>Login</Text>
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
};

export default SignupScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

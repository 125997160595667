/**
 * Determine the mobile operating system on web
 */
export const getOsNameOnWeb = () => {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }

  return "other";
};

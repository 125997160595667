import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useMutation } from "react-query";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuestionCreateFromTopic">;
function QuestionCreateFromTopicScreen({ route, navigation }: Props) {
  const [topic, setTopic] = useState<string>("");
  const { user, showSnackBar } = useProfile();
  const {
    quizId,
  }: {
    quizId?: string;
  } = route.params;

  const generateQuestions = useMutation({
    mutationFn: async (param: { topic: string; quizId: string; userId: string }) => {
      return await PostRequest(`/generateQuestionsFromTopic`, param);
    },
    onSuccess: (response, variables) => {
      showSnackBar({ type: "SUCCESS", message: `Added ${response.data.length} questions` });
      setTimeout(() => {
        navigation.navigate("QuizEdit", { _id: quizId });
      }, 1000);
    },
    onError: () => {
      showSnackBar({
        type: "ERROR",
        message: "Oops, we encountered error while generating questions.",
      });
    },
  });

  const onGenerateQuestionPress = () => {
    if (topic.trim().length === 0) {
      showSnackBar({ type: "WARNING", message: "Please enter quiz topic" });
      return;
    }

    if (topic.trim().length >= 200) {
      showSnackBar({ type: "WARNING", message: "Topic too long" });
      return;
    }

    if (!user) {
      showSnackBar({ type: "ERROR", message: "User not set" });
      return;
    }

    generateQuestions.mutate({ topic, quizId, userId: user._id });
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View testID="QuestionCreateFromTopicScreen" style={{ flex: 1, gap: 20 }}>
          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Topic (Max: 200 characters)</Text>
            <TextInput
              autoCapitalize="none"
              multiline={true}
              numberOfLines={2}
              inputMode="text"
              testID="topicInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              textColor="#858494"
              label="Topic"
              value={topic}
              onChangeText={(text) => setTopic(text)}
            />
            <Text style={{ color: "#CCC", fontSize: 10 }}>Length: {topic.length}</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                borderWidth: 2,
                borderColor: "#b4acef",
                borderRadius: 10,
                backgroundColor: "#e1def8",
                marginTop: 10,
                gap: 10,
              }}
            >
              <Text style={{ fontWeight: "bold" }}>Tips: </Text>
              <Text>
                You can provide topic such as "mammals" or "solar system" or "earth quake"
              </Text>
            </View>
          </View>
        </View>
        <View style={{ display: "flex", gap: 10 }}>
          {generateQuestions.isLoading && (
            <Button mode="outlined" buttonColor="#fff" textColor="#6A5AE0">
              Generating... (this usually take 15-30 seconds)
            </Button>
          )}
          {!generateQuestions.isLoading && (
            <Button
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              testID="GenerateQuestionsButton"
              onPress={() => onGenerateQuestionPress()}
            >
              Generate Questions
            </Button>
          )}
        </View>
      </View>
    </Container>
  );
}

export default QuestionCreateFromTopicScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

import { useState } from "react";
import { Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { PostRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { QuestionRecord } from "../../types/Question";
interface Props {
  question: QuestionRecord;
  userId: string;
  onClose: any;
}

function ReportQuestion({ question, userId, onClose }: Props) {
  const { showSnackBar } = useProfile();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [customMessage, setCustomMessage] = useState<string>("");

  const options = ["Wrong answer", "Inappropriate question", "Too hard", "Too easy"];

  const submitReport = async () => {
    if (!selectedOption && customMessage.trim().length === 0) {
      showSnackBar({ type: "WARNING", message: "Please select option to tell us what is wrong" });
      return;
    }

    const feedback = {
      message: selectedOption || customMessage,
      userId,
      questionId: question._id,
    };

    try {
      await PostRequest("/sendFeedback", feedback);
      onClose();
      showSnackBar({
        type: "SUCCESS",
        message: "Thanks for report. We will check and fix very soon",
      });
    } catch (e) {
      showSnackBar({
        type: "ERROR",
        message: "Oops something went wrong. We can't save your feedback report at the moment",
      });
      console.error("Error adding event sendFeedback", e);
    }
  };

  return (
    <View style={{ display: "flex", gap: 5 }}>
      <Text style={{ fontWeight: "bold" }}>Whats wrong?</Text>
      {options.map((option) => (
        <Button
          key={option}
          textColor="#000"
          mode={selectedOption === option ? "contained" : "outlined"}
          onPress={() => setSelectedOption(option)}
        >
          {option}
        </Button>
      ))}

      <TextInput
        mode="outlined"
        style={{ backgroundColor: "#FFF" }}
        outlineColor={"#6A5AE0"}
        theme={{ roundness: 20, colors: { onSurfaceVariant: "#858494" } }}
        textColor="#000"
        placeholder="Something else? Type here"
        value={customMessage}
        onChangeText={(text) => {
          setSelectedOption(undefined);
          setCustomMessage(text);
        }}
      />
      <Button
        testID="SubmitBtn"
        textColor="#FFF"
        buttonColor="#6A5AE0"
        mode="contained"
        onPress={submitReport}
        style={{ marginTop: 30 }}
      >
        Submit
      </Button>
      <Button textColor="#000" mode="outlined" onPress={() => onClose()}>
        Cancel
      </Button>
    </View>
  );
}

export default ReportQuestion;

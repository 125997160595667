import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
const LoginScreen = ({ navigation }: Props) => {
  const { setUser, showSnackBar, signedIn } = useProfile();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [validForm, setValidForm] = useState<boolean>(false);

  const loginUser = async ({ email, password }: { email: string; password: string }) => {
    const response = await PostRequest("/loginByEmail", { email, password });
    return response.data;
  };

  const onLoginPress = async () => {
    if (!email) {
      showSnackBar({ type: "ERROR", messageCode: "EMAIL_REQUIRED" });

      return;
    }

    if (!password) {
      showSnackBar({ type: "ERROR", messageCode: "PASSWORD_REQUIRED" });

      return;
    }

    if (loading) {
      return;
    }

    if (email && password) {
      setLoading(true);
      try {
        const response = await loginUser({ email, password });
        await AsyncStorage.setItem("accessToken", response.accessToken);
        setUser(response.user);
        navigation.navigate("Setting");
      } catch (e: any) {
        showSnackBar({ type: "ERROR", messageCode: e.response.data?.errorCode || "" });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (email && password) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [email, password]);

  return (
    <Container>
      <View
        testID="LoginScreen"
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Email</Text>
            <TextInput
              autoCapitalize="none"
              inputMode="email"
              testID="emailInput"
              left={
                <TextInput.Icon
                  accessibilityLabel={"Email Icon"}
                  icon="email-outline"
                  iconColor="#6A5AE0"
                />
              }
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Your email address"
              value={email}
              onChangeText={(text) => setEmail(text)}
              accessibilityLabel={"Login Email Input"}
            />
          </View>
          <View>
            <Text style={styles.label}>Password</Text>
            <TextInput
              secureTextEntry={true}
              testID="passwordInput"
              left={
                <TextInput.Icon
                  accessibilityLabel={"Password Icon"}
                  icon="eye"
                  iconColor="#6A5AE0"
                />
              }
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Your password"
              value={password}
              onChangeText={(text) => setPassword(text)}
              accessibilityLabel={"Login Password Input"}
            />
          </View>
          <View>
            {loading ? (
              <Button
                style={styles.button}
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                onPress={() => onLoginPress()}
                labelStyle={{ color: "#CCC" }}
                disabled
              >
                Our app is running at maximum speed. Please wait a bit.
              </Button>
            ) : (
              <Button
                testID="loginButton"
                style={styles.button}
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#fff"
                onPress={() => onLoginPress()}
                labelStyle={!validForm && { color: "#777" }}
                disabled={!validForm}
              >
                Login
              </Button>
            )}
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button mode="text" onPress={() => navigation.navigate("ForgetPassword")}>
              Forgot password?
            </Button>
            <Button mode="text" onPress={() => navigation.navigate("Signup")}>
              Signup
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { isWebBigScreen } from "../../scripts/ScreenSize";
import useProfile from "../../scripts/userProfile";
import { QuestionRecord } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";
import Skeleton from "../utils/Skeleton";

function MyQuestionList({
  questions,
  onRemove,
}: {
  questions: QuestionRecord[];
  onRemove: (questionId: string) => void;
}) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const { user, setDialog } = useProfile();

  const onRemoveClick = (_id: string) => {
    // alert are you sure you want to delete
    setDialog({
      visible: true,
      title: "Delete Question",
      content: "Please confirm that you want to delete this question",
      dismissLabel: "No",
      confirmLabel: "Yes",
      onDismiss: async () => {
        setDialog({ visible: false });
      },
      onConfirm: () => {
        setDialog({ visible: false });

        onRemove(_id);
      },
    });
    return;

    // delete the question
  };

  if (!questions) {
    return <Skeleton />;
  }

  return (
    <View
      testID="LatestQuestionList"
      style={{
        backgroundColor: "#C4D0FB",
        borderRadius: 20,
        padding: 16,
        paddingTop: 24,
        marginTop: 20,
      }}
    >
      {questions.map((question, index) => (
        <View
          key={question._id}
          style={{
            borderRadius: 20,
          }}
        >
          <View
            style={{
              flexDirection: isWebBigScreen() ? "row" : "column",
              justifyContent: "space-between",
              gap: isWebBigScreen() ? 0 : 10,
              padding: 10,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <View
              style={{
                flex: 1,
                flexWrap: "wrap",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: "500",
                    paddingLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    {`${index + 1}`}.{" "}
                  </Text>
                  {question.title}
                </Text>
              </View>
              <Text>Answer: {question.answerText}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {
                // At somepoint we will let user to add other question into their quiz. So we won't let user to edit question that wasn't created by them
                question?.createdBy === user?._id && (
                  <Button
                    mode="contained"
                    buttonColor="#6A5AE0"
                    textColor="#FFF"
                    style={{ maxHeight: 40 }}
                    onPress={() => navigation.navigate("QuestionEdit", { _id: question._id })}
                  >
                    Edit
                  </Button>
                )
              }
              <Button
                mode="contained"
                buttonColor="#FF6666"
                textColor="#FFF"
                style={{ maxHeight: 40 }}
                onPress={() => onRemoveClick(question._id)}
              >
                Delete
              </Button>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
}

export default MyQuestionList;

const styles = StyleSheet.create({});

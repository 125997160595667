import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { QuestionRecord } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";

const screenWidth = Dimensions.get("window").width - 10;
const screenHeight = Dimensions.get("window").height - 10;

function NoMoreQuestion({ tags, questions }: { tags: string[]; questions: QuestionRecord[] }) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingTop: Platform.OS === "web" ? 60 : 160,
        backgroundColor: "#6A5AE0",
      }}
    >
      <View
        style={[
          styles.cardsContainer,
          {
            height: screenHeight,
            width: screenWidth,
          },
        ]}
      >
        <View style={[styles.card, { top: 0 }]}>
          <View style={styles.cardContent}>
            <Text
              style={{
                color: "#0C092A",
                marginTop: 50,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              There is no more question. Let's have a test on this?
            </Text>

            <Button
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              style={{ borderRadius: 15, padding: 20, marginTop: 50 }}
              onPress={() =>
                navigation.navigate("Question", {
                  tags: tags,
                  questionIds: questions.map((question) => question._id),
                })
              }
            >
              Start
            </Button>

            <Button
              textColor="#000"
              mode="outlined"
              style={{ borderRadius: 15, padding: 5, marginTop: 50 }}
              onPress={() => navigation.goBack()}
            >
              Go Back
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}

export default NoMoreQuestion;

const styles = StyleSheet.create({
  cardsContainer: {},
  card: {
    height: screenHeight - (Platform.OS === "web" ? 0 : 60),
    width: screenWidth,
    flex: 1,
    position: "absolute",
    padding: 10,
    paddingTop: 60,
    paddingBottom: 60,
  },
  cardContent: {
    flex: 1,
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
  },
});

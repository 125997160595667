import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { Button, IconButton, Modal, Portal } from "react-native-paper";
import { useMutation, useQuery } from "react-query";
import MyBannerAd from "../components/commons/BannerAds";
import ReportQuestion from "../components/question/ReportQuestion";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { logEvent } from "../scripts/analytic";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import useProfile from "../scripts/userProfile";
import { QuestionRecord } from "../types/Question";
import { QuizUserUpdate } from "../types/QuizUser";
import { RootNativeStackParamList } from "../types/Screens";
import { VoteQuestion, VoteResult } from "../types/Vote";

const SCREEN_HEIGHT = Dimensions.get("window").height;

type Props = NativeStackScreenProps<RootNativeStackParamList, "Question">;
const QuestionScreen = ({ route, navigation }: Props) => {
  const {
    questionIds,
    quizId,
    quizUserId,
    tags,
    startQuestionIndex = 0,
  }: {
    questionIds: string[];
    quizId?: string;
    quizUserId?: string;
    tags?: string[];
    startQuestionIndex?: number;
  } = route.params;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(startQuestionIndex);
  const [timeStart, setTimeStart] = useState<number>(new Date().getTime() / 1000);
  const [correctAnswer, setCorrectAnswer] = useState<number>(3);
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const [haveNextQuestion, setHaveNextQuestion] = useState<boolean>(true);
  const [translations, setTranslations] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentQuizRank, setCurrentQuizRank] = useState<number>(99);

  const { user, setDialog, showSnackBar } = useProfile();

  const getQuestion = async () => {
    const response = await GetRequest("/getQuestion", {
      questionId: questionIds[currentQuestionIndex],
    });

    return response.data;
  };

  const {
    isLoading: questionLoading,
    data: question,
  }: { isLoading: boolean; data?: QuestionRecord } = useQuery(
    `question${questionIds[currentQuestionIndex]}`,
    getQuestion,
    { staleTime: 60000 }
  );

  const insertVoteMutation = useMutation({
    mutationFn: async (newVote: VoteQuestion) => {
      return await PostRequest("/voteQuestion", newVote);
    },
    onSuccess: (data, variables) => {
      showFeedBack(data.data, variables);
    },
  });

  const showFeedBack = (voteResult: VoteResult, voteData: VoteQuestion) => {
    if (voteResult.result) {
      //1. Check if more than 3 votes and this user is first one to get correctly.
      if (voteResult.voteStats.totalVote >= 3 && voteResult.voteStats.totalCorrect == 0) {
        showSnackBar({
          type: "INFO",
          message: "GREAT!!, You are the first person to get this question correctly",
        });

        return;
      }

      //2. If this most of answer are wrong.
      if (
        voteResult.voteStats.totalVote >= 3 &&
        voteResult.voteStats.totalWrong >= voteResult.voteStats.totalCorrect
      ) {
        showSnackBar({
          type: "INFO",
          message: "NICE, most of users answer this question wrong",
        });

        return;
      }
    } else {
      //3. If most of other users get wrong too and have this wrong option voted more than 25%
      if (
        voteData.vote === 0 ||
        voteData.vote === 1 ||
        voteData.vote === 2 ||
        voteData.vote === 3
      ) {
        if (
          voteResult.voteStats.totalVote >= 5 &&
          (voteResult.voteStats[`totalVote${voteData.vote}`] || 0) /
            voteResult.voteStats.totalVote >
            0.25
        ) {
          logEvent("show_feedback", {
            message: "NO WORRY, most of others users voted this option too",
          });

          showSnackBar({
            type: "INFO",
            message: "NO WORRY, most of others users voted this option too",
          });

          return;
        }
      }
    }

    if (voteData.quizId) {
      let newRank = voteResult.quizUsers
        .sort((a, b) => b.totalPoint - a.totalPoint)
        .findIndex((quizUser) => quizUser.user._id === user?._id);

      if (newRank === -1 || voteResult.quizUsers.length <= 1) {
        return;
      }

      newRank = newRank + 1; // findIndex return 0 if its on first rank

      if (newRank < currentQuizRank && newRank < voteResult.quizUsers.length) {
        logEvent("show_feedback", {
          message: "CONGRATULATION!!, You are on position out of users for this quiz",
        });
        showSnackBar({
          type: "INFO",
          message: `CONGRATULATION!!, You are on #${newRank} position out of ${voteResult.quizUsers.length} users for this quiz`,
        });
      } else if (currentQuizRank < newRank) {
        logEvent("show_feedback", {
          message: "OOPS!!, Your ranking for this quiz dropped from to",
        });
        showSnackBar({
          type: "INFO",
          message: `OOPS!!, Your ranking for this quiz dropped from #${currentQuizRank} to #${newRank}`,
        });
      }

      setCurrentQuizRank(newRank);

      return;
    }
  };

  const updateQuizUserMutation = useMutation({
    mutationFn: async ({ quizUserUpdate }: { quizUserUpdate: QuizUserUpdate }) => {
      return await PostRequest(`/updateQuizUser`, quizUserUpdate);
    },
  });

  useEffect(() => {
    setTimeStart(new Date().getTime() / 1000); // Reset to current time
    if (question) {
      setCorrectAnswer(question.answer);
    }
  }, [question]); //On question first load

  useEffect(() => {
    if (user?.autoTranslate && question) {
      translateQuestion();
    }
  }, [user, question]);

  useEffect(() => {
    navigation.setOptions({
      title: `Question ${currentQuestionIndex + 1} of ${questionIds.length}`,
    });
  }, [currentQuestionIndex]);

  const onAnswerPress = (answer: number) => {
    if (typeof selectedAnswer !== "undefined") {
      // If user already voted. User should click next not vote again.
      return;
    }

    if (!user || !question) {
      showSnackBar({
        type: "ERROR",
        message: "Oops, something went wrong. User or Question not set",
      });
      return;
    }

    const secondsTaken = new Date().getTime() / 1000 - timeStart;

    setSelectedAnswer(answer);

    let answerCorrect = false;
    if (correctAnswer === answer) {
      answerCorrect = true;
    }

    const points = (answerCorrect ? 1 / secondsTaken : 0) * 100; // Multiply by 100 just so we have bigger number

    logEvent("click_vote", {
      userId: user._id,
      questionId: question._id,
      answerCorrect: answerCorrect,
      type: "4option",
    });

    insertVoteMutation.mutate({
      userId: user._id,
      questionId: questionIds[currentQuestionIndex],
      type: "4option",
      vote: answer,
      result: answerCorrect,
      quizId: quizId,
      points: points,
      quizUserId: quizUserId,
    });

    // If already last question answered then update quizUser to finish
    if (quizUserId && currentQuestionIndex + 1 === questionIds.length) {
      updateQuizUserMutation.mutate({
        quizUserUpdate: {
          status: "complete",
          _id: quizUserId,
        },
      });
    }
  };

  const onNextQuestionPress = () => {
    if (typeof selectedAnswer === "undefined") {
      showSnackBar({ type: "ERROR", message: "Please select answer first." });
      return;
    }

    const tempHaveNextQuestion = currentQuestionIndex + 1 === questionIds.length ? false : true;

    setHaveNextQuestion(tempHaveNextQuestion);

    if (!tempHaveNextQuestion) {
      // Update quiz user

      if (quizUserId) {
        updateQuizUserMutation.mutate({
          quizUserUpdate: {
            status: "complete",
            _id: quizUserId,
          },
        });
      }

      return;
    }

    setSelectedAnswer(undefined); // Reset

    setTimeStart(new Date().getTime() / 1000); // Reset to current time

    setCurrentQuestionIndex(currentQuestionIndex + 1);

    setTranslations([]);

    // We don't need to refetch query for next question because it will do automatically when question index change as we are using it as useQuery key
  };

  const onViewResultPress = () => {
    if (quizUserId) {
      navigation.navigate("QuizResult", {
        quizUserId,
      });
    } else if (tags) {
      navigation.navigate("TagResult", {
        tags,
      });
    } else {
      // In case we move here by clicking on a single question in question list
      navigation.goBack();
    }
  };

  const translateQuestion = async () => {
    if (!question || !user) {
      showSnackBar({
        type: "ERROR",
        message: "Oops, something went wrong. User or Question not set",
      });
      return;
    }

    const response = await GetRequest("/translateQuestion", {
      questionId: question._id,
      target: user.language,
    });

    setTranslations(response.data);
  };

  const onTranslatePress = async () => {
    if (!user?.language) {
      setDialog({
        visible: true,
        title: "Please choose language",
        content: "It seems you haven't choose your preferred language. Choose now?",
        dismissLabel: "No",
        confirmLabel: "Yes",
        onDismiss: async () => {
          setDialog({ visible: false }); // If no then we will insert new quizuser and get that quizuserid
        },
        onConfirm: () => {
          setDialog({ visible: false });
          navigation.navigate("OtherSettings");
        },
      });
      return;
    }

    translateQuestion();
  };

  const OptionsLoading = () => {
    return (
      <View style={{ marginTop: 20, gap: 20 }}>
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
      </View>
    );
  };

  const Options = ({ options }: { options: string[] }) => {
    return (
      <>
        {options.map((option, index) => (
          <Button
            testID={`Option${index}`}
            onPress={() => onAnswerPress(index)}
            key={index}
            style={[
              styles.option,
              {
                borderColor:
                  selectedAnswer === index && correctAnswer !== selectedAnswer
                    ? "#FF6666"
                    : "#EFEEFC",
              },
            ]}
            textColor={
              correctAnswer === index && typeof selectedAnswer !== "undefined"
                ? "#fff"
                : selectedAnswer === index && correctAnswer !== selectedAnswer
                ? "#FF6666"
                : "#999"
            }
            buttonColor={
              correctAnswer === index && typeof selectedAnswer !== "undefined"
                ? "#53DF83"
                : undefined
            }
            contentStyle={{
              justifyContent: "space-between", // To make text align left
              flexDirection:
                selectedAnswer == index ||
                (correctAnswer == index && typeof selectedAnswer !== "undefined")
                  ? "row-reverse"
                  : "row", // To make icon on right
            }}
            icon={
              correctAnswer === index && typeof selectedAnswer !== "undefined"
                ? "check"
                : selectedAnswer === index && correctAnswer !== selectedAnswer
                ? "close"
                : undefined
            }
          >
            {option} {translations[index + 1] && ` / ${translations[index + 1]}`}
          </Button>
        ))}
      </>
    );
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
        }}
      >
        <View style={[styles.container, SCREEN_HEIGHT < 660 && styles.scroll]}>
          <IconButton
            icon={"translate"}
            iconColor="#CCC"
            size={25}
            style={{
              margin: 0,
              position: "absolute",
              right: 10,
            }}
            onPress={() => onTranslatePress()}
            accessibilityLabel={"Translate"}
          />
          <Text
            style={{
              color: "#0C092A",
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 20,
              paddingLeft: 20,
              paddingRight: 20,
              minHeight: 80,
            }}
          >
            {questionLoading ? (
              <Skeleton testID="QuestionLoadingSkeleton" width={"100%"} />
            ) : (
              question?.title.trim()
            )}
          </Text>
          <Text
            style={{
              color: "#CCC",
              fontSize: 9,
              margin: 0,
              position: "absolute",
              left: 10,
              top: 5,
            }}
          >
            {Math.round((!questionLoading && question?.funeQ) || 100)}
          </Text>
          {translations && translations.length > 0 && (
            <Text style={{ color: "#BBB", padding: 20 }}>{translations[0]}</Text>
          )}
          <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
            {haveNextQuestion ? (
              <>
                <View style={{ marginTop: 10 }}>
                  {questionLoading ? (
                    <OptionsLoading />
                  ) : (
                    <Options options={question?.options || []} />
                  )}
                </View>
                <View>
                  <MyBannerAd />
                </View>
              </>
            ) : (
              <View style={{ marginTop: 10, padding: 20 }}>
                <Text style={{ fontSize: 20 }}>There's no more question</Text>
              </View>
            )}
            <View>
              {haveNextQuestion ? (
                insertVoteMutation.isLoading ? (
                  <Button
                    mode="outlined"
                    buttonColor="#FFF"
                    textColor="#000"
                    style={{ borderRadius: 15 }}
                  >
                    Saving
                  </Button>
                ) : (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",

                      gap: 5,
                    }}
                  >
                    <Button
                      testID="ReportButton"
                      onPress={() => setModalVisible(true)}
                      mode="outlined"
                      textColor="#6A5AE0"
                      style={{ borderRadius: 15, alignSelf: "center" }}
                    >
                      Report
                    </Button>
                    <Button
                      testID="NextButton"
                      onPress={() => (insertVoteMutation.isLoading ? {} : onNextQuestionPress())}
                      mode="contained"
                      buttonColor="#6A5AE0"
                      textColor="#fff"
                      style={{ borderRadius: 15, flex: 5 }}
                    >
                      Next
                    </Button>
                  </View>
                )
              ) : (
                <Button
                  onPress={() => onViewResultPress()}
                  mode="contained"
                  buttonColor="#6A5AE0"
                  textColor="#fff"
                  style={{ borderRadius: 15 }}
                >
                  {quizUserId || tags ? "View Result" : "Go Back"}
                </Button>
              )}
            </View>
          </View>
          <Portal>
            <Modal
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
              contentContainerStyle={{
                backgroundColor: "white",
                padding: 20,
                margin: 20,
                alignSelf: "center",
              }}
            >
              {question && user && (
                <ReportQuestion
                  question={question}
                  userId={user?._id}
                  onClose={() => setModalVisible(false)}
                />
              )}
            </Modal>
          </Portal>
        </View>
      </View>
    </Container>
  );
};

export default QuestionScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 10,
    marginLeft: 5,
    marginRight: 5,
  },
  scroll: {
    overflow: "scroll",
  },
  option: {
    marginTop: 16,
    padding: 5,
    borderRadius: 20,
    borderColor: "#EFEEFC",
    borderWidth: 2,
    textAlign: "left",
  },
});

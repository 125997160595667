import { MaterialIcons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as Clipboard from "expo-clipboard";
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button } from "react-native-paper";
import QRCode from "react-native-qrcode-svg";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuizRecord } from "../types/Quiz";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuizShare">;
function QuizShareScreen({ navigation, route }: Props) {
  const { user, showSnackBar } = useProfile();
  const { _id }: { _id: string } = route.params;

  const getQuiz = async () => {
    const response = await GetRequest("/getQuizes", {
      _id: _id,
      status: "any",
    });

    return response.data[0];
  };

  const { isLoading: quizLoading, data: quiz }: { isLoading: boolean; data?: QuizRecord } =
    useQuery(`Quiz${_id}`, getQuiz, {
      enabled: typeof _id !== "undefined",
    }); // Should not have stale time

  const copyToClipboard = async (copyText: string) => {
    showSnackBar({ type: "SUCCESS", message: "Copied!" });
    await Clipboard.setStringAsync(copyText);
  };

  if (quizLoading) {
    return (
      <View style={{ backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ flex: 1, backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Skeleton />
        </View>
      </View>
    );
  }

  if (!quiz) {
    return (
      <View style={{ backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ flex: 1, backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Text style={{ textAlign: "center" }}>Oops something went wrong. Quiz not found</Text>
        </View>
      </View>
    );
  }

  return (
    <Container>
      <ScrollView>
        <View
          style={{
            flex: 1,
            backgroundColor: "#FFF",
            borderRadius: 20,
            margin: 20,
            padding: 20,
            marginTop: isWebBigScreen() ? 0 : 20,
          }}
        >
          <View style={{ display: "flex", gap: 20, flex: 1 }}>
            <View>
              <Text style={{ textAlign: "center", fontSize: 18, fontWeight: "600" }}>
                Successfully Published
              </Text>
              <Text style={{ textAlign: "center", color: "#CCC" }}>
                Share to users by 3 methods below.
              </Text>
            </View>
            <View
              style={{
                padding: 10,
                borderWidth: 2,
                borderColor: "#b4acef",
                borderRadius: 10,
                backgroundColor: "#e1def8",
              }}
            >
              <Text>
                <Text style={{ fontWeight: "bold" }}>Thank you</Text> for creating quiz using
                fune.app We love to hear feedback on how we can make our app more fun and useful.
                Click{" "}
                <TouchableOpacity
                  onPress={() => navigation.navigate("ContactUs")}
                  style={[
                    {
                      backgroundColor: "#6A5AE0",
                      borderRadius: 5,
                      paddingLeft: 5,
                      paddingRight: 5,
                    },
                    isWebBigScreen() ? styles.inlineButton : styles.fullWidthButton,
                  ]}
                >
                  <Text style={{ color: "#FFF" }}>Send Feedback</Text>
                </TouchableOpacity>
              </Text>
            </View>
            <View style={{ alignItems: "center", marginTop: 20 }}>
              <Text style={{ color: "#CCC" }}>Share by Quiz Number</Text>
              <Text
                style={{
                  textAlign: "center",
                  padding: 20,
                  borderWidth: 3,
                  width: 200,
                  fontSize: 20,
                  borderColor: "#6A5AE0",
                  color: "#6A5AE0",
                  fontWeight: "600",
                  marginTop: 10,
                }}
                onPress={() => copyToClipboard(quiz.number.toString())}
              >
                {quiz.number.toString().replace(/^(.{3})(.{3})(.*)$/, "$1 $2 $3")}
              </Text>
            </View>
            <View style={{ display: "flex" }}>
              <Text style={{ color: "#CCC", textAlign: "center" }}>Share by link</Text>
              <View style={{ display: "flex", gap: 10, flexDirection: "row", marginTop: 20 }}>
                <View
                  style={{
                    flex: 8,
                    backgroundColor: "#EEE",
                    padding: 13,
                    paddingLeft: 8,
                  }}
                >
                  <Text>{`https://web.fune.app/quiz/${quiz._id}?via=link`}</Text>
                </View>
                <TouchableOpacity
                  onPress={() => copyToClipboard(`https://web.fune.app/quiz/${quiz._id}?via=link`)}
                  style={{
                    flex: 2,
                    padding: 2,
                    borderRadius: 0,
                    justifyContent: "center",
                    backgroundColor: "#6A5AE0",
                    alignItems: "center",
                  }}
                >
                  <MaterialIcons
                    name="content-copy"
                    size={15}
                    color="#FFF"
                    style={{ marginRight: 5 }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ alignItems: "center", display: "flex", gap: 10 }}>
              <Text style={{ color: "#CCC" }}>Share by QR Code</Text>
              <QRCode value={`https://web.fune.app/quiz/${quiz._id}?via=qr`} size={200} />
            </View>
            <View style={{ display: "flex", gap: 10 }}>
              <Button
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                onPress={() => navigation.navigate("QuizPerformances", { quizId: _id || "" })}
              >
                View Quiz Scores
              </Button>
              <View style={{ display: "flex", gap: 10, flexDirection: "row" }}>
                {user?._id === quiz?.createdBy && (
                  <Button
                    mode="outlined"
                    textColor="#6A5AE0"
                    onPress={() => navigation.navigate("QuizEdit", { _id })}
                    style={{ flex: 1 }}
                  >
                    Edit Quiz
                  </Button>
                )}
                <Button
                  mode="outlined"
                  textColor="#6A5AE0"
                  style={{ flex: 1 }}
                  onPress={() => navigation.navigate("Quiz", { _id })}
                >
                  Take This Test
                </Button>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
}

export default QuizShareScreen;

const styles = StyleSheet.create({
  inlineButton: {},
  fullWidthButton: {
    width: "100%",
    padding: 5,
    textAlign: "center",
    marginTop: 10,
  },
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

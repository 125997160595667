import { Dimensions, Platform } from "react-native";

export const shouldHideHeaderBar = () => {
  if (Platform.OS !== "web") {
    return false;
  }

  if (Dimensions.get("screen").width >= 768) {
    return true;
  }

  return false;
};

export const shouldHideTabBar = () => {
  if (Platform.OS !== "web") {
    return false;
  }

  if (Dimensions.get("screen").width >= 768) {
    return true;
  }

  return false;
};

export const shouldShowSideBar = () => {
  if (Platform.OS !== "web") {
    return false;
  }

  if (Dimensions.get("screen").width >= 768) {
    return true;
  }

  return false;
};

export const isWebBigScreen = () => {
  if (Platform.OS !== "web") {
    return false;
  }

  if (Dimensions.get("screen").width >= 768) {
    return true;
  }

  return false;
};

import * as React from "react";
// import { Text } from 'react-native';
import { Button, Dialog, Portal, Text } from "react-native-paper";
import { DialogProps } from "../../types/Dialog";

function DialogComponent({ props }: { props: DialogProps }) {
  return (
    <Portal>
      <Dialog visible={props.visible} onDismiss={props.onDismiss}>
        <Dialog.Title>{props.title}</Dialog.Title>
        <Dialog.Content>
          <Text variant="bodyMedium">{props.content}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={props.onDismiss}>{props.dismissLabel || "Close"}</Button>
          {typeof props.onConfirm !== "undefined" && (
            <Button onPress={props.onConfirm}>{props.confirmLabel || "Confim"}</Button>
          )}
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

export default DialogComponent;

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as ExpoLinking from "expo-linking";
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import LatestQuestionList from "../components/home/LatestQuestionList";
import Missions from "../components/missions/Missions";
import ProfileFuneQ from "../components/profile/ProfileFuneQ";
import LatestQuizes from "../components/quiz/LatestQuizes";
import GradeTags from "../components/tags/GradeTags";
import HotTags from "../components/tags/HotTags";
import LeastPlayTags from "../components/tags/LeastPlayTags";
import WorstPlayTags from "../components/tags/WorstPlayTags";
import Container from "../components/utils/Container";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function HomeScreen({ navigation }: Props) {
  const { user } = useProfile();

  const url = ExpoLinking.useURL();

  if (url) {
    const { queryParams } = ExpoLinking.parse(url);

    if (queryParams) {
      switch (queryParams.screen) {
        case "QuizScreen":
          if (typeof queryParams.quizId === "string") {
            navigation.navigate("Quiz", { _id: queryParams.quizId });
            break;
          }
      }
    }
  }

  return (
    <Container>
      <ScrollView testID="homescreen" style={[styles.container]}>
        <View style={{ flexDirection: "column", gap: 15 }}>
          {/* <Recent navigation={navigation} title="Latest Quiz" /> */}
          {/* <Featured /> */}
          <Missions />
          <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <Button
              mode="contained"
              buttonColor="#FFF"
              textColor="#6A5AE0"
              style={{ flex: 1 }}
              onPress={() => navigation.navigate("QuizNew")}
            >
              Create Quiz
            </Button>
            <Button
              mode="outlined"
              textColor="#FFF"
              style={{ flex: 1, borderWidth: 2, borderColor: "#FFF" }}
              onPress={() => navigation.navigate("QuizJoin")}
            >
              Join Quiz
            </Button>
          </View>
          <View
            style={{
              marginBottom: 10,
              backgroundColor: "#FFF",
              borderRadius: 20,
              padding: 20,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Your Expertise</Text>
            <ProfileFuneQ funeQ={user?.funeQ || {}} />
          </View>
          <LeastPlayTags />
          <WorstPlayTags />
          <LatestQuizes />
          <GradeTags />
          <HotTags />

          <LatestQuestionList />
        </View>
      </ScrollView>
    </Container>
  );
}

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#6A5AE0",
    padding: 20,
    paddingTop: shouldShowSideBar() ? 0 : 20,
  },
});

import * as React from "react";
import { StyleSheet } from "react-native";
import { Button, Dialog, Portal, TextInput } from "react-native-paper";
import { useMutation } from "react-query";
import { PostRequest } from "../../scripts/ApiRequest";
import { isWebBigScreen } from "../../scripts/ScreenSize";
import useProfile from "../../scripts/userProfile";
import { UsernameUpdateDialogProps } from "../../types/Dialog";

function UsernameUpdateDialog({ props }: { props: UsernameUpdateDialogProps }) {
  const [username, setUsername] = React.useState<string>("");

  const { user, setUser, accessToken, showSnackBar } = useProfile();

  const updateProfileMutation = useMutation({
    mutationFn: async () => {
      return await PostRequest(`/updateUser?_id=${user?._id}`, { accessToken, username });
    },
    onSuccess: () => {
      // Should update user so when click next quiz it don't ask for username again
      if (user) {
        setUser({ ...user, username });
      }

      showSnackBar({ type: "SUCCESS", message: "Username updated successfully!" });
    },
  });

  const onConfirm = () => {
    if (username.trim().length === 0) {
      showSnackBar({ type: "ERROR", message: "Username can not be empty" });
      return;
    }

    if (username.trim().length > 50) {
      showSnackBar({ type: "ERROR", message: "Username too long" });
      return;
    }

    if (username.length > 0) {
      updateProfileMutation.mutate();
    }

    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  return (
    <Portal>
      <Dialog
        testID="UpdateUsernameDialog"
        visible={props.visible}
        onDismiss={props.onDismiss}
        style={{
          backgroundColor: "#FFF",
          borderRadius: 20,
          maxWidth: isWebBigScreen() ? 500 : "auto",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Dialog.Title style={{ color: "#000" }}>Please provide username</Dialog.Title>
        <Dialog.Content>
          <TextInput
            autoCapitalize="none"
            multiline={true}
            inputMode="text"
            testID="usernameInput"
            outlineColor={"#6A5AE0"}
            mode="outlined"
            theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
            style={styles.inputText}
            textColor="#858494"
            label="Username"
            value={username}
            onChangeText={(text) => setUsername(text)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            mode="contained"
            buttonColor="#6A5AE0"
            textColor="#FFF"
            onPress={props.onDismiss}
            style={{ borderRadius: 10 }}
          >
            Cancel
          </Button>
          <Button
            mode="contained"
            buttonColor="#6A5AE0"
            textColor="#FFF"
            onPress={onConfirm}
            testID="updateButton"
            style={{ borderRadius: 10 }}
          >
            Update
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

export default UsernameUpdateDialog;

const styles = StyleSheet.create({
  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { Dimensions, StyleSheet, Text, TextInput, View } from "react-native";
import { Button } from "react-native-paper";
import { useMutation } from "react-query";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";
import { UserUpdate } from "../types/User";

const SCREEN_WIDTH = Dimensions.get("window").width;

interface UserForm {
  username?: string;
  age?: string;
}

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function UpdateProfileScreen({ navigation }: Props) {
  const [userForm, setUserForm] = useState<UserForm>();

  const { user, accessToken, setUser, showSnackBar } = useProfile();

  const updateProfileMutation = useMutation({
    mutationFn: async (userProfile: UserUpdate) => {
      return await PostRequest(`/updateUser?_id=${user?._id}`, { accessToken, ...userProfile });
    },
    onSuccess: () => {
      showSnackBar({ type: "SUCCESS", message: "Profile updated successfully!" });
    },
  });

  const onUpdatePress = () => {
    if (typeof userForm?.username !== "undefined" && userForm?.username.trim() === "") {
      showSnackBar({ type: "ERROR", messageCode: "USERNAME_REQUIRED" });
      return;
    }

    if (typeof userForm?.age !== "undefined" && userForm?.age.trim() === "") {
      showSnackBar({ type: "ERROR", messageCode: "AGE_REQUIRED" });
      return;
    }

    if (typeof userForm?.age !== "undefined" && !Number.isInteger(parseInt(userForm?.age))) {
      showSnackBar({ type: "ERROR", messageCode: "AGE_ONLY_NUMBER" });
      return;
    }

    if (userForm) {
      updateProfileMutation.mutate(userForm);
    }

    if (user) {
      setUser({
        ...user,
        ...(userForm?.username && { username: userForm.username }),
        ...(userForm?.age && { age: parseInt(userForm.age) }),
      });
    }
  };

  return (
    <Container>
      <View
        testID="UpdateProfileScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            gap: 20,
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 20,
          }}
        >
          <View>
            <Text style={styles.label}>Username</Text>
            {/* We are using native TextInput because of this https://github.com/callstack/react-native-paper/issues/2955 */}
            <TextInput
              autoCapitalize="none"
              testID="UsernameInput"
              defaultValue={user?.username}
              style={styles.inputText}
              onChangeText={(text) => setUserForm({ ...userForm, username: text })}
            />
          </View>
          <View>
            <Text style={styles.label}>Age</Text>
            <TextInput
              inputMode="numeric"
              autoCapitalize="none"
              testID="AgeInput"
              defaultValue={user?.age?.toString()}
              style={styles.inputText}
              onChangeText={(text) => setUserForm({ ...userForm, age: text })}
            />
          </View>

          <View>
            <Button
              mode="text"
              onPress={() => navigation.navigate("ChangePassword", { email: user?.email || "" })}
            >
              Change Password
            </Button>
          </View>

          <View>
            <Button
              testID="UpdateBtn"
              style={styles.button}
              mode="contained"
              buttonColor="#6A5AE0"
              labelStyle={{ color: "#fff" }}
              onPress={() => onUpdatePress()}
            >
              Update
            </Button>
          </View>
        </View>
      </View>
    </Container>
  );
}

export default UpdateProfileScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    backgroundColor: "#fff",
    borderColor: "#6A5AE0",
    height: 40,
    borderWidth: 1,
    borderRadius: 8,
    padding: 10,
    color: "#858494",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

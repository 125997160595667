import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { shuffle, startCase } from "lodash";
import { StyleSheet, View } from "react-native";
import { Text, TouchableRipple } from "react-native-paper";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { RootNativeStackParamList } from "../../types/Screens";
import { TagStats } from "../../types/Tag";
import Skeleton from "../utils/Skeleton";

function LeastPlayTags() {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  const titles = [
    "Let's play something new",
    "Want to try something new?",
    "Hey you haven't tried these",
    "Unlock new knowledge",
  ];
  const { user } = useProfile();

  const getUserTagStats = async () => {
    const response = await GetRequest("/getUserTagsStats", { userId: user?._id });
    return response.data;
  };

  const { isLoading, data: tags }: { isLoading: boolean; data?: TagStats } = useQuery(
    `userTagStats`,
    getUserTagStats,
    { staleTime: 60000, enabled: typeof user !== "undefined" }
  );

  if (isLoading || !tags) {
    return <Skeleton width="100%" height={60} style={{ borderRadius: 20 }} />;
  }

  return (
    <View
      testID="LeastPlayTags"
      style={{
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 20,
      }}
    >
      <View style={styles.subHeader}>
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>
              {shuffle(titles)[0]}
            </Text>
            <Text style={{ marginTop: 5, fontSize: 12, color: "#CCC" }}>
              These topics you played least, let's learn something new.
            </Text>
          </View>
        </View>
      </View>
      <View
        testID="LeastPlayTagsList"
        style={{
          marginTop: 20,

          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 16,
        }}
      >
        {tags &&
          shuffle(tags.leastPlays)
            .slice(0, 3)
            .map((tag) => (
              <TouchableRipple
                key={tag}
                rippleColor="rgba(0, 0, 0, .32)"
                style={{
                  borderRadius: 10,
                  backgroundColor: "#C4D0FB",
                  padding: 10,
                }}
                onPress={() => navigation.navigate("Tag", { tags: [tag] })}
              >
                <View
                  style={{
                    flexDirection: "row",
                    padding: 10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "500",
                      maxWidth: "100%",
                      color: "#000",
                    }}
                  >
                    {startCase(tag)}
                  </Text>
                </View>
              </TouchableRipple>
            ))}
      </View>
    </View>
  );
}

export default LeastPlayTags;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    paddingTop: 5,
  },
  section: {
    padding: 0,
    margin: 0,
  },
  subHeader: {
    // color: "#000",
  },
  seeAll: {
    fontSize: 14,
    color: "#6A5AE0",
    fontWeight: "bold",
  },
  item: {
    border: "2px solid #EFEEFC",
    marginTop: 15,
    borderRadius: 10,
  },
  itemText: {},

  titleStyle: {},
});

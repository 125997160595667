import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import { QuestionRecord } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";
import QuestionList from "../question/QuestionList";
import Skeleton from "../utils/Skeleton";

function LatestQuestionList() {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  const limit = 10;
  const getLatestQuestions = async () => {
    const response = await GetRequest("/getLatestQuestions", { limit, status: "approved" });
    return response.data;
  };

  const {
    isLoading: latestQuestionsLoading,
    data: latestQuestions,
  }: { isLoading: boolean; data?: QuestionRecord[] } = useQuery(
    `latestQuestions${limit}`,
    getLatestQuestions,
    { staleTime: 60000 }
  );

  if (latestQuestionsLoading || !latestQuestions) {
    return <Skeleton width={"100%"} height={100} style={{ borderRadius: 20 }} />;
  }

  return (
    <View
      testID="LatestQuestionListContainer"
      style={{
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 20,
        // marginLeft: -20,
        // marginRight: -20,
      }}
    >
      <View style={styles.subHeader}>
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>New Questions</Text>
          </View>
          <View>
            <Text
              style={styles.seeAll}
              onPress={() => {
                navigation.navigate("QuestionList");
              }}
            >
              See all
            </Text>
          </View>
        </View>
      </View>
      <QuestionList questions={latestQuestions} />
    </View>
  );
}

export default LatestQuestionList;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    paddingTop: 5,
  },
  section: {
    padding: 0,
    margin: 0,
  },
  subHeader: {
    // color: "#000",
  },
  seeAll: {
    fontSize: 14,
    color: "#6A5AE0",
    fontWeight: "bold",
  },
  item: {
    border: "2px solid #EFEEFC",
    marginTop: 15,
    borderRadius: 10,
  },
  itemText: {},

  titleStyle: {},
});

import { useEffect, useState } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { getSnackBarMessage, MessageCode, SnackBarType } from "../../types/Snackbar";

interface Props {
  visible: boolean;
  messageCode: MessageCode;
  onHide: any;
  message: string;
  type: SnackBarType;
}

function SnackBar(props: Props) {
  const [visible, setVisible] = useState<boolean>(props.visible);
  const [messageCode, setMessageCode] = useState<MessageCode>(props.messageCode);
  const [message, setMessage] = useState<string>("Something went wrong");
  const [type, setType] = useState<SnackBarType>(props.type);

  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (props.visible) {
      setVisible(true);

      setTimeout(() => {
        setVisible(false);
        props.onHide();
      }, 3000);
    }

    setMessageCode(props.messageCode);

    if (props.message) {
      setMessage(props.message);
    } else if (props.messageCode) {
      setMessage(getSnackBarMessage(props.messageCode));
    }

    if (props.type) {
      setType(props.type);
    }
  }, [props.type, props.visible, props.messageCode, props.message]);

  if (!visible) {
    return <></>;
  }

  return (
    <View
      style={[
        styles.container,
        {
          marginTop: insets.top,
          backgroundColor:
            (type === "SUCCESS" ? "#53DF83" : "") ||
            (type === "ERROR" ? "#FF6666" : "") ||
            (type === "WARNING" ? "#e8d13a" : "") ||
            (type === "INFO" ? "#0d6efd" : ""),
        },
      ]}
      testID="snackbar"
      accessibilityLabel={`${type}SnackBar`}
    >
      <Text
        style={{
          color:
            (type === "SUCCESS" ? "#fff" : "") ||
            (type === "ERROR" ? "#fff" : "") ||
            (type === "WARNING" ? "#000" : "") ||
            (type === "INFO" ? "#FFF" : ""),
        }}
      >
        {message}
      </Text>
    </View>
  );
}

export default SnackBar;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: Dimensions.get("window").width - 10,
    margin: 5,
    backgroundColor: "red",
    borderRadius: 5,
    padding: 10,
    top: 10,
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FeedbackReplies from "../components/feedback/FeedbackReplies";
import Container from "../components/utils/Container";
import NotFound from "../components/utils/NotFound";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { Feedback, FeedbackStatusKey } from "../types/Feedback";
import { RootNativeStackParamList } from "../types/Screens";

const SCREEN_WIDTH = Dimensions.get("window").width;
const INPUT_HEIGHT_OFFSET = 40;

type Props = NativeStackScreenProps<RootNativeStackParamList, "Feedback">;
function FeedbackScreen({ navigation, route }: Props) {
  const { user, showSnackBar } = useProfile();
  const { _id }: { _id: string } = route.params;
  const [message, setMessage] = useState<string>("");
  const [inputMessageHeight, setMessageInputHeight] = useState<number>(INPUT_HEIGHT_OFFSET);
  const scrollViewRef = useRef<any>(null);
  const bottomTextRef = useRef<any>(null);

  const queryClient = useQueryClient();

  const getFeedbacks = async () => {
    const response = await GetRequest("/getFeedbacks", { userId: user?._id, _id: _id });
    if (response.data[0]) {
      return response.data[0];
    }

    return {};
  };

  const {
    isLoading,
    data: feedback,
    refetch,
  }: { isLoading: boolean; data?: Feedback; refetch: any } = useQuery(
    `feedbacks${_id}`,
    getFeedbacks,
    {
      enabled: typeof user !== "undefined" && typeof _id !== "undefined",
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    const updateStatus = async (statusKey: FeedbackStatusKey, statusValue: boolean) => {
      await PostRequest("/updateFeedbackStatus", {
        _id,
        statusKey,
        statusValue,
        userId: user?._id,
      });

      queryClient.invalidateQueries(`feedbacks${user?._id}`); // So the list have latest updated status
    };

    const scrollToBottom = () => {
      // Not sure which one actually work for bot web and app. And what's the proper way to use it.
      if (scrollViewRef.current?.scrollToEnd) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }

      if (bottomTextRef.current?.scrollIntoView) {
        bottomTextRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (feedback) {
      scrollToBottom();

      if (user?._id) {
        updateStatus("readByUser", true);
      }
    }
  }, [feedback]);

  useEffect(() => {
    const messageNewLines = message.split(/\r\n|\r|\n/).length;
    const messageTextLengthLines = Math.round(message.length / 30);

    const inputMessageHeight =
      (messageNewLines + messageTextLengthLines) * 20 + INPUT_HEIGHT_OFFSET;

    setMessageInputHeight(inputMessageHeight);
  }, [message]);

  const replyFeedback = useMutation({
    mutationFn: async (param: { _id: string; message: string; userId: string }) => {
      return await PostRequest(`/replyFeedback`, param);
    },
    onSuccess: () => {
      showSnackBar({ type: "SUCCESS", message: "Replied Send!" });
      setMessage("");
      refetch();
    },
  });

  const onReplyPress = async () => {
    if (message.trim().length === 0) {
      showSnackBar({ type: "WARNING", message: "Please enter some message" });
      return;
    }
    replyFeedback.mutate({ _id: _id, message: message, userId: user?._id || "" });
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (!feedback) {
    return <NotFound message="Oops, something went wrong. We could not find feedback" />;
  }

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <ScrollView style={{ paddingBottom: 60 }} ref={scrollViewRef}>
          <View style={styles.myReply}>
            <Text>{feedback.message}</Text>
          </View>
          <FeedbackReplies replies={feedback.replies || []} />
          <Text ref={bottomTextRef} />
        </ScrollView>

        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"}>
          <View
            style={{
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <View style={{ flex: 6 }}>
              <TextInput
                multiline={true}
                testID="MessageInput"
                outlineColor={"#6A5AE0"}
                mode="outlined"
                style={[styles.inputText, { height: inputMessageHeight }]} // Autogrow height hack
                textColor="#858494"
                placeholder="Your Reply"
                value={message || ""}
                theme={{ roundness: 20 }}
                onChangeText={(text) => setMessage(text)}
              />
            </View>
            <Button
              testID="ReplyBtn"
              style={styles.button}
              mode="contained"
              buttonColor="#fff"
              labelStyle={{
                color: "#6A5AE0",
              }}
              onPress={() => onReplyPress()}
            >
              Reply
            </Button>
          </View>
        </KeyboardAvoidingView>
      </View>
    </Container>
  );
}

export default FeedbackScreen;

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    alignSelf: "center",
    paddingTop: 8,
    paddingBottom: 12,
    flex: 2,
  },
  inputText: {
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "#fff",
    marginTop: -5,
  },

  myReply: {
    backgroundColor: "#FFF",
    padding: 20,
    borderRadius: 20,
    marginTop: 10,
    marginLeft: 40,
    borderBottomEndRadius: 0,
    alignSelf: "flex-end",
  },
});

import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import QuestionsPerformance from "../components/question/QuestionsPerformance";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { timeAgo } from "../components/utils/TimeAgo";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen, shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuizQuestionsVotesRecord } from "../types/Quiz";
import { AggregateQuizUsers } from "../types/QuizUser";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuizPerformances">;
function QuizPerformanceScreen({ route, navigation }: Props) {
  const [topic, setTopic] = useState<string>("");
  const { user, showSnackBar } = useProfile();
  const {
    quizId,
  }: {
    quizId?: string;
  } = route.params;

  const getUserQuizes = async () => {
    const response = await GetRequest("/getUserQuizes", {
      quizId,
    });
    return response.data;
  };

  const getQuizQuestionsVotesPerformance = async () => {
    const response = await GetRequest("/getQuizQuestionsVotesPerformance", {
      quizId,
    });
    return response.data;
  };

  const {
    isLoading: questionsVotesLoading,
    data: questionsVotes,
  }: { isLoading: boolean; data?: QuizQuestionsVotesRecord[] } = useQuery(
    `QuestionsVotes${quizId}`,
    getQuizQuestionsVotesPerformance,
    {
      enabled: typeof quizId !== "undefined",
      refetchInterval: 5000,
    }
  );

  const {
    isLoading: userQuizesLoading,
    data: userQuizes,
  }: { isLoading: boolean; data?: AggregateQuizUsers[] } = useQuery(
    `UserQuizes${quizId}`,
    getUserQuizes,
    {
      enabled: typeof quizId !== "undefined",
      refetchInterval: 5000,
    }
  ); // Should not have stale time

  if (userQuizes && userQuizes.length === 0) {
    return (
      <Container>
        <ScrollView
          style={{
            flex: 1,
            backgroundColor: "#FFF",
            padding: 20,
            margin: 20,
            marginTop: shouldShowSideBar() ? 0 : 20,
            borderRadius: 20,
          }}
        >
          <View testID="QuizPerformanceScreen" style={{ flex: 1, gap: 20 }}>
            <Text style={{ textAlign: "center" }}>No one have taken quiz yet</Text>
          </View>
        </ScrollView>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View testID="QuizPerformanceScreen" style={{ flex: 1, gap: 20 }}>
          <View>
            {isWebBigScreen() && (
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>Live Performance</Text>
            )}
          </View>

          <View>
            <View>
              <Text style={{ fontWeight: "bold", fontSize: 16 }}>Ranked Quiz Takers</Text>
              <Text style={{ color: "#CCC" }}>
                *We only count users who took quiz for the first time. Subsequent quiz takers won't
                be ranked here.
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                // backgroundColor: "#E8E5FA",
                padding: 2,
                borderRadius: 20,
                justifyContent: "space-around",
                marginTop: 10,
                gap: 2,
              }}
            >
              <View style={styles.card}>
                <Text style={styles.cardValue}>#</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>User</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Vote</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>
                  {isWebBigScreen() ? (
                    `Correct`
                  ) : (
                    <FontAwesome name="check" size={18} color="black" aria-label="Correct" />
                  )}
                </Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>
                  {isWebBigScreen() ? (
                    `Points`
                  ) : (
                    <MaterialIcons name="score" size={18} color="black" aria-label="Points" />
                  )}
                </Text>
              </View>
              <View style={[styles.card, { flex: 2 }]}>
                <Text style={styles.cardValue}>Date</Text>
              </View>
            </View>
            {userQuizesLoading && <Skeleton width={"100%"} />}
            {userQuizes &&
              userQuizes
                .sort((a, b) => b.totalPoint - a.totalPoint)
                .filter((a) => a.recentPlay === false)
                .map((userQuiz, index) => (
                  <View
                    key={userQuiz._id}
                    style={{
                      flexDirection: "row",
                      padding: 2,
                      justifyContent: "space-around",
                      gap: 2,
                    }}
                  >
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{index + 1}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.user?.username}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.totalVote}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.totalCorrect}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{Math.round(userQuiz.totalPoint)}</Text>
                    </View>

                    <View style={[styles.card, { flex: 2 }]}>
                      <Text style={styles.cardLabel}>{timeAgo(userQuiz.updatedAt)}</Text>
                    </View>
                  </View>
                ))}
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>Top Correct</Text>
            {questionsVotes && (
              <QuestionsPerformance
                questionsVotes={questionsVotes
                  .sort(
                    (a, b) =>
                      (b.performance?.totalCorrect || 0) - (a.performance?.totalCorrect || 0)
                  )
                  .slice(0, 3)}
              />
            )}
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>Top Wrong</Text>
            {questionsVotes && (
              <QuestionsPerformance
                questionsVotes={questionsVotes
                  .sort(
                    (a, b) => (b.performance?.totalWrong || 0) - (a.performance?.totalWrong || 0)
                  )
                  .slice(0, 3)}
              />
            )}
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>All Questions Performance</Text>
            {questionsVotes && <QuestionsPerformance questionsVotes={questionsVotes} />}
          </View>

          <View>
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>All Quiz Takers</Text>
            <Text style={{ color: "#CCC" }}>
              *There are users who took quiz multiple time. They will all shown here
            </Text>
            <View
              style={{
                flexDirection: "row",
                // backgroundColor: "#E8E5FA",
                padding: 2,
                borderRadius: 20,
                justifyContent: "space-around",
                marginTop: 10,
                gap: 2,
              }}
            >
              <View style={styles.card}>
                <Text style={styles.cardValue}>User</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>Vote</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>
                  {isWebBigScreen() ? (
                    `Correct`
                  ) : (
                    <FontAwesome name="check" size={18} color="black" />
                  )}
                </Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardValue}>
                  {isWebBigScreen() ? (
                    `Points`
                  ) : (
                    <MaterialIcons name="score" size={18} color="black" aria-label="Points" />
                  )}
                </Text>
              </View>
              <View style={[styles.card, { flex: 2 }]}>
                <Text style={styles.cardValue}>Date</Text>
              </View>
            </View>
            {userQuizesLoading && <Skeleton width={"100%"} />}
            {userQuizes &&
              userQuizes
                .sort((a, b) => (new Date(b.updatedAt) > new Date(a.updatedAt) ? -1 : 1))
                .map((userQuiz, index) => (
                  <View
                    key={userQuiz._id}
                    style={{
                      flexDirection: "row",
                      padding: 2,
                      justifyContent: "space-around",
                      gap: 2,
                    }}
                  >
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.user?.username}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.totalVote}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userQuiz.totalCorrect}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{Math.round(userQuiz.totalPoint)}</Text>
                    </View>

                    <View style={[styles.card, { flex: 2 }]}>
                      <Text style={styles.cardLabel}>{timeAgo(userQuiz.updatedAt)}</Text>
                    </View>
                  </View>
                ))}
          </View>
          {userQuizes && userQuizes.length === 0 && (
            <View>
              <Text style={{ textAlign: "center" }}>No one have taken test yet</Text>
            </View>
          )}
        </View>
      </ScrollView>
    </Container>
  );
}

export default QuizPerformanceScreen;

const styles = StyleSheet.create({
  inlineButton: {},
  fullWidthButton: {
    width: "100%",
    padding: 5,
    textAlign: "center",
    marginTop: 10,
  },
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
  card: {
    backgroundColor: "#E8E5FA",
    padding: 5,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { startCase } from "lodash";
import { StyleSheet, Text, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { RootNativeStackParamList } from "../../types/Screens";
import { TagCount } from "../../types/Tag";
import Skeleton from "../utils/Skeleton";

type NavigateToParam = "Tag" | "TrueFalse" | "Learn";

function TagList({
  tags,
  navigateTo,
  params,
}: {
  tags: TagCount[];
  navigateTo: NavigateToParam;
  params?: { tags?: string[] };
}) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const onTagPress = (navigateTo: NavigateToParam, tag: TagCount) => {
    const finalTags = params?.tags ? [...params.tags, tag._id] : [tag._id];

    if (navigateTo === "TrueFalse") {
      navigation.navigate("TrueFalse", {
        tags: finalTags,
      });

      return;
    }

    if (navigateTo === "Learn") {
      navigation.navigate("Learn", {
        tags: finalTags,
      });

      return;
    }

    if (navigateTo === "Tag") {
      navigation.navigate("Tag", {
        tags: finalTags,
      });

      return;
    }
  };

  if (!tags) {
    return <Skeleton />;
  }

  return (
    <View
      testID="TagList"
      style={{
        backgroundColor: "#C4D0FB",
        borderRadius: 20,
        padding: 16,
        paddingTop: 24,
        marginTop: 20,
      }}
    >
      {tags.map((tag, index) => (
        <TouchableRipple
          key={tag._id}
          rippleColor="rgba(0, 0, 0, .32)"
          style={{
            borderRadius: 20,
          }}
          onPress={() => onTagPress(navigateTo, tag)}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              // gap: 10,
              padding: 10,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <View style={{ flex: 1, flexWrap: "wrap" }}>
              <Text
                style={{
                  fontWeight: "500",
                  paddingLeft: 10,
                  maxWidth: "100%",
                }}
              >
                {startCase(tag._id)}
              </Text>
            </View>
            <View style={{ marginRight: 20 }}>
              <Text
                style={{
                  backgroundColor: "#FFF",
                  borderRadius: 10,
                  textAlign: "center",
                  color: "#6A5AE0",
                  fontSize: 12,
                  fontWeight: "bold",
                  padding: 3,
                  paddingLeft: 4,
                  paddingRight: 5,
                }}
              >
                {tag.count}
              </Text>
            </View>
            <View>
              <MaterialIcons
                name="arrow-forward-ios"
                size={15}
                color="#6A5AE0"
                style={{ marginTop: 3 }}
              />
            </View>
          </View>
        </TouchableRipple>
      ))}
    </View>
  );
}

export default TagList;

const styles = StyleSheet.create({});

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { shuffle } from "lodash";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useQuery } from "react-query";
import { logEvent } from "../../scripts/analytic";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { Mission as MissionInterface } from "../../types/Mission";
import { RootNativeStackParamList } from "../../types/Screens";
import { TagStats } from "../../types/Tag";
import Skeleton from "../utils/Skeleton";

const missionMessages = {
  TOTAL_VOTE: "Answer total of NNNN questions",
  TOTAL_CORRECT: "Have total of NNNN correct answers",
  TOTAL_TRUE_FALSE: "Play NNNN true false questions",
  TOTAL_LEARN: "Learn NNNN questions",
  TOTAL_TAG_VOTE: "Play total of NNNN question on topic TTTT",
  TOTAL_TAG_CORRECT: "Have total of NNNN correct answers on topic TTTT",
  TOTAL_TAG_TRUE_FALSE: "Play NNNN true false questions on topic TTTT",
  TOTAL_TAG_LEARN: "Learn NNNN questions on topic TTTT",
};

interface Props {
  mission: MissionInterface;
}

const missionText = (mission: MissionInterface) => {
  const missionText = missionMessages[mission.type];

  return missionText
    .replace("NNNN", mission.params.target.toString())
    .replace("TTTT", mission.params.value || "");
};

function Mission({ mission }: Props) {
  const { user } = useProfile();
  const getUserTagsStats = async () => {
    const response = await GetRequest("/getUserTagsStats", { userId: user?._id });
    return response.data;
  };

  const { isLoading, data: UserTagStats }: { isLoading: boolean; data?: TagStats } = useQuery(
    `userTagsStats`,
    getUserTagsStats,
    {
      staleTime: 60000,
      enabled: typeof user !== "undefined",
    }
  );

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const onMissionPress = (mission: MissionInterface) => {
    let screen = "";
    let tag = "";
    switch (mission.type) {
      case "TOTAL_VOTE":
        screen = "Tag";
        tag = shuffle(UserTagStats?.leastPlays)[0];

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Tag", { tags: [tag] });
        break;

      case "TOTAL_CORRECT":
        screen = "Tag";
        tag = shuffle(UserTagStats?.leastPlays)[0];

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Tag", { tags: [tag] });
        break;

      case "TOTAL_TRUE_FALSE":
        screen = "TrueFalse";
        tag = shuffle(UserTagStats?.leastPlays)[0];

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("TrueFalse", { tags: [tag] });
        break;

      case "TOTAL_LEARN":
        screen = "Learn";
        tag = shuffle(UserTagStats?.leastPlays)[0];

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Learn", { tags: [tag] });
        break;

      case "TOTAL_TAG_VOTE":
        screen = "Tag";
        tag = mission.params.value || "";

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Tag", { tags: [tag] });
        break;

      case "TOTAL_TAG_CORRECT":
        screen = "Tag";
        tag = mission.params.value || "";

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Tag", { tags: [tag] });
        break;

      case "TOTAL_TAG_TRUE_FALSE":
        screen = "TrueFalse";
        tag = mission.params.value || "";

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("TrueFalse", { tags: [tag] });
        break;

      case "TOTAL_TAG_LEARN":
        screen = "Learn";
        tag = mission.params.value || "";

        logEvent("click_mission", {
          type: mission.type,
          userId: user?._id,
          screen,
          tag,
        });
        navigation.navigate("Learn", { tags: [tag] });
        break;
    }
  };

  if (isLoading) {
    <Skeleton />;
  }

  return (
    <TouchableOpacity style={styles.container} onPress={() => onMissionPress(mission)}>
      <Text>
        {missionText(mission)}
        <Text style={styles.progressText}>
          ({mission.params.done}/{mission.params.target})
        </Text>
      </Text>
      <ProgressBar
        progress={mission.progress}
        color="#6A5AE0"
        style={{ backgroundColor: "#EFEEFC", marginTop: 5 }}
      />
    </TouchableOpacity>
  );
}

export default Mission;

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  progressText: {
    fontSize: 8,
    color: "#CCC",
    marginLeft: 10,
  },
});

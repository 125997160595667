import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import Svg, { G, Path } from "react-native-svg";
import { RootNativeStackParamList } from "../../types/Screens";
import Container from "../utils/Container";

const screenWidth = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function SinginOrSignup({ navigation }: Props) {
  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
        }}
      >
        <View
          style={{
            position: "absolute",
            top: -20,
          }}
        >
          <Svg width={screenWidth} height="552" viewBox="0 0 375 552" fill="none">
            <Path
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 462C90.2742 462 144 408.274 144 342C144 275.726 90.2742 222 24 222C-42.2742 222 -96 275.726 -96 342C-96 408.274 -42.2742 462 24 462Z"
              fill="#C4D0FB"
            />
            <Path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 551C139.428 551 233 457.428 233 342C233 226.572 139.428 133 24 133C-91.4275 133 -185 226.572 -185 342C-185 457.428 -91.4275 551 24 551Z"
              stroke="#C4D0FB"
            />
            <Path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M336 45C341.523 45 346 40.5228 346 35C346 29.4772 341.523 25 336 25C330.477 25 326 29.4772 326 35C326 40.5228 330.477 45 336 45Z"
              fill="#C4D0FB"
            />
            <Path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M58.5 49C72.031 49 83 38.031 83 24.5C83 10.969 72.031 0 58.5 0C44.969 0 34 10.969 34 24.5C34 38.031 44.969 49 58.5 49Z"
              fill="#C4D0FB"
            />
            <Path
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M386.5 292C422.122 292 451 263.122 451 227.5C451 191.878 422.122 163 386.5 163C350.878 163 322 191.878 322 227.5C322 263.122 350.878 292 386.5 292Z"
              fill="#C4D0FB"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171 237C174.866 237 178 233.866 178 230C178 226.134 174.866 223 171 223C167.134 223 164 226.134 164 230C164 233.866 167.134 237 171 237Z"
              fill="#7C72E5"
            />
          </Svg>
        </View>
        <View
          style={{
            position: "absolute",
            top: -70,
            alignSelf: "center",
          }}
        >
          <Svg width="300" height="548" viewBox="0 0 686 548" fill="none">
            <G id="Illustration">
              <G id="Person / Man / 6">
                <G id="Man / Head / 1">
                  <G id="Group 30">
                    <Path
                      id="Fill 1"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M214.341 82.816C214.341 90.3267 209.436 96.415 203.384 96.415C197.333 96.415 192.427 90.3267 192.427 82.816C192.427 75.3054 197.333 69.217 203.384 69.217C209.436 69.217 214.341 75.3054 214.341 82.816Z"
                      fill="#19141E"
                    />
                    <G id="Face color">
                      <Path
                        id="Face"
                        d="M188.412 136.689L187.954 121.069C187.954 121.069 189.599 121.199 192.031 121.329C204.261 121.983 213.041 113.442 213.265 101.196C213.393 94.1504 212.778 86.5043 210.338 80.7457L210.216 80.1943L169.62 75.5093L167.344 98.4933C165.976 94.5137 154.69 90.0011 151.74 95.6999C145.347 108.048 168.206 116.363 168.206 116.363L165.885 136.876"
                        fill="#FFCCD7"
                      />
                      <Path id="Path" d="M156.197 100.525L164.267 106.484" stroke="#0C092A" />
                    </G>
                    <Path
                      id="Fill 5"
                      opacity="0.398926"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M187.954 121.069L174.982 118.325L187.892 126.929L187.954 121.069Z"
                      fill="#0C092A"
                    />
                    <Path
                      id="Path_2"
                      d="M198.498 96.6556L201.539 107.347L197.171 108.531"
                      stroke="#001833"
                      strokeLinecap="square"
                    />
                    <Path
                      id="Fill 9"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M193.543 98.6176C193.811 100.074 192.83 101.467 191.355 101.73C189.879 101.994 188.466 101.027 188.199 99.5713C187.932 98.1153 188.912 96.7215 190.388 96.458C191.864 96.1949 193.276 97.1615 193.543 98.6176Z"
                      fill="#001833"
                    />
                    <Path
                      id="Fill 11"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M208.607 98.6176C208.874 100.074 207.894 101.467 206.418 101.73C204.942 101.994 203.529 101.027 203.263 99.5713C202.996 98.1153 203.975 96.7215 205.451 96.458C206.927 96.1949 208.34 97.1615 208.607 98.6176Z"
                      fill="#001833"
                    />
                    <Path
                      id="Hair"
                      d="M170.358 102.416C166.509 95.9167 160.94 93.9345 158.44 93.3532C157.443 90.5102 156.683 87.213 156.149 83.7062C154.717 74.3209 161.634 65.758 167.22 63.2992C172.875 60.8108 182.626 57.3626 196.899 62.0433C206.495 65.1924 212.628 57.6532 218.275 60.8731C227.923 66.3769 218.949 90.4724 194.483 84.3513C176.982 79.9725 176.532 81.0709 176.382 84.2537C175.897 94.5418 174.271 100.594 170.358 102.416Z"
                      fill="#001833"
                    />
                    <G id="Group 21">
                      <Path
                        id="Stroke 20"
                        d="M190.497 110.456C190.497 110.456 192.412 114.523 196.55 114.427"
                        stroke="#001833"
                      />
                    </G>
                    <Path
                      id="Stroke 22"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M197.022 98.7469C197.022 102.132 194.24 104.876 190.809 104.876C187.378 104.876 184.596 102.132 184.596 98.7469C184.596 95.3619 187.378 92.6177 190.809 92.6177C194.24 92.6177 197.022 95.3619 197.022 98.7469Z"
                      stroke="#001833"
                    />
                    <Path
                      id="Stroke 24"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M212.665 98.7469C212.665 102.132 209.884 104.876 206.453 104.876C203.022 104.876 200.24 102.132 200.24 98.7469C200.24 95.3619 203.022 92.6177 206.453 92.6177C209.884 92.6177 212.665 95.3619 212.665 98.7469Z"
                      stroke="#001833"
                    />
                    <Path
                      id="Stroke 26"
                      d="M199.734 99.0327H197.047"
                      stroke="#001833"
                      strokeWidth="4"
                    />
                    <Path id="Stroke 28" d="M184.596 98.7485H169.249" stroke="#001833" />
                  </G>
                </G>
                <G id="Man / Legs / 1">
                  <G id="Group 23">
                    <G id="Shoes">
                      <Path
                        id="Shoes_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M204.388 487.734L204.539 474.924L187.773 466.605L185.34 503.225H243.427C233.544 492.257 204.388 487.734 204.388 487.734ZM129.982 488.233L130.133 473.846L119.202 482.613L110.407 487.438L107.504 503.225H147.26C142.262 489.813 129.982 488.233 129.982 488.233Z"
                        fill="#0D0B6D"
                      />
                    </G>
                    <G id="Leg skin">
                      <Path
                        id="Skin"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M130.133 473.846L113.367 468.882L110.406 487.437L129.982 488.233L130.133 473.846ZM204.539 474.924L187.773 469.961L186.422 486.938L204.389 487.733L204.539 474.924Z"
                        fill="#FFCCD7"
                      />
                    </G>
                    <G id="Pants">
                      <Path
                        id="Pants_2"
                        d="M206.942 470.724L206.555 482.613H184.44L184.828 470.689C182.35 469.71 180.58 467.355 180.52 464.573L176.96 286.504L138.496 466.28C137.954 468.809 136.05 470.773 133.632 471.497L132.186 482.613H108.998L110.993 470.34C109.063 468.866 107.944 466.441 108.328 463.85L143.741 225.58L175.742 225.579L175.74 225.489H214.5L212.177 464.24C212.145 467.38 209.927 470.006 206.942 470.724Z"
                        fill="#09074A"
                      />
                    </G>
                    <Path
                      id="Fill 15"
                      opacity="0.197824"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M176.763 289.326L183.55 259.688L178.444 349.541L176.763 289.326Z"
                      fill="#0C092A"
                    />
                    <G id="Pants line">
                      <Path
                        id="Pants line_2"
                        d="M152.02 225.806L155.735 226.403L115.229 469.217L111.503 468.605L152.02 225.806Z"
                        fill="#EFEFEF"
                      />
                    </G>
                  </G>
                </G>
                <G id="Man / Body / 5">
                  <G id="Group 18">
                    <G id="Skin color">
                      <Path
                        id="Skin_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M267.739 143.906L267.472 139.385C267.472 139.385 267.478 136.239 267.893 132.844C267.997 131.992 268.226 131.101 268.47 130.149L268.47 130.149C269.199 127.308 270.067 123.928 268.17 119.437C265.636 113.448 271.693 117.583 273.034 120.432C273.552 121.533 273.703 123.198 273.853 124.848V124.849C274.026 126.758 274.197 128.65 274.934 129.63C276.094 129.01 276.866 128.203 276.866 128.203L289.92 111.63C290.341 111.073 291.118 110.906 291.741 111.234L292.443 111.609C293.183 112.005 293.417 112.932 292.943 113.609C292.038 114.91 290.457 117.105 288.804 119.398C285.806 123.56 282.572 128.049 282.709 128.12L283.055 128.531L298.388 112.708C298.867 112.182 299.67 112.089 300.261 112.49L301.182 113.115C301.852 113.568 301.996 114.474 301.496 115.099C299.861 117.165 295.814 121.64 292.444 125.366L292.444 125.366L292.444 125.366C289.529 128.589 287.121 131.251 287.222 131.307L287.68 131.739L301.953 119.401C302.459 118.849 303.321 118.786 303.907 119.25L304.604 119.807C305.216 120.292 305.306 121.172 304.806 121.765L291.325 134.172L291.714 134.771L303.417 126.724C303.954 126.255 304.763 126.255 305.306 126.719L305.631 127.005C306.206 127.505 306.269 128.364 305.769 128.943C304.973 129.865 303.396 131.077 301.516 132.522L301.516 132.522L301.516 132.522C299.353 134.184 296.789 136.155 294.551 138.349C293.318 139.557 292.324 140.741 291.317 141.941L291.317 141.941C289.671 143.901 287.991 145.903 285.179 148.119C283.5 149.447 281.981 150.219 280.535 150.954C280.331 151.058 280.128 151.161 279.926 151.265L267.739 143.906ZM162.518 133.534C162.518 133.534 171.537 133.516 178.047 133.495C184.555 133.473 192.741 136.166 192.741 136.166C192.741 136.166 197 145.581 182.205 144.728C167.41 143.875 162.518 133.534 162.518 133.534ZM113.187 171.631C113.187 171.631 98.7489 192.955 99.0762 204.813C99.4625 218.795 144.883 253.151 144.883 253.151L156.654 243.717L128.06 204.493L138.459 182.698L113.187 171.631Z"
                        fill="#FFCCD7"
                      />
                    </G>
                    <G id="Sweater color">
                      <Path
                        id="Sweater"
                        d="M153.234 226.62L160.266 235.903L139.087 254.065C139.087 254.065 100.222 226.039 99.0221 204.066C98.2579 190.07 110.703 170.092 110.703 170.092C110.703 170.092 126.324 145.529 144.382 137.239C148.087 135.538 157.072 134.119 162.518 133.534C164.188 133.354 163.19 133.994 163.763 135.077C164.361 136.209 166.184 137.886 167.445 139.139C170.069 141.748 175.241 143.723 178.019 144.127C180.137 144.435 184.228 144.913 186.197 144.653C188.165 144.393 190.305 143.519 190.877 143C191.448 142.48 192.299 141.83 192.741 140.829C193.182 139.828 193.159 140.115 193.159 139.192C193.159 138.268 193.111 138.405 193.019 137.965C192.927 137.526 192.927 137.262 192.873 137.035C192.633 136.03 192.741 136.166 192.741 136.166C203.4 139.334 208.8 140.506 212.683 145.114L242.36 165.605L262.666 139.537L288.866 156.844C288.866 156.844 265.668 199.365 248.856 204.066C235.313 207.852 221.594 196.076 216.77 191.352L222.501 219.976C223.42 224.919 219.021 226.789 215.102 226.614L153.234 226.62Z"
                        fill="#9087E5"
                      />
                    </G>
                    <Path
                      id="Fill 9_2"
                      opacity="0.201242"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M216.68 191.369L213.133 177.578L222.71 196.425L216.68 191.369Z"
                      fill="#0C092A"
                    />
                    <Path
                      id="Stroke 16"
                      opacity="0.200591"
                      d="M150.604 223.296L134.065 200.219L154.043 170.412"
                      stroke="#0C092A"
                      strokeWidth="5"
                    />
                  </G>
                </G>
              </G>
              <G id="Person / Man / 3">
                <G id="Man / Head / 1_2">
                  <G id="Group 21_2">
                    <G id="Face color_2">
                      <Path
                        id="Face_2"
                        d="M497.84 145.563L498.295 130.18C498.295 130.18 496.779 130.299 494.51 130.422C482.201 131.09 473.394 122.483 473.425 110.156C473.441 103.628 474.101 96.3704 476.181 89.9242L516.507 85.3101L519.376 107.993C520.735 104.074 531.337 99.5825 534.268 105.195C540.618 117.357 517.911 125.545 517.911 125.545L520.217 145.748"
                        fill="#FFCCD7"
                      />
                      <Path
                        id="Path_3"
                        d="M529.462 110.456L521.391 114.428"
                        stroke="#0C092A"
                        strokeLinecap="square"
                      />
                    </G>
                    <Path
                      id="Path_4"
                      d="M488.595 106.534L487.108 113.655L490.299 114.264"
                      stroke="#001833"
                      strokeLinecap="square"
                    />
                    <Path
                      id="Fill 4"
                      opacity="0.399344"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M498.295 130.18L511.181 127.478L498.357 135.952L498.295 130.18Z"
                      fill="#0C092A"
                    />
                    <Path
                      id="Fill 8"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M494.761 106.082C494.495 107.516 495.469 108.889 496.935 109.148C498.401 109.408 499.804 108.455 500.069 107.022C500.334 105.588 499.361 104.215 497.895 103.955C496.429 103.696 495.025 104.648 494.761 106.082Z"
                      fill="#001833"
                    />
                    <Path
                      id="Fill 10"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M478.738 106.082C478.473 107.516 479.446 108.889 480.912 109.148C482.378 109.408 483.781 108.455 484.047 107.022C484.312 105.588 483.338 104.215 481.872 103.955C480.406 103.696 479.003 104.648 478.738 106.082Z"
                      fill="#001833"
                    />
                    <G id="Group 15">
                      <Path
                        id="Stroke 14"
                        d="M495.162 118.401C495.162 118.401 492.454 122.717 487.091 122.351"
                        stroke="#001833"
                      />
                    </G>
                    <Path
                      id="Hair_2"
                      d="M526.998 103.145C528.789 96.6309 535.269 72.5869 534.505 68.4822C533.622 63.7252 530.236 57.8855 512.841 51.7605C498.103 46.571 489.79 50.3288 487.271 53.5262C483.439 58.3915 476.181 89.9245 476.181 89.9245C476.181 89.9245 482.187 94.1048 503.483 93.3838C503.483 93.3838 500.535 105.925 512.62 105.204C512.62 105.204 511.325 109.427 517.173 110.756C520.382 105.875 524.641 103.902 526.998 103.145Z"
                      fill="#001833"
                    />
                  </G>
                </G>
                <G id="Man / Legs / 1_2">
                  <G id="Group 20">
                    <G id="Shoes_3">
                      <Path
                        id="Shoes_4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M603.892 451.585L587.691 436.956L593.319 422.16L627.991 447.509L599.905 495.785C599.905 495.785 599.002 483.788 600.471 470.499C601.485 461.318 603.892 451.585 603.892 451.585ZM447.788 483.543L451.488 471.798L467.153 472.613L454.115 504.867L404.035 476.298C418.897 471.868 447.788 483.543 447.788 483.543Z"
                        fill="#0D0B6D"
                      />
                    </G>
                    <G id="Skin legs">
                      <Path
                        id="Skin_3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M591.775 440.684L593.556 422.146L613.512 437.016L603.892 451.585L591.775 440.684ZM450.836 473.783L466.324 475.156L460.606 488.807L447.788 483.542L450.836 473.783Z"
                        fill="#FFCCD7"
                      />
                    </G>
                    <G id="Pants_3">
                      <Path
                        id="Pants_4"
                        d="M480.183 286.87L436.979 477.068L448.879 480.057H448.884L462.737 483.542L462.807 483.558L467.602 484.763C467.602 484.763 489.613 416.393 508.991 355.401C513.706 362.96 519.017 370.431 524.994 377.698C567.458 429.326 593.44 451.394 593.44 451.394L597.303 445.661L597.314 445.645L606.601 431.867L613.317 421.902C613.317 421.902 555.169 367.864 529.007 291.846C533.605 277.046 536.793 266.481 537.612 263.1C542.301 243.823 542.396 234.991 542.396 234.287H492.125L471.966 234.287C472.7 246.849 474.728 265.532 480.183 286.869L480.183 286.87Z"
                        fill="#B9B2F0"
                      />
                    </G>
                    <G id="Overlay">
                      <Path
                        id="Mask"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M436.979 477.068L448.879 480.057H448.884L462.737 483.542L462.807 483.558L467.602 484.763C467.602 484.763 489.613 416.393 508.991 355.401C516.554 331.59 523.712 308.906 529.006 291.846C533.605 277.046 536.793 266.481 537.612 263.1C542.301 243.823 542.396 234.991 542.396 234.287H492.125L480.183 286.869L436.979 477.068Z"
                        fill="#B9B2F0"
                      />
                    </G>
                  </G>
                </G>
                <G id="Man / Body / 5_2">
                  <G id="Group 25">
                    <G id="Hands">
                      <Path
                        id="Mask_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M454.94 245.254C454.94 245.254 462.108 246.112 463.473 249.165C467.375 257.899 459.289 273.513 459.289 273.513C459.289 273.513 462.715 271.524 463.397 273.264C463.701 274.038 464.388 280.223 451.551 280.64C450.188 280.684 446.909 278.046 446.909 278.046C446.909 278.046 449.3 282.39 445.639 283.244C445.639 283.244 439.74 274.832 439.65 272.96C439.56 271.088 441.453 261.613 442.477 259.7C443.501 257.786 454.94 245.254 454.94 245.254ZM551.883 260.49C553.126 256.994 554.228 255.14 554.228 255.14L572.946 256.005C574.847 261.161 575.052 262.931 575.516 266.923C575.537 267.103 575.559 267.288 575.581 267.477C576.006 271.114 576.035 274.77 574.59 279.636C573.662 282.762 572.671 285.421 572.155 285.309C570.854 285.026 570.805 283.433 570.805 283.433L571.418 280.473L571.695 277.582C571.695 277.582 567.078 290.38 566.686 290.309C565.303 290.06 565.082 287.817 565.082 287.817C565.082 287.817 563.635 292.234 562.907 292.074C561.054 291.667 561.23 289.621 561.23 289.621C561.23 289.621 560.645 290.11 560.195 289.986C558.518 289.524 558.568 286.688 558.597 285.025C558.6 284.892 558.602 284.766 558.603 284.65C558.603 284.65 559.346 279.417 559.194 277.805C558.421 269.589 557.089 267.635 556.358 266.563C556.289 266.462 556.225 266.368 556.168 266.278C555.021 267.023 554.596 268.298 554.145 269.653C553.817 270.638 553.476 271.664 552.832 272.558C550.875 275.279 545.416 277.85 548.468 271.616C550.163 268.155 550.584 266.026 550.975 264.049C551.204 262.89 551.423 261.783 551.883 260.49Z"
                        fill="#FFCCD7"
                      />
                    </G>
                    <Path
                      id="Stroke 4"
                      opacity="0.150646"
                      d="M456.667 256.537C456.667 256.537 454.866 263.013 448.684 265.031C446.522 265.736 447.062 274.066 441.764 273.069"
                      stroke="#0C092A"
                      strokeWidth="3"
                    />
                    <G id="Shirt">
                      <Path
                        id="Shirt_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M522.02 145.926L519.637 136.119L509.28 136.749L502.16 145.374L497.53 137.457L494.225 137.661L493.198 146.817C478.122 152.476 475.492 158.012 472.193 164.957C471.838 165.704 471.476 166.467 471.089 167.248C466.738 176.041 472.05 237.073 472.05 237.073L537.353 234.127C537.353 234.127 528.504 146.589 522.708 145.994C522.479 145.97 522.249 145.948 522.02 145.926Z"
                        fill="#EFEFEF"
                      />
                    </G>
                    <Path
                      id="Stroke 8"
                      d="M473.375 237.177L470.312 188.909"
                      stroke="#12173A"
                      strokeWidth="5"
                    />
                    <G id="Coat">
                      <Path
                        id="Coat_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M551.618 273.869L556.267 294.596L521.316 297.401C521.316 297.401 518.314 198.196 518.609 169.03C518.744 155.674 522.707 145.999 522.707 145.999C522.707 145.999 538.241 150.473 541.763 152.762C550.059 158.154 568.093 183.988 571.302 197.644C576.342 219.081 575.82 256.138 575.82 256.138L549.349 254.914L541.722 214.644L540.559 224.953L550.176 267.59C549.845 268.653 549.569 269.455 549.349 269.997C548.244 272.713 547.611 274.295 547.745 274.885C547.868 275.434 548.412 275.764 549.745 275.095C550.099 274.917 550.723 274.509 551.618 273.869ZM470.099 254.92L466.496 262.274L443.335 249.659L462.228 203.045C462.228 203.045 467.143 166.941 470.105 162.12C475.832 152.799 493.085 146.863 493.085 146.863C493.085 146.863 493.08 146.868 493.08 146.874C492.908 147.306 492.77 149.807 492.46 155.413C492.251 159.203 491.963 164.413 491.534 171.361C490.585 186.726 481.579 297.003 481.579 297.003L469.12 295.56L470.099 254.92Z"
                        fill="#09074A"
                      />
                    </G>
                    <G id="Lapel">
                      <Path
                        id="Shape"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M534.77 176.339L519.051 209.021C519.051 209.021 518.588 173.07 518.802 164.421C518.953 158.334 522.707 145.999 522.707 145.999L528.947 151.058C532.908 154.27 533.927 159.807 531.36 164.169L531.278 164.31L532.542 165.432C535.655 168.193 536.558 172.619 534.77 176.339ZM493.085 146.863L488.693 208.712L477.234 178.27C475.569 173.848 476.91 168.878 480.588 165.835L481.076 165.432L480.857 164.327C480.035 160.176 481.623 155.918 484.986 153.262L493.085 146.863Z"
                        fill="#0C092A"
                      />
                    </G>
                    <Path
                      id="Stroke 23"
                      opacity="0.197498"
                      d="M541.847 215.305L540.309 207.784L532.527 193.721"
                      stroke="#0C092A"
                      strokeWidth="5"
                    />
                  </G>
                </G>
              </G>
            </G>
          </Svg>
        </View>

        <View style={styles.container}>
          <Text style={{ fontWeight: "700", marginTop: 10, textAlign: "center" }}>
            Login Or Signup
          </Text>
          <Text style={{ color: "#858494", marginTop: 10, textAlign: "center" }}>
            To save your progress and not to loose data, we recommend to signup
          </Text>
          <Button
            mode="contained"
            buttonColor="#6A5AE0"
            textColor="#FFF"
            style={{ marginTop: 20 }}
            onPress={() => navigation.navigate("Login")}
          >
            Login
          </Button>
          <Button
            mode="contained"
            buttonColor="#E6E6E6"
            textColor="#6A5AE0"
            style={{ marginTop: 10 }}
            onPress={() => navigation.navigate("Signup")}
          >
            Create an account
          </Button>

          <Button
            mode="outlined"
            textColor="#6A5AE0"
            style={{ marginTop: 30, width: 100 }}
            onPress={() => navigation.navigate("Debug")}
          >
            Debug
          </Button>
        </View>
      </View>
    </Container>
  );
}

export default SinginOrSignup;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 10,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 320,
  },
});

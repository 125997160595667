import { StyleSheet, Text, View } from "react-native";
import useProfile from "../../scripts/userProfile";
import { Reply } from "../../types/Feedback";
import Skeleton from "../utils/Skeleton";

interface Props {
  replies: Reply[];
}

function FeedbackReplies(props: Props) {
  const { user } = useProfile();

  if (!user) {
    return <Skeleton />;
  }

  return (
    <>
      {props.replies &&
        props.replies.map((reply: Reply, index: number) => (
          <View
            key={reply.createdAt}
            style={user?._id === reply.by ? styles.myReply : styles.otherReply}
          >
            <Text
              // ref={props.replies.length === index + 1 ? props.bottomRef : null}
              style={user?._id === reply.by ? styles.myReplyText : styles.otherReplyText}
            >
              {reply.message}
            </Text>
          </View>
        ))}
    </>
  );
}

export default FeedbackReplies;

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    width: 100,
    marginTop: 10,
  },
  inputText: {
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "#fff",
  },

  myReply: {
    backgroundColor: "#FFF",
    padding: 20,
    borderRadius: 20,
    marginTop: 10,
    marginLeft: 40,
    borderBottomEndRadius: 0,
    alignSelf: "flex-end",
  },

  myReplyText: {
    // textAlign: "right",
  },

  otherReplyText: {
    // textAlign: "left",
  },

  otherReply: {
    alignSelf: "flex-start",
    backgroundColor: "#FFF",
    padding: 20,
    borderRadius: 20,
    marginTop: 10,
    marginRight: 40,
    borderBottomLeftRadius: 0,
  },
});

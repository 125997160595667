export type SnackBarType = "SUCCESS" | "INFO" | "WARNING" | "ERROR";

export type MessageCode =
  | "EMAIL_ALREADY_EXIST"
  | "EMAIL_REQUIRED"
  | "USER_NOT_FOUND"
  | "PASSWORD_INCORRECT"
  | "PASSWORD_REQUIRED"
  | "PARAMETERS_INVALID"
  | "PLEASE_SELECT_ANSWER"
  | "USERNAME_REQUIRED"
  | "AGE_REQUIRED"
  | "AGE_ONLY_NUMBER"
  | "SUCCESS"
  | "ERROR"
  | "OTHER";

export const getSnackBarMessage = (messageCode: MessageCode) => {
  switch (messageCode) {
    case "EMAIL_ALREADY_EXIST":
      return "Email already exist";

    case "EMAIL_REQUIRED":
      return "Email required";

    case "PASSWORD_REQUIRED":
      return "Password required";

    case "USER_NOT_FOUND":
      return "Can not find any user with this email. Please signup first";

    case "PLEASE_SELECT_ANSWER":
      return "Please select answer first";

    case "USERNAME_REQUIRED":
      return "Please enter username";

    case "AGE_REQUIRED":
      return "Please enter age";

    case "AGE_ONLY_NUMBER":
      return "Please enter number only for age";

    case "SUCCESS":
      return "Success";

    case "ERROR":
      return "Error";

    default:
      return "Something went wrong";
  }
};

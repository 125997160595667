import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Dimensions, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import TagList from "../components/tags/TagList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import { RootNativeStackParamList } from "../types/Screens";
import { TagCount } from "../types/Tag";

const screenWidth = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function TrueFalseTagsScreen({ navigation }: Props) {
  const getTags = async () => {
    const response = await GetRequest("/getTags");
    return response.data;
  };

  const { isLoading: tagsLoading, data: tags }: { isLoading: boolean; data?: TagCount[] } =
    useQuery(`tags`, getTags, {
      staleTime: 60000,
    });

  if (tagsLoading) {
    return (
      <View
        style={{
          flex: 1,
          gap: 10,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 60 : 120,
        }}
      >
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
      </View>
    );
  }

  return (
    <Container>
      <View
        testID="TrueFalseTagsScreen"
        style={{
          backgroundColor: "#FFF",
          flex: 1,
          borderRadius: 20,
          margin: 20,
          marginTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: "bold", padding: 20 }}>Select Topic</Text>
        <ScrollView
          style={{
            padding: 20,
            paddingTop: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            flex: 1,
          }}
        >
          <TagList
            tags={
              tags
                ?.filter((tag) => tag._id.includes("grade"))
                .sort((a, b) => (a._id < b._id ? -1 : 1)) || []
            }
            navigateTo="TrueFalse"
          />
          <TagList
            tags={tags?.filter((tag) => !tag._id.includes("grade") && tag.count > 10) || []}
            navigateTo="TrueFalse"
          />
        </ScrollView>
      </View>
    </Container>
  );
}

export default TrueFalseTagsScreen;

const styles = StyleSheet.create({
  itemsContainer: {
    backgroundColor: "#fff",
    margin: 10,
    marginTop: Platform.OS === "web" ? 60 : 120,
    borderRadius: 20,
    padding: 20,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
  },
  item: {
    backgroundColor: "#EFEEFC",
    width: screenWidth / 2 - 40, //Offset of padding etc.
    height: screenWidth / 2 - 40,
    textAlign: "center",
    borderRadius: 15,
  },

  itemText: {
    color: "#6A5AE0",
    textAlign: "center",
  },
});

export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const timeAgo = (dateStr: string) => {
  if (isNaN(Date.parse(dateStr))) {
    return dateStr;
  }

  const date = new Date(dateStr);

  const today = new Date();
  const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const months = Math.round(days / 30);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  if (hours < 24) {
    return `${hours} hours ago`;
  }

  if (days < 32) {
    return `${days} days ago`;
  }

  if (months < 12) {
    return `${months} days ago`;
  }

  return date.getDate() + " " + MONTH_NAMES[date.getMonth()] + ", " + date.getFullYear();
};

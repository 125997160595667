import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";

const getApiUrl = () => {
  if (process.env.NODE_ENV === "test") {
    return "http://127.0.0.1:5001/quiz-78ce0/us-central1/staging";
  }

  const installedVersion = Constants.expoConfig?.version;
  const noDecimal = installedVersion?.replaceAll(".", "");

  if (process.env.NODE_ENV === "production") {
    return `https://us-central1-quiz-78ce0.cloudfunctions.net/production${noDecimal}`;
  }

  if (process.env.NODE_ENV === "staging") {
    return "https://us-central1-quiz-78ce0.cloudfunctions.net/staging";
  }

  // When open in expo
  if (Platform.OS !== "web") {
    return "https://us-central1-quiz-78ce0.cloudfunctions.net/staging";
  }

  return "http://127.0.0.1:5001/quiz-78ce0/us-central1/staging";
};

const apiUrl = getApiUrl();

export const GetRequest = async (url: string, data: any = {}, message: string = "Getting data") => {
  const postData = { ...data };
  let accessToken = await AsyncStorage.getItem("accessToken");
  if (process.env.NODE_ENV === "test") {
    accessToken = "asdf";
  }
  const appVersion = Constants.expoConfig?.version;
  if (!postData.accessToken && accessToken !== null) {
    postData.accessToken = accessToken;
  }

  if (!postData.appVersion && appVersion !== null) {
    postData.appVersion = appVersion;
  }

  try {
    return await axios.get(`${apiUrl}${url}?${new URLSearchParams(postData).toString()}`);
  } catch (e: any) {
    // alert(`Network Error: ${message}`);
    throw e;
  }
};

export const PostRequest = async (url: string, data: any, message: string = "Posting data") => {
  const postData = { ...data };
  let accessToken = await AsyncStorage.getItem("accessToken");
  if (process.env.NODE_ENV === "test") {
    accessToken = "asdf";
  }
  const appVersion = Constants.expoConfig?.version;
  if (!postData.accessToken && accessToken !== null) {
    postData.accessToken = accessToken;
  }

  if (!postData.appVersion && appVersion !== null) {
    postData.appVersion = appVersion;
  }

  try {
    const response = await axios.post(`${apiUrl}${url}`, postData);
    return response;
  } catch (e: any) {
    // alert(`Network Error: ${message}`);
    throw e;
  }
};

// We will use this to update data
export const PatchRequest = async (url: string, data: any, message: string = "Posting data") => {
  const postData = { ...data };
  let accessToken = await AsyncStorage.getItem("accessToken");
  if (process.env.NODE_ENV === "test") {
    accessToken = "asdf";
  }
  const appVersion = Constants.expoConfig?.version;

  if (!postData.accessToken && accessToken !== null) {
    postData.accessToken = accessToken;
  }

  if (!postData.appVersion && appVersion !== null) {
    postData.appVersion = appVersion;
  }

  try {
    const response = await axios.patch(`${apiUrl}${url}`, postData);
    return response;
  } catch (e: any) {
    // alert(`Network Error: ${message}`);
    throw e;
  }
};

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { Text, View } from "react-native";
import { Button, Modal, Portal } from "react-native-paper";
import Svg, { Path } from "react-native-svg";
import Container from "../components/utils/Container";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function QuizNewScreen({ navigation }: Props) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { user, registered } = useProfile();

  const onCreateQuizPress = () => {
    if (!registered) {
      setModalVisible(true);
    } else {
      navigation.navigate("QuizCreate");
    }
  };

  return (
    <Container>
      <View style={{ flex: 1, backgroundColor: "#6A5AE0", height: "100%", padding: 20 }}>
        <View style={{ alignItems: "center" }}>
          <Svg width="318" height="243" viewBox="0 0 318 243" fill="none">
            <Path d="M17.2576 181.179V242.686" stroke="#9087E5" strokeWidth="5" />
            <Path d="M81.7137 181.179V242.686" stroke="#9087E5" strokeWidth="5" />
            <Path d="M95.7653 120.093H0V215.852H95.7653V120.093Z" fill="#C4D0FB" />
            <Path d="M359.996 86.8584H95.7651V215.855H359.996V86.8584Z" fill="#C4D0FB" />
            <Path d="M32.8158 120.093V188.682" stroke="#6A5AE0" strokeLinecap="square" />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5371 141.056C18.6841 141.056 21.2349 138.505 21.2349 135.358C21.2349 132.211 18.6841 129.66 15.5371 129.66C12.3905 129.66 9.83936 132.211 9.83936 135.358C9.83936 138.505 12.3905 141.056 15.5371 141.056Z"
              stroke="#6A5AE0"
              strokeLinecap="square"
            />
            <Path
              d="M41.6901 152.774C41.6901 152.774 39.386 130.608 62.6187 130.608C85.8514 130.608 102.393 152.774 102.393 152.774"
              fill="#0C092A"
            />
            <Path
              d="M108.443 140.369L98.6069 137.837L103.617 131.915C103.701 131.815 103.808 131.74 103.93 131.694C104.051 131.648 104.182 131.634 104.31 131.653C104.439 131.673 104.56 131.724 104.662 131.804C104.765 131.883 104.845 131.988 104.896 132.107L108.443 140.369Z"
              fill="#0C092A"
            />
            <Path
              d="M93.4894 131.95L98.4749 137.837L88.6392 140.368L92.1682 132.149C92.2209 132.027 92.3043 131.919 92.4104 131.838C92.5161 131.756 92.6411 131.703 92.7733 131.683C92.9054 131.663 93.0407 131.677 93.166 131.724C93.291 131.771 93.4025 131.848 93.4894 131.95Z"
              fill="#0C092A"
            />
            <Path
              d="M98.7061 156.104C105.422 156.104 110.867 151.656 110.867 146.169C110.867 140.682 105.422 136.234 98.7061 136.234C91.9895 136.234 86.5449 140.682 86.5449 146.169C86.5449 151.656 91.9895 156.104 98.7061 156.104Z"
              fill="#0C092A"
            />
            <Path d="M114.341 152.578H87.7676V161.399H114.341V152.578Z" fill="#C4D0FB" />
            <Path d="M182.856 152.774H32.8154" stroke="#6A5AE0" strokeLinecap="square" />
            <Path
              d="M94.0098 141.757C94.0098 140.895 94.9357 140.197 96.0788 140.197C97.2219 140.197 98.1442 140.909 98.1442 141.757"
              fill="white"
            />
            <Path
              d="M104.227 141.757C104.227 140.895 105.152 140.197 106.296 140.197C107.439 140.197 108.365 140.909 108.365 141.757"
              fill="white"
            />
            <Path
              d="M99.1055 147.226C99.1055 147.226 101.826 147.062 101.826 144.488C101.826 144.488 102.656 147.023 104.358 147.023"
              stroke="white"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <Path
              d="M100.559 143.647H102.91C102.976 143.655 103.038 143.68 103.09 143.721C103.143 143.761 103.183 143.816 103.205 143.878C103.228 143.94 103.233 144.008 103.22 144.073C103.207 144.137 103.176 144.198 103.131 144.246L101.955 145.25C101.892 145.299 101.815 145.327 101.735 145.327C101.654 145.327 101.576 145.299 101.514 145.25L100.339 144.246C100.293 144.198 100.262 144.137 100.249 144.073C100.236 144.008 100.241 143.94 100.264 143.878C100.287 143.816 100.327 143.761 100.379 143.721C100.431 143.68 100.494 143.655 100.559 143.647Z"
              fill="#C4D0FB"
            />
            <Path
              d="M66.4214 165.825C63.0063 161.858 54.773 169.956 49.6201 166.672C41.5221 161.513 55.8271 138.791 50.7561 135.465C47.6188 133.414 39.6276 149.484 41.0271 161.271C41.8925 168.553 48.1494 173.823 55.9518 173.292C60.5848 172.975 68.5866 168.339 66.4214 165.825Z"
              fill="#0C092A"
            />
            <Path d="M277.575 181.179V242.686" stroke="#9087E5" strokeWidth="5" />
            <Path
              d="M294.472 93.101L264.751 88.9989L260.517 108.26L292.254 119.27C303.115 123.041 314.176 114.114 312.773 102.701C311.37 91.2885 309.87 80.0005 309.244 78.5298C307.93 75.4604 303.322 62.5343 275.492 61.3628C247.661 60.1913 234.218 85.762 234.218 85.762L291.744 137.185"
              fill="#9087E5"
            />
            <Path
              d="M279.38 28.1759C281.346 15.852 273.728 4.39025 262.361 2.57562C250.991 0.76099 240.183 9.28077 238.213 21.605C236.247 33.9292 243.868 45.3907 255.235 47.2053C266.603 49.0199 277.411 40.5002 279.38 28.1759Z"
              fill="#0C092A"
            />
            <Path
              d="M268.779 71.6215C266.927 71.3982 265.197 70.5771 263.851 69.2823C262.508 67.9876 261.621 66.2898 261.329 64.4463L256.746 35.5782C256.543 34.4543 256.568 33.3013 256.817 32.1871C257.067 31.0726 257.537 30.0193 258.199 29.0888C258.862 28.1583 259.702 27.3692 260.671 26.7678C261.643 26.1664 262.725 25.7651 263.851 25.587C264.98 25.409 266.133 25.4581 267.241 25.7312C268.348 26.004 269.392 26.4958 270.311 27.1773C271.226 27.8589 271.995 28.7163 272.575 29.6998C273.156 30.683 273.537 31.7726 273.69 32.9039L278.284 61.7471C278.462 62.8596 278.416 63.9958 278.156 65.0912C277.892 66.1865 277.418 67.2195 276.756 68.1311C276.094 69.0427 275.26 69.8154 274.299 70.4044C273.341 70.9933 272.273 71.3879 271.162 71.5645C270.375 71.6931 269.573 71.7119 268.779 71.6215Z"
              fill="#FFCCD7"
            />
            <Path
              d="M262.828 57.4101L261.076 57.6772C259.039 57.9877 256.956 57.8449 254.979 57.2598C253.003 56.6744 251.176 55.6606 249.638 54.2908C248.096 52.9205 246.878 51.228 246.062 49.3329C245.25 47.4378 244.866 45.3875 244.937 43.3268L245.293 33.1177C245.404 29.821 246.675 26.6689 248.883 24.2162C251.087 21.7635 254.089 20.1671 257.355 19.7074C259.178 19.4492 261.037 19.5532 262.821 20.0133C264.605 20.473 266.279 21.2799 267.753 22.3877C269.224 23.4955 270.463 24.8824 271.4 26.4695C272.336 28.0563 272.952 29.8121 273.209 31.6364L274.612 41.5784C275.128 45.2372 274.181 48.9519 271.973 51.9164C269.769 54.8804 266.482 56.8546 262.828 57.4101Z"
              fill="#FFCCD7"
            />
            <Path d="M251.967 35.0938L248.505 43.7081L252.01 44.1959" stroke="#25282D" />
            <Path
              d="M255.994 39.0716C256.941 39.0716 257.71 38.3031 257.71 37.3551C257.71 36.4072 256.941 35.6387 255.994 35.6387C255.047 35.6387 254.277 36.4072 254.277 37.3551C254.277 38.3031 255.047 39.0716 255.994 39.0716Z"
              fill="#0C092A"
            />
            <Path
              d="M247.8 38.0958C248.747 38.0958 249.516 37.3273 249.516 36.3793C249.516 35.4313 248.747 34.6628 247.8 34.6628C246.853 34.6628 246.083 35.4313 246.083 36.3793C246.083 37.3273 246.853 38.0958 247.8 38.0958Z"
              fill="#0C092A"
            />
            <Path
              d="M258.402 46.4141C258.402 46.4141 256.298 46.2788 255.197 47.9953"
              stroke="#25282D"
            />
            <Path
              d="M311.694 56.9578C311.694 56.9578 314.087 75.7166 298.35 75.5278C272.301 75.2109 268.12 58.1008 269.926 48.2158C270.638 44.2775 273.077 41.3611 275.787 38.5694C261.739 35.4892 262.697 26.295 262.697 26.295C262.697 26.295 236.576 38.6726 238.093 22.1822C239.61 5.69169 255.044 -0.8283 265.652 0.0832876C290.309 2.19846 291.036 17.9055 297.652 20.1667C335.158 33.0001 311.694 56.9578 311.694 56.9578Z"
              fill="#0C092A"
            />
            <Path
              d="M279.063 38.1233C280.79 35.0901 279.733 31.2309 276.699 29.5035C273.665 27.7757 269.804 28.8347 268.077 31.8679L265.236 36.8592C263.508 39.8924 264.566 43.752 267.6 45.4794C270.634 47.2068 274.494 46.1481 276.222 43.1146L279.063 38.1233Z"
              fill="#FFCCD7"
            />
            <Path d="M274.79 33.2812L270.755 40.3643" stroke="#25282D" />
            <Path d="M266.008 88.0908L294.767 92.3926" stroke="#6A5AE0" strokeLinecap="square" />
            <Path d="M286.883 91.2109L284.54 80.9158" stroke="#6A5AE0" strokeLinecap="square" />
            <Path
              d="M243.741 90.7364C243.741 90.7364 256.233 80.4276 266.696 93.4106C277.158 106.394 258.861 104.15 258.861 104.15"
              fill="#FFCCD7"
            />
            <Path
              d="M267.265 105.294H206.341L170.73 80.1003H231.653L267.265 105.294Z"
              fill="#B9B2F0"
            />
            <Path
              d="M144.371 243.001H183.921L188.906 242.948L185.092 227.888L167.201 229.244L144.371 243.001Z"
              fill="#0C092A"
            />
            <Path
              d="M111.926 169.696L147.851 192.005L152.413 194.764L161.237 169.432L142.221 167.242L111.926 169.696Z"
              fill="#0C092A"
            />
            <Path
              d="M211.469 116.033L279.38 154.466H192.393V229.109H155.453V138.449C155.453 126.068 166.136 116.033 179.291 116.033H211.469Z"
              fill="#09074A"
            />
            <Path
              d="M210.169 124.828L246.005 151.339C259.053 160.989 277.2 156.681 284.992 142.052L295.348 122.941L222.398 83.8853C211.288 77.9422 197.713 81.1363 190.088 91.4914L136.579 164.166L164.356 187.03L210.169 124.828Z"
              fill="#09074A"
            />
            <Path d="M359.996 120.093H264.231V215.852H359.996V120.093Z" fill="#C4D0FB" />
            <Path
              d="M198.325 116.033L246.005 151.339C246.005 151.339 251.749 156.542 263.099 156.542"
              stroke="#6A5AE0"
            />
            <Path
              d="M170.823 117.653C170.823 117.653 174.933 116.03 179.292 116.03H198.326"
              stroke="#6A5AE0"
            />
            <Path d="M155.959 137.847V181.005" stroke="#6A5AE0" />
            <Path
              d="M228.559 35.7102H173.825C172.116 35.7102 170.73 37.0957 170.73 38.8046V77.8213C170.73 79.5302 172.116 80.9157 173.825 80.9157H228.559C230.268 80.9157 231.653 79.5302 231.653 77.8213V38.8046C231.653 37.0957 230.268 35.7102 228.559 35.7102Z"
              fill="#B9B2F0"
            />
            <Path d="M296.227 121.161V189.75" stroke="#6A5AE0" strokeLinecap="square" />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M280.387 141.056C283.535 141.056 286.085 138.505 286.085 135.358C286.085 132.211 283.535 129.66 280.387 129.66C277.239 129.66 274.689 132.211 274.689 135.358C274.689 138.505 277.239 141.056 280.387 141.056Z"
              stroke="#6A5AE0"
              strokeLinecap="square"
            />
            <Path d="M286.085 120.15H360" stroke="#6A5AE0" strokeLinecap="square" />
            <Path
              d="M201.192 63.1227C204.38 63.1227 206.965 60.5385 206.965 57.3502C206.965 54.1619 204.38 51.5776 201.192 51.5776C198.004 51.5776 195.419 54.1619 195.419 57.3502C195.419 60.5385 198.004 63.1227 201.192 63.1227Z"
              fill="white"
            />
            <Path
              d="M206.249 46.5242C206.249 46.5242 205.917 48.7712 204.347 49.8467C202.777 50.9221 200.562 50.4307 200.562 50.4307C200.562 50.4307 200.893 48.1837 202.463 47.1046C204.034 46.0256 206.249 46.5242 206.249 46.5242Z"
              fill="white"
            />
          </Svg>
        </View>
        <View style={{ flex: 1, backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <View testID="QuizNewScreen" style={{ justifyContent: "space-between", flex: 1 }}>
            <View style={{ display: "flex", gap: 10 }}>
              <Button
                testID="StartNewQuiz"
                mode="contained"
                buttonColor="#6A5AE0"
                textColor="#FFF"
                onPress={onCreateQuizPress}
              >
                Start Creating New Quiz
              </Button>
              <Button
                testID="MyQuizes"
                mode="outlined"
                textColor="#6A5AE0"
                onPress={() => navigation.navigate("MyQuizes")}
              >
                My Existing Quizes
              </Button>
            </View>
          </View>
          <View>
            <Text style={{ textAlign: "center" }}>
              If you have any suggestions on features let us know.
            </Text>
            <Button
              testID="ContactUs"
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              style={{ marginTop: 20 }}
              onPress={() => navigation.navigate("ContactUs")}
            >
              Contact Us
            </Button>
          </View>
          <Portal>
            <Modal
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
              contentContainerStyle={{
                backgroundColor: "white",
                padding: 20,
                margin: 20,
                alignSelf: "center",
              }}
            >
              <View style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                  Continue without login?
                </Text>
                <View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                  <Text>
                    We see that you are not logged in. While it is fine to create quiz but your
                    progress/data can be easily lost.
                  </Text>
                  <Text>
                    By logging in, you will be able to edit and view your existing quiz in future.
                  </Text>
                </View>
                <View style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 10,
                    }}
                  >
                    <Button
                      buttonColor="#6A5AE0"
                      textColor="#FFF"
                      onPress={() => {
                        navigation.navigate("Login");
                        setModalVisible(false);
                      }}
                    >
                      Login
                    </Button>
                    <Button
                      buttonColor="#6A5AE0"
                      textColor="#FFF"
                      onPress={() => {
                        navigation.navigate("Signup");
                        setModalVisible(false);
                      }}
                    >
                      Signup
                    </Button>
                  </View>
                  <Button
                    mode="outlined"
                    textColor="#6A5AE0"
                    onPress={() => {
                      navigation.navigate("QuizCreate");
                      setModalVisible(false);
                    }}
                  >
                    Continue without login.
                  </Button>
                </View>
              </View>
            </Modal>
          </Portal>
        </View>
      </View>
    </Container>
  );
}

export default QuizNewScreen;

import { View } from "react-native";
import Svg, { Path } from "react-native-svg";

function ProfileHeaderSVG() {
  return (
    <>
      <View style={{ position: "absolute", right: 0 }}>
        <Svg width="144" height="114" viewBox="0 0 144 114" fill="none">
          <Path
            opacity="0.1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.4375 69.25C131.366 69.25 157.25 43.3665 157.25 11.4375C157.25 -20.4915 131.366 -46.375 99.4375 -46.375C67.5085 -46.375 41.625 -20.4915 41.625 11.4375C41.625 43.3665 67.5085 69.25 99.4375 69.25Z"
            fill="#C4D0FB"
          />
          <Path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101 113C156.228 113 201 68.2285 201 13C201 -42.2285 156.228 -87 101 -87C45.7715 -87 1 -42.2285 1 13C1 68.2285 45.7715 113 101 113Z"
            stroke="#C4D0FB"
          />
        </Svg>
      </View>
      <View style={{ position: "absolute" }}>
        <Svg width="131" height="202" viewBox="0 0 131 202" fill="none">
          <Path
            opacity="0.1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.4375 157.25C60.3665 157.25 86.25 131.366 86.25 99.4375C86.25 67.5085 60.3665 41.625 28.4375 41.625C-3.49146 41.625 -29.375 67.5085 -29.375 99.4375C-29.375 131.366 -3.49146 157.25 28.4375 157.25Z"
            fill="#C4D0FB"
          />
          <Path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 201C85.2285 201 130 156.228 130 101C130 45.7715 85.2285 1 30 1C-25.2285 1 -70 45.7715 -70 101C-70 156.228 -25.2285 201 30 201Z"
            stroke="#C4D0FB"
          />
        </Svg>
      </View>
    </>
  );
}

export default ProfileHeaderSVG;

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View } from "react-native";
import Settings from "../components/setting/Settings";
import SinginOrSignup from "../components/setting/SigninOrSignup";
import Skeleton from "../components/utils/Skeleton";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function SettingScreen({ route, navigation }: Props) {
  const { signedIn, registered } = useProfile();

  if (typeof signedIn === "undefined") {
    return (
      <View
        style={{
          padding: 15,
          backgroundColor: "#6A5AE0",
          flex: 1,
        }}
      >
        <Skeleton width="100%" height={50} />
      </View>
    );
  }

  return signedIn && registered ? (
    <Settings navigation={navigation} route={route} />
  ) : (
    <SinginOrSignup navigation={navigation} route={route} />
  );
}

export default SettingScreen;

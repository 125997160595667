import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from "expo-device";
import { getIpAddressAsync } from "expo-network";
import { Dimensions, Platform } from "react-native";
import { EventType } from "../types/Event";
import { PostRequest } from "./ApiRequest";

export const logEvent = async (eventName: EventType, eventParams: any = {}) => {
  const ipAddress = await getIpAddressAsync();

  const SCREEN_WIDTH = Dimensions.get("window").width;
  const SCREEN_HEIGHT = Dimensions.get("window").height;

  if (!eventParams.userId) {
    eventParams.userId = await AsyncStorage.getItem("userId");
  }

  const event = {
    eventName: eventName,
    createdAt: new Date(),
    env: process.env.NODE_ENV,
    device: {
      brand: Device.brand,
      manufacturer: Device.manufacturer,
      modelId: Device.modelId,
      modelName: Device.modelName,
      designName: Device.designName,
      productName: Device.productName,
      deviceType: Device.deviceType,
      deviceYearClass: Device.deviceYearClass,
      totalMemory: Device.totalMemory,
      platformOS: Platform.OS || "",
      osName: Device.osName,
      osVersion: Device.osVersion,
      osBuildId: Device.osBuildId,
      deviceName: Device.deviceName,
      ipAddress: ipAddress,
      platformVersion: Platform.Version || "",
      screenWidth: SCREEN_WIDTH,
      screenHeight: SCREEN_HEIGHT,
    },
    ...eventParams,
  };

  if (process.env.NODE_ENV === "test") {
    return;
  }

  try {
    await PostRequest("/logEvent", { event });
  } catch (e) {}
};

import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { View } from "react-native";
import { PaperProvider } from "react-native-paper";
import { useQueryClient } from "react-query";
import SnackBar from "../components/commons/SnackBar";
import DialogComponent from "../components/utils/Dialog";
import { default as UpdateUsernameDialog } from "../components/utils/UpdateUsernameDialog";
import { DialogProps, UsernameUpdateDialogProps } from "../types/Dialog";
import { MessageCode, SnackBarType } from "../types/Snackbar";
import { UserRecord } from "../types/User";
import { PostRequest } from "./ApiRequest";

interface ProfileContext {
  user?: UserRecord;
  setUser: React.Dispatch<React.SetStateAction<UserRecord | undefined>>;
  signedIn: boolean;
  accessToken?: string;
  screenLoading?: boolean;
  setScreenLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
  setShowUsernameUpdateDialog: React.Dispatch<React.SetStateAction<UsernameUpdateDialogProps>>;
  showSnackBar: ({
    type,
    messageCode,
    message,
  }: {
    type: SnackBarType;
    messageCode?: MessageCode;
    message?: string;
  }) => void;
  registered: boolean;
  signOut: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContext | null>(null);

const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const [screenLoading, setScreenLoading] = useState<boolean>(true);
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [user, setUser] = useState<UserRecord>();

  const [accessToken, setAccessToken] = useState<string>();
  const [dialog, setDialog] = useState<DialogProps>({ visible: false });
  const [showUsernameUpdateDialog, setShowUsernameUpdateDialog] =
    useState<UsernameUpdateDialogProps>({
      visible: false,
    });
  const [snackBar, setSnackBar] = useState<{
    visible: boolean;
    type?: SnackBarType;
    messageCode?: MessageCode;
    message?: string;
  }>({
    visible: false,
  });

  const queryClient = useQueryClient();

  const showSnackBar = ({
    type,
    messageCode,
    message,
  }: {
    type: SnackBarType;
    messageCode?: MessageCode;
    message?: string;
  }) => {
    setSnackBar({
      type,
      visible: true,
      messageCode: messageCode || (type === "SUCCESS" ? "SUCCESS" : "ERROR"),
      message,
    });
  };

  const loginAsGuest = async () => {
    const response = await PostRequest("/loginAsGuest", {});
    return response.data;
  };

  const loginByAccessToken = async () => {
    const response = await PostRequest("/loginByAccessToken", {});
    return response.data;
  };

  const signOut = async () => {
    setUser(undefined);
    setSignedIn(false);
    setRegistered(false);
    setAccessToken(undefined);
    await AsyncStorage.removeItem("accessToken");

    // Re-authenticate user so it get authenticate as new guest
    await authenticateUser();
  };

  const authenticateUser = async () => {
    const accessToken = await AsyncStorage.getItem("accessToken");
    let response: { user: UserRecord; accessToken: string };

    if (!accessToken) {
      response = await loginAsGuest();
      await AsyncStorage.setItem("accessToken", response.accessToken);
    } else {
      response = await loginByAccessToken();

      // For whatever case if loginByAccessToken doesn't return any user then loginAsGuest as fallback so it have user to view other part of the app
      if (Object.keys(response.user).length === 0) {
        response = await loginAsGuest();
        await AsyncStorage.setItem("accessToken", response.accessToken);
      }

      setRegistered(response.user.email ? true : false);
    }

    setAccessToken(response.accessToken);
    setUser(response.user);

    // We will set userId in AsyncStorate just to be able to reuse where context is not accessible, such as logEvent
    await AsyncStorage.setItem("userId", response.user._id);
  };

  useEffect(() => {
    if (!signedIn) {
      authenticateUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setSignedIn(true);
      if (user.email) {
        setRegistered(true);
      }
    } else {
      setSignedIn(false);
      setRegistered(false);
    }
  }, [user]);

  return (
    <ProfileContext.Provider
      value={{
        user,
        setUser,
        registered,
        signedIn,
        accessToken,
        setScreenLoading,
        screenLoading,
        setDialog,
        setShowUsernameUpdateDialog,
        showSnackBar,
        signOut,
      }}
    >
      <PaperProvider>
        <View style={{ flex: 1, backgroundColor: "#6A5AE0" }}>{children}</View>
        <SnackBar
          type={snackBar.type || "SUCCESS"}
          visible={snackBar.visible}
          message={snackBar.message || ""}
          messageCode={snackBar.messageCode || "OTHER"}
          onHide={() => setSnackBar({ ...snackBar, visible: false })}
        />
        <DialogComponent props={dialog} />
        <UpdateUsernameDialog props={showUsernameUpdateDialog} />
      </PaperProvider>
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };

import { useContext } from "react";
import { ProfileContext } from "./UserContext";

const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) throw new Error("ProfileContext must be placed within ProfileProvider");

  return context;
};

export default useProfile;

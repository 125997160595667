import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";

import { useMutation } from "react-query";
import Container from "../components/utils/Container";
import { PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function QuizCreateScreen({ navigation }: Props) {
  const [title, setTitle] = useState<string>("");
  const { user, showSnackBar } = useProfile();

  const createQuiz = useMutation({
    mutationFn: async (param: { title: string; createdBy: string }) => {
      return await PostRequest(`/createQuiz`, param);
    },
    onSuccess: (response, variables) => {
      navigation.navigate("QuestionAdd", { quizId: response.data._id });
      // Navigate here to add question page
    },
  });

  const onAddQuestionPress = async () => {
    if (title.trim().length === 0) {
      showSnackBar({ type: "WARNING", message: "Please enter quiz title" });
      return;
    }

    if (title.trim().length > 100) {
      showSnackBar({
        type: "WARNING",
        message: "Quiz title too long. It should be lesser than 100 characters.",
      });
      return;
    }

    if (!user) {
      showSnackBar({ type: "ERROR", message: "User not set" });
      return;
    }

    createQuiz.mutate({ title, createdBy: user._id });
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          padding: 20,
          margin: 20,
          marginTop: shouldShowSideBar() ? 0 : 20,
          borderRadius: 20,
        }}
      >
        <View testID="QuizCreateScreen" style={{ flex: 1, gap: 20 }}>
          <View>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>Title</Text>
            <TextInput
              autoCapitalize="none"
              inputMode="text"
              testID="quizTitleInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              textColor="#858494"
              label="Quiz Title"
              value={title}
              onChangeText={(text) => setTitle(text)}
            />
          </View>
          <View>
            <Text style={{ color: "#CCC", textAlign: "center" }}>
              We will add question and options on next step
            </Text>
          </View>
        </View>
        <View style={{ display: "flex", gap: 10 }}>
          {createQuiz.isLoading && (
            <Button mode="outlined" buttonColor="#fff" textColor="#6A5AE0">
              Loading...
            </Button>
          )}
          {!createQuiz.isLoading && (
            <Button
              mode="contained"
              buttonColor="#6A5AE0"
              textColor="#FFF"
              testID="CreateQuizButton"
              onPress={() => onAddQuestionPress()}
            >
              Create Quiz
            </Button>
          )}
        </View>
      </View>
    </Container>
  );
}

export default QuizCreateScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "400",
    color: "#0C092A",
  },

  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
  button: {
    borderRadius: 15,
    marginTop: 15,
  },
});

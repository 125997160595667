import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, Text, View } from "react-native";
import { Period } from "../../types/Stat";
import { UserStat } from "../../types/User";

interface Props {
  testID: string;
  period: Period;
  label: string;
  stat?: UserStat;
  refetch: (period: any) => Promise<void>;
}

function ProfileStat(props: Props) {
  return (
    <>
      <View style={{ marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Text style={{ fontWeight: "bold" }}>{props.label}</Text>
        <View style={{ marginLeft: 10, marginTop: 2 }}>
          <MaterialCommunityIcons
            testID={props.testID}
            onPress={() => props.refetch(props.period)}
            name="reload"
            size={15}
            color="rgba(0,0,0, 0.2)"
          />
        </View>
      </View>
      {props.stat && <Stat stat={props.stat} />}
    </>
  );
}

const Stat = ({ stat }: { stat: UserStat }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "#E8E5FA",
        padding: 10,
        borderRadius: 20,
        justifyContent: "space-around",
        marginTop: 10,
        gap: 5,
      }}
    >
      <View style={styles.card}>
        <Text style={styles.cardValue}>{stat?.votes || 0}</Text>
        <Text style={styles.cardLabel}>Played</Text>
      </View>
      <View style={styles.card}>
        <Text style={styles.cardValue}>{stat?.correct || 0}</Text>
        <Text style={styles.cardLabel}>Correct</Text>
      </View>
      <View style={styles.card}>
        <Text style={styles.cardValue}>{stat?.wrong || 0}</Text>
        <Text style={styles.cardLabel}>Wrong</Text>
      </View>
      <View style={styles.card}>
        <Text style={styles.cardValue}>
          {Math.round((stat?.correct / stat?.votes) * 100) || 0}%
        </Text>
        <Text style={styles.cardLabel}>Ratio</Text>
      </View>
    </View>
  );
};

export default ProfileStat;

const styles = StyleSheet.create({
  item: {
    alignItems: "center",
  },
  label: {
    opacity: 0.5,
    fontSize: 10,
    marginTop: 5,
  },
  value: {
    fontWeight: "600",
  },
  itemText: {
    color: "#fff",
    marginTop: 3,
  },
  card: {
    backgroundColor: "#fff",
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 15,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

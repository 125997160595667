import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Text, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { Feedback } from "../../types/Feedback";
import { RootNativeStackParamList } from "../../types/Screens";
import { timeAgo } from "../utils/TimeAgo";

interface Props {
  feedbacks: Feedback[];
}
function FeedbackList(props: Props) {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  return (
    <>
      {props.feedbacks &&
        props.feedbacks.map((feedback, index) => (
          <TouchableRipple
            key={feedback._id}
            rippleColor="rgba(0, 0, 0, .32)"
            style={{
              borderRadius: 20,
            }}
            onPress={() => navigation.navigate("Feedback", { _id: feedback._id })}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                // gap: 10,
                padding: 10,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <View style={{ flex: 1, flexWrap: "wrap" }}>
                <Text
                  style={{
                    fontWeight: "500",
                    paddingLeft: 10,
                    maxWidth: "100%",
                  }}
                >
                  {feedback.message?.slice(0, 20)}
                </Text>
              </View>
              <View style={{ marginRight: 20, display: "flex", flexDirection: "row" }}>
                {feedback.status?.readByUser === false && (
                  <Text
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      backgroundColor: "#6A5AE0",
                      color: "#FFF",
                      borderRadius: 10,
                      marginRight: 10,
                      fontSize: 12,
                    }}
                  >
                    New
                  </Text>
                )}
                <Text style={{ fontSize: 10, color: "#CCC" }}>
                  {timeAgo(feedback.updatedAt || "")}
                </Text>
              </View>
              <View>
                <MaterialIcons name="arrow-forward-ios" size={15} color="#6A5AE0" />
              </View>
            </View>
          </TouchableRipple>
        ))}
    </>
  );
}

export default FeedbackList;

import * as ExpoLinking from "expo-linking";
import { Linking, Text, TouchableOpacity, View } from "react-native";
import { logEvent } from "../../scripts/analytic";
import { getOsNameOnWeb } from "../utils/DetermineOSOnWeb";

interface Params {
  screen: string;
  params: Record<string, string>;
}
function OpenInApp(params: Params) {
  const onOpenInAppClick = () => {
    logEvent("click_open_in_app", {
      screen,
      component: "OpenInApp",
    });

    ExpoLinking.openURL(
      `fune://?screen=${params.screen}&${new URLSearchParams(params.params).toString()}`
    );
  };

  const onDownloadClick = () => {
    logEvent("click_download", {
      screen,
      component: "OpenInApp",
    });

    const platform = getOsNameOnWeb();
    const appUrl =
      platform === "ios"
        ? "https://apps.apple.com/us/app/fune/id6470586383?platform=iphone"
        : "https://play.google.com/store/apps/details?id=app.fune.quiz";

    Linking.openURL(appUrl);
  };

  return (
    <View
      style={{
        backgroundColor: "#FFF",
        padding: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        zIndex: 9,
        justifyContent: "space-around",
      }}
    >
      <Text style={{ textAlign: "center", fontSize: 20 }}>App Installed?</Text>
      <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <TouchableOpacity
          style={{ padding: 10, borderWidth: 1, borderColor: "#6A5AE0", borderRadius: 10 }}
          onPress={() => onOpenInAppClick()}
        >
          <Text>Open In App</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            padding: 10,
            borderWidth: 1,
            borderColor: "#6A5AE0",
            borderRadius: 10,
            backgroundColor: "#6A5AE0",
          }}
          onPress={() => onDownloadClick()}
        >
          <Text style={{ color: "#FFF" }}>Download</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default OpenInApp;

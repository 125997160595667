import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import HorizontalLine from "../components/commons/HorizontalLine";
import Container from "../components/utils/Container";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuestionAdd">;
function QuestionAddScreen({ navigation, route }: Props) {
  const { quizId } = route.params;
  return (
    <Container>
      <View
        testID="QuestionAddScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: shouldShowSideBar() ? 0 : 20,
        }}
      >
        <View style={{ backgroundColor: "#FFF", borderRadius: 20, padding: 20 }}>
          <Text style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
            Add questions to your quiz.
          </Text>
          <Text style={{ marginTop: 20 }}>You can do this in 3 different ways.</Text>
          <View style={{ display: "flex", gap: 20, marginTop: 20 }}>
            <TouchableOpacity
              style={{ backgroundColor: "#6A5AE0", padding: 20, borderRadius: 20 }}
              onPress={() => navigation.navigate("QuestionCreateManual", { quizId })}
            >
              <Text style={{ color: "#FFF" }}>1. Create question manually one by one</Text>
            </TouchableOpacity>
            <HorizontalLine />
            <TouchableOpacity
              style={{ backgroundColor: "#6A5AE0", padding: 20, borderRadius: 20 }}
              onPress={() => navigation.navigate("QuestionCreateFromTopic", { quizId })}
            >
              <Text style={{ color: "#FFF" }}>
                2. Provide a topic and we will auto generate 10 questions.
              </Text>
            </TouchableOpacity>
            <HorizontalLine />
            <TouchableOpacity
              style={{ backgroundColor: "#6A5AE0", padding: 20, borderRadius: 20 }}
              onPress={() => navigation.navigate("QuestionCreateFromContext", { quizId })}
            >
              <Text style={{ color: "#FFF" }}>
                3. Provide long text as context from which we will auto generate 10 questions.
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Container>
  );
}

export default QuestionAddScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "600",
    color: "#0C092A",
  },
  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Linking, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button } from "react-native-paper";
import { useQuery } from "react-query";
import FeedbackList from "../components/feedback/FeedbackList";
import Container from "../components/utils/Container";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { Feedback } from "../types/Feedback";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function FeedbacksScreen({ navigation }: Props) {
  const { user } = useProfile();
  const getFeedbacks = async () => {
    const response = await GetRequest("/getFeedbacks", { userId: user?._id });
    return response.data;
  };

  const {
    isLoading,
    data: feedbacks,
    refetch,
  }: { isLoading: boolean; data?: Feedback[]; refetch: any } = useQuery(
    `feedbacks${user?._id}`,
    getFeedbacks,
    {
      enabled: typeof user !== "undefined",
    }
  );

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 20,
            gap: 10,
          }}
        >
          <Button
            testID="SendBtn"
            style={styles.button}
            mode="contained"
            buttonColor="#6A5AE0"
            labelStyle={{ color: "#fff" }}
            onPress={() => navigation.navigate("ContactUs")}
          >
            Send New Feedback
          </Button>
          <View>
            <TouchableOpacity onPress={() => Linking.openURL("mailto:admin@fune.app")}>
              <Text style={{ color: "#CCC" }}>You can also send email to admin@fune.app</Text>
            </TouchableOpacity>
          </View>
          <View style={{ marginTop: 20 }}>
            <Text style={{ fontWeight: "bold" }}>Older Feedbacks</Text>
            <FeedbackList feedbacks={feedbacks || []} />
          </View>
        </View>
      </View>
    </Container>
  );
}

export default FeedbacksScreen;

const styles = StyleSheet.create({
  button: {
    borderRadius: 15,
  },
});

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import { Button, IconButton, Modal, Portal } from "react-native-paper";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { QuestionRecord } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";
import MyBannerAd from "../commons/BannerAds";
import ReportQuestion from "../question/ReportQuestion";
import Skeleton from "../utils/Skeleton";

const screenHeight = Dimensions.get("window").height - 10;

const LearnQuestionCard = ({
  isOnscreenQuestion,
  question,
  index,
  onNextClick,
}: {
  isOnscreenQuestion: boolean;
  question: QuestionRecord;
  index: number;
  onNextClick: () => void;
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [translations, setTranslations] = useState<string[]>([]);

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const { user, setDialog } = useProfile();

  const translateQuestion = async (questionId: string) => {
    const response = await GetRequest("/translateQuestion", {
      questionId,
      target: user?.language,
    });

    setTranslations(response.data);
  };

  useEffect(() => {
    if (user?.autoTranslate && user?.language) {
      if (question) {
        if (question._id && (!question.translations || !question.translations[user?.language])) {
          translateQuestion(question._id);
        } else if (question.translations && question.translations[user?.language]) {
          setTranslations(question.translations[user?.language]);
        }
      }
    }
  }, [user, question]);

  const onTranslatePress = async () => {
    if (!user?.language) {
      setDialog({
        visible: true,
        title: "Please choose language",
        content: "It seems you haven't choose your preferred language. Choose now?",
        dismissLabel: "No",
        confirmLabel: "Yes",
        onDismiss: async () => {
          setDialog({ visible: false }); // If no then we will insert new quizuser and get that quizuserid
        },
        onConfirm: () => {
          setDialog({ visible: false });

          navigation.navigate("OtherSettings");
        },
      });
      return;
    }

    translateQuestion(question._id);
  };

  return (
    <View key={index} style={[styles.card, { top: screenHeight * (index - 1) }]}>
      <View style={styles.cardContent}>
        <IconButton
          icon={"translate"}
          iconColor="#CCC"
          size={25}
          style={{
            margin: 0,
            position: "absolute",
            right: 10,
          }}
          onPress={() => onTranslatePress()}
          accessibilityLabel={"Translate"}
        />
        <View style={{ maxWidth: "100%" }}>
          <Text
            style={{
              color: "#0C092A",
              marginTop: 20,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            {question ? question.title.trim() : ""}
          </Text>
          {translations && translations.length > 0 && (
            <Text style={{ color: "#BBB", marginTop: 20 }}>{translations[0]}</Text>
          )}
          {isOnscreenQuestion ? (
            <View style={{ marginTop: 30 }}>
              <MyBannerAd />
            </View>
          ) : (
            <Skeleton style={{ marginTop: 40, height: 50, width: "100%" }}></Skeleton>
          )}
        </View>

        <View style={{ alignItems: "center" }}>
          <Text style={{ color: "#CCC" }}>Answer</Text>
          <Text
            style={{
              fontSize: 25,
              textAlign: "center",
              padding: 20,
              borderWidth: 3,
              borderColor: "#EEE",
              marginTop: 10,
              borderRadius: 20,
            }}
          >
            {question ? question.answerText : ""}
          </Text>
          <Text style={{ fontSize: 20, color: "#BBB", textAlign: "center" }}>
            {translations && question && translations[question.answer + 1]}
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", gap: 20, justifyContent: "center" }}>
          <Button
            testID="ReportButton"
            onPress={() => setModalVisible(true)}
            mode="outlined"
            textColor="#6A5AE0"
            style={{
              borderRadius: 15,
            }}
          >
            Report
          </Button>
          <Button
            testID="NextButton"
            onPress={() => onNextClick()}
            mode="contained"
            buttonColor="#6A5AE0"
            textColor="#FFF"
            style={{
              borderRadius: 15,
            }}
          >
            Next
          </Button>
        </View>
      </View>
      <Portal>
        <Modal
          visible={modalVisible}
          onDismiss={() => setModalVisible(false)}
          contentContainerStyle={{
            backgroundColor: "white",
            padding: 20,
            margin: 20,
            alignSelf: "center",
          }}
        >
          <ReportQuestion
            question={question}
            userId={user?._id || ""}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      </Portal>
    </View>
  );
};

export default LearnQuestionCard;

const styles = StyleSheet.create({
  cardsContainer: {},
  card: {
    height: screenHeight - (Platform.OS === "web" ? 100 : 150),
    width: "100%",
    position: "absolute",
    paddingBottom: 60,
  },
  cardContent: {
    flex: 1,
    backgroundColor: "#FFF",
    borderRadius: 20,
    padding: 20,
    justifyContent: "space-between",
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import TagList from "../components/tags/TagList";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import { RootNativeStackParamList } from "../types/Screens";
import { TagCount } from "../types/Tag";

type Props = NativeStackScreenProps<RootNativeStackParamList, "TagList">;
function TagListScreen({ route }: Props) {
  const { tag }: { tag?: string } = route.params;

  const getTags = async () => {
    const response = await GetRequest("/getTags", tag ? { tag } : {}); // If tag is undefined then don't filter tag otherwise it get filter by 'undefined' as tag
    return response.data;
  };

  const { isLoading: tagsLoading, data: tags }: { isLoading: boolean; data?: TagCount[] } =
    useQuery(`tags${tag}`, getTags, { staleTime: 60000 });

  if (tagsLoading) {
    return <Skeleton />;
  }

  if (!tags || tags.length === 0) {
    return (
      <Container>
        <View
          testID="TagListScreen"
          style={{
            backgroundColor: "#FFF",
            flex: 1,
            gap: 10,
            padding: 20,
            margin: 20,
            borderRadius: 20,
            marginTop: isWebBigScreen() ? 0 : 20,
          }}
        >
          <Text style={{ fontSize: 16, textAlign: "center" }}>
            {`Oops, we could not find topic for ${tag}`}
          </Text>
        </View>
      </Container>
    );
  }

  return (
    <Container>
      <View
        testID="TagListScreen"
        style={{
          backgroundColor: "#FFF",
          flex: 1,
          gap: 10,
          padding: 20,
          margin: 20,
          borderRadius: 20,
          marginTop: isWebBigScreen() ? 0 : 20,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: "bold" }}>Select Topic</Text>
        <ScrollView
          style={{
            paddingTop: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            flex: 1,
          }}
        >
          <TagList
            tags={tags
              ?.filter((tag) => !tag._id.includes("grade") && tag.count >= 10)
              .sort((a, b) => (a._id < b._id ? -1 : 1))}
            navigateTo="Tag"
            params={{ tags: tag ? [tag] : [] }} //tag could be undefined so check
          />
        </ScrollView>
      </View>
    </Container>
  );
}

export default TagListScreen;

const styles = StyleSheet.create({});

import { AntDesign, Entypo } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { shuffle } from "lodash";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { useMutation, useQuery } from "react-query";
import Container from "../components/utils/Container";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { shouldShowSideBar } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { QuestionRecord } from "../types/Question";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuestionEdit">;
function QuestionEditScreen({ navigation, route }: Props) {
  const [title, setTitle] = useState<string>("");
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  const [wrongOptions, setWrongOptions] = useState<string[]>(["", "", "", ""]);
  const { _id } = route.params;
  const { user, showSnackBar } = useProfile();

  const getQuestion = async () => {
    const response = await GetRequest("/getQuestion", { questionId: _id });
    return response.data;
  };

  const { isLoading, data: question }: { isLoading: boolean; data?: QuestionRecord } = useQuery(
    `question${_id}`,
    getQuestion,
    {
      staleTime: 60,
      enabled: typeof _id !== "undefined",
    }
  );

  useEffect(() => {
    if (question) {
      setTitle(question.title);
      setCorrectAnswer(question.answerText);

      // Remove answer from options then add answer to first element. Wrong options will show element from 1 - 3
      let opts: string[] = [];
      if (question.options) {
        opts = question.options.filter((option) => option !== question.answerText);
        opts.unshift(question.answerText);
      }

      setWrongOptions(opts);
    }
  }, [question]);

  const updateQuestion = useMutation({
    mutationFn: async (param: {
      _id: string;
      title: string;
      answer: number;
      options: string[];
    }) => {
      return await PostRequest(`/updateQuestion`, param);
    },
    onSuccess: (response, variables) => {
      // navigation.navigate("QuizEdit", { _id: response.data._id });
      showSnackBar({ type: "SUCCESS", message: "Question updated successfully" });
    },
  });

  const onWrongOptionsChange = (option: string, index: number) => {
    const tempOptions = [...wrongOptions];
    tempOptions[index] = option;

    setWrongOptions(tempOptions);
  };

  const onUpdateQuestionPress = () => {
    if (title.length < 10) {
      showSnackBar({ type: "WARNING", message: "Question title too short" });
      return;
    }

    if (correctAnswer.length === 0) {
      showSnackBar({ type: "WARNING", message: "Please provide correct answer" });
      return;
    }

    if (
      wrongOptions[1]?.length === 0 ||
      wrongOptions[2]?.length === 0 ||
      wrongOptions[3]?.length === 0
    ) {
      showSnackBar({ type: "WARNING", message: "Please provide all wrong options" });
      return;
    }

    const options = shuffle([correctAnswer, wrongOptions[1], wrongOptions[2], wrongOptions[3]]);
    const answer = options.findIndex((option) => option === correctAnswer);

    updateQuestion.mutate({ _id, title, answer, options });
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          backgroundColor: "#FFF",
          borderRadius: 20,
          padding: 20,
          paddingTop: shouldShowSideBar() ? 0 : 20,
        }}
      >
        <View style={{ display: "flex", flex: 1, gap: 20 }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "space-around",
            }}
          >
            <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <Text>
                {title && title.length > 10 ? (
                  <AntDesign name="checkcircle" size={20} color="#53DF83" />
                ) : (
                  <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                )}
              </Text>
              <Text style={styles.label}>Title</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <Text>
                {correctAnswer ? (
                  <AntDesign name="checkcircle" size={20} color="#53DF83" />
                ) : (
                  <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                )}
              </Text>
              <Text style={styles.label}>Answer</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <Text>
                {wrongOptions[1] &&
                wrongOptions[2] &&
                wrongOptions[3] &&
                wrongOptions[1]?.length !== 0 &&
                wrongOptions[2]?.length !== 0 &&
                wrongOptions[3]?.length !== 0 ? (
                  <AntDesign name="checkcircle" size={20} color="#53DF83" />
                ) : (
                  <Entypo name="circle-with-cross" size={23} color="#FF6666" />
                )}
              </Text>
              <Text style={styles.label}>Options</Text>
            </View>
          </View>

          <View>
            <Text style={styles.label}>Title</Text>
            <TextInput
              autoCapitalize="none"
              inputMode="text"
              testID="questionTitleInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Question Title"
              value={title}
              onChangeText={(text) => setTitle(text)}
            />
          </View>

          <View>
            <Text style={styles.label}>Corrrect Answer</Text>
            <TextInput
              autoCapitalize="none"
              inputMode="text"
              testID="questionTitleInput"
              outlineColor={"#6A5AE0"}
              mode="outlined"
              theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
              style={styles.inputText}
              label="Correct Answer"
              value={correctAnswer}
              onChangeText={(text) => setCorrectAnswer(text)}
            />
          </View>

          <View>
            <Text style={styles.label}>Wrong Options</Text>
            <View style={{ display: "flex", gap: 10 }}>
              {[1, 2, 3].map((key) => (
                <TextInput
                  key={key}
                  autoCapitalize="none"
                  inputMode="text"
                  testID="questionTitleInput"
                  outlineColor={"#6A5AE0"}
                  mode="outlined"
                  theme={{ roundness: 15, colors: { onSurfaceVariant: "#858494" } }}
                  style={styles.inputText}
                  label={`Option ${key}`}
                  value={wrongOptions[key]}
                  onChangeText={(text) => onWrongOptionsChange(text, key)}
                />
              ))}
            </View>
          </View>
        </View>
        <Button
          mode="contained"
          buttonColor="#6A5AE0"
          textColor="#FFF"
          onPress={() => onUpdateQuestionPress()}
        >
          Update
        </Button>
      </View>
    </Container>
  );
}

export default QuestionEditScreen;

const styles = StyleSheet.create({
  label: {
    fontWeight: "600",
    color: "#0C092A",
  },
  inputText: {
    marginTop: 5,
    underlineColorAndroid: "transparent",
    borderWidth: 0,
    backgroundColor: "#FFF",
  },
});

import { FontAwesome } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { Avatar } from "react-native-paper";
import { useQuery } from "react-query";
import ProfileFuneQ from "../components/profile/ProfileFuneQ";
import ProfileHeaderSVG from "../components/profile/ProfileHeader";
import ProfileStat from "../components/profile/ProfileStat";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import useProfile from "../scripts/userProfile";
import { RootNativeStackParamList } from "../types/Screens";
import { UserRecord } from "../types/User";

type Props = NativeStackScreenProps<RootNativeStackParamList, "Profile">;
const ProfileScreen = ({ route }: Props) => {
  const { userId }: { userId: string } = route.params;
  const { showSnackBar } = useProfile();

  const getUser = async () => {
    const response = await GetRequest("/getUser", {
      _id: userId,
    });
    return response.data;
  };

  const {
    isLoading,
    data: user,
    refetch,
  }: { isLoading: boolean; data?: UserRecord; refetch: any } = useQuery(`user${userId}`, getUser); // Should not have stale time

  const refetchCalculateUserStat = async (period: string) => {
    showSnackBar({ type: "SUCCESS", message: "Updating.." });

    try {
      await PostRequest("/calculateUserStats", {
        userId,
        period,
      });
      showSnackBar({ type: "SUCCESS", message: "Updated Successfully!" });
    } catch (e: any) {
      showSnackBar({ type: "ERROR", message: "Oops, something went wrong" });
    }

    refetch();
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (!user || Object.keys(user).length === 0) {
    return (
      <Container>
        <View style={{ marginTop: 60 }}>
          <Text>Users Not Found</Text>
        </View>
        <View style={{ marginTop: 60 }}>
          <Text>This doesn't suppose to happen. Please report to admin from contact page.</Text>
        </View>
        <View style={{ marginTop: 60 }}>
          <Text style={{ fontSize: 10 }}>UserId: {userId}</Text>
        </View>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollView
        testID="ProfileScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
        }}
      >
        <ProfileHeaderSVG />
        <View style={styles.container}>
          <View>
            <View style={{ alignItems: "center", marginTop: -60 }}>
              <Avatar.Text
                size={100}
                label={user.username?.slice(0, 2).toLocaleUpperCase() || "x"}
                style={{ backgroundColor: "#BF83FF" }}
              />
            </View>
            <View>
              <Text style={{ fontSize: 16, fontWeight: "600", textAlign: "center", marginTop: 10 }}>
                Points
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "#6A5AE0",
                padding: 16,
                borderRadius: 20,
                justifyContent: "space-around",
                marginTop: 20,
              }}
            >
              <View style={styles.item}>
                <FontAwesome name="star-o" size={18} color="#fff" />
                <Text style={[styles.itemText, styles.label]}>Last 7 day</Text>
                <Text style={[styles.itemText, styles.value]}>
                  {Math.round(user.last7day?.correctPoints || 0)} Pts.
                </Text>
              </View>
              <View style={styles.item}>
                <FontAwesome name="star-o" size={18} color="#fff" />
                <Text style={[styles.itemText, styles.label]}>Last 30 Day</Text>
                <Text style={[styles.itemText, styles.value]}>
                  {Math.round(user.last30day?.correctPoints || 0)} Pts.
                </Text>
              </View>
              <View style={styles.item}>
                <FontAwesome name="star-o" size={18} color="#fff" />
                <Text style={[styles.itemText, styles.label]}>Lifetime</Text>
                <Text style={[styles.itemText, styles.value]}>
                  {Math.round(user.lifetime?.correctPoints || 0)} Pts.
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 30, marginBottom: 10 }}>
              <Text style={{ fontWeight: "bold" }}>User Expertise</Text>
              <ProfileFuneQ funeQ={user.funeQ || {}} />
            </View>

            <ProfileStat
              label="Last 7 day"
              period="last7day"
              refetch={refetchCalculateUserStat}
              testID="calculateUserStatLast7Day"
              stat={user.last7day}
            />
            <ProfileStat
              label="Last 30 day"
              period="last30day"
              refetch={refetchCalculateUserStat}
              testID="calculateUserStatLast30Day"
              stat={user.last30day}
            />

            <ProfileStat
              label="Lifetime"
              period="lifetime"
              refetch={refetchCalculateUserStat}
              testID="calculateUserStatLifetime"
              stat={user.lifetime}
            />
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

export default ProfileScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    // borderTopLeftRadius: 20,
    // borderTopRightRadius: 20,
    borderRadius: 20,
    padding: 10,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 150,
    marginBottom: Platform.OS === "web" ? 0 : 100, // So that it can scroll to bottom.
  },
  item: {
    alignItems: "center",
  },
  label: {
    opacity: 0.5,
    fontSize: 10,
    marginTop: 5,
  },
  value: {
    fontWeight: "600",
  },
  itemText: {
    color: "#fff",
    marginTop: 3,
  },
  card: {
    backgroundColor: "#fff",
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 15,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

import { AntDesign } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { startCase } from "lodash";
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, IconButton } from "react-native-paper";
import Svg, { Path } from "react-native-svg";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import NotFound from "../components/utils/NotFound";
import Skeleton from "../components/utils/Skeleton";
import { MONTH_NAMES } from "../components/utils/TimeAgo";
import { GetRequest } from "../scripts/ApiRequest";
import useProfile from "../scripts/userProfile";
import { QuestionRecord } from "../types/Question";
import { RootNativeStackParamList } from "../types/Screens";
import { AggregateVotes } from "../types/Vote";

const screenWidth = Dimensions.get("window").width;

const SVGComponent = () => {
  return (
    <Svg width={screenWidth} height="270" viewBox={`0 0 ${screenWidth} 270`} fill="none">
      <Path
        d="M46.4346 258.921L93.227 212.155C97.4361 207.944 100.198 202.506 101.115 196.623L105.118 170.957L122.628 196.488L95.1542 249.158L125.008 258.001L153.244 212.514C155.825 208.354 157.122 203.526 156.973 198.633C156.824 193.741 155.236 189 152.407 185.006L128.706 151.581L86.3715 125.709L77.2507 140.013C73.8048 145.42 71.7892 151.613 71.3939 158.013L69.4251 189.861L16.4456 244.383L46.4346 258.921Z"
        fill="#09074A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.965 95.1419C161.98 102.164 171.852 134.384 171.852 134.384L201.202 147.729L193.873 167.364L154.766 150.743L151.841 145.647L145.107 164.509L115.458 153.448C113.149 152.586 111.12 151.11 109.589 149.179C108.058 147.248 107.084 144.935 106.772 142.491L106.171 137.802L86.2987 125.656C86.2987 125.656 97.0137 110.941 101.076 106.445C109.159 97.4981 126.171 84.2188 147.965 95.1419ZM138.994 138.884L150.424 143.178L144.267 132.45L138.994 138.884Z"
        fill="#9087E5"
      />
      <Path d="M103.203 134.458L88.2492 125.876" stroke="#6A5AE0" strokeLinecap="square" />
      <Path d="M435 178.558L340.39 266.882H304.126L398.738 178.558H435Z" fill="#7F75E1" />
      <Path d="M399.248 177.388L300.204 71.2964H333.267L432.306 177.388H399.248Z" fill="#7F75E1" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.51 70.126H333.774L435 178.558H398.738L297.51 70.126ZM429.611 176.218L332.757 72.4673H302.898L399.755 176.218H429.611Z"
        fill="#9087E5"
      />
      <Path
        d="M199.051 150.684C199.051 150.684 207.059 154.042 210.572 158.003C214.086 161.963 216.294 170.486 216.294 170.486C216.294 170.486 194.968 173.178 192.916 153.841"
        fill="#FFCCD7"
      />
      <Path
        d="M23.6129 245.491L10.0164 259.309L22.6533 266.882H61.6096L40.8688 255.845L23.6129 245.491Z"
        fill="#162C56"
      />
      <Path
        d="M100.28 249.538L91.5809 266.882H141.375L120.634 255.844L100.28 249.538Z"
        fill="#162C56"
      />
      <Path
        d="M167.462 81.0986L146.407 98.716"
        stroke="#FFCCD7"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M153.187 102.838L154.459 103.556C155.939 104.39 157.584 104.887 159.277 105.013C160.971 105.139 162.672 104.89 164.259 104.284C165.845 103.677 167.279 102.729 168.457 101.506C169.636 100.283 170.53 98.8159 171.077 97.2078L173.785 89.2444C174.66 86.672 174.601 83.8733 173.618 81.3402C172.634 78.8071 170.79 76.7016 168.409 75.3929C165.722 73.9175 162.559 73.5692 159.616 74.4251C156.673 75.2813 154.19 77.2711 152.714 79.9572L148.732 87.2054C147.267 89.873 146.913 93.0105 147.747 95.9374C148.581 98.8642 150.536 101.344 153.187 102.838Z"
        fill="#FFCCD7"
      />
      <Path
        d="M150.277 92.049L150.673 91.9889C152.885 91.6536 154.405 89.5894 154.07 87.3783L153.649 84.5989C153.313 82.3877 151.249 80.8668 149.038 81.2021L148.642 81.2621C146.431 81.5974 144.91 83.6616 145.245 85.8727L145.667 88.6524C146.002 90.8635 148.066 92.3842 150.277 92.049Z"
        fill="#FFCCD7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.991 84.459L149.723 89.1039L147.991 84.459Z"
        fill="#0C092A"
      />
      <Path d="M147.991 84.459L149.723 89.1039" stroke="#0C092A" strokeLinecap="square" />
      <Path
        d="M167.621 89.9673L165.751 96.4483L163.442 95.7955"
        stroke="#0C092A"
        strokeLinecap="square"
      />
      <Path
        d="M152.417 89.7329L160.157 79.7929L161.993 77.4315C162.358 78.4268 162.885 79.355 163.554 80.1778C165.964 83.1843 175.665 86.508 179.769 80.4587C183.116 75.5173 176.087 76.1961 173.301 74.6175C168.927 72.1364 166.877 68.074 159.14 68.8074C149.869 69.6735 149.195 81.1791 149.195 81.1791C149.195 81.1791 153.062 81.3014 152.417 89.7329Z"
        fill="#24272D"
      />
      <Path
        d="M151.952 101.096L149 97.8999L149.127 103.416L151.952 101.096Z"
        fill="black"
        fillOpacity="0.3"
      />
      <Path
        d="M163.943 90.6383C164.6 90.6383 165.132 90.1062 165.132 89.4498C165.132 88.7933 164.6 88.2612 163.943 88.2612C163.287 88.2612 162.755 88.7933 162.755 89.4498C162.755 90.1062 163.287 90.6383 163.943 90.6383Z"
        fill="#0C092A"
      />
      <Path
        d="M170.469 93.5172C171.125 93.5172 171.657 92.9851 171.657 92.3287C171.657 91.6722 171.125 91.1401 170.469 91.1401C169.812 91.1401 169.28 91.6722 169.28 92.3287C169.28 92.9851 169.812 93.5172 170.469 93.5172Z"
        fill="#0C092A"
      />
      <Path
        d="M398.738 178.558L304.126 266.882L202.9 158.452L297.51 70.126L398.738 178.558Z"
        fill="#9087E5"
      />
      <Path
        d="M163.655 144.411L159.608 150.169"
        stroke="#C4D0FB"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <Path
        d="M172.118 116.284L175.267 128.092L185.727 129.293L181.688 137.969L172.583 145.488L163.421 144.744L160.763 137.285L162.238 128.058L172.118 116.284Z"
        fill="#9087E5"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.073 135.31C188.561 126.866 189.004 117.418 184.062 114.206C179.121 110.995 170.666 115.236 165.179 123.68C159.691 132.124 159.248 141.572 164.19 144.784C169.131 147.995 177.586 143.753 183.073 135.31Z"
        stroke="#C4D0FB"
        strokeWidth="4"
      />
      <Path d="M194.081 266.882L184.952 253.192L175.821 266.882H194.081Z" fill="#C4D0FB" />
      <Path d="M299.127 174.551L302.651 159.914L287.785 162.285L299.127 174.551Z" fill="#C4D0FB" />
      <Path d="M276.891 266.882L252.943 230.974L228.993 266.882H276.891Z" fill="#C4D0FB" />
      <Path d="M230.918 220.131H211.371V266.882H230.918V220.131Z" fill="#9087E5" />
      <Path
        d="M312.994 171.089L303.798 179.592L324.136 201.586L333.332 193.082L312.994 171.089Z"
        fill="#C4D0FB"
      />
      <Path
        d="M205.724 266.882C205.724 259.77 208.55 252.948 213.579 247.918C218.609 242.889 225.43 240.063 232.543 240.063C239.656 240.063 246.477 242.889 251.507 247.918C256.538 252.948 259.362 259.77 259.362 266.882"
        fill="#FFD6DD"
      />
      <Path
        d="M283.013 157.126C284.22 156.009 285.195 154.666 285.884 153.172C286.573 151.679 286.961 150.064 287.026 148.421C287.088 146.778 286.828 145.138 286.259 143.595C285.689 142.052 284.823 140.636 283.705 139.429C282.589 138.221 281.247 137.246 279.752 136.558C278.259 135.869 276.644 135.482 275 135.417C273.359 135.353 271.718 135.613 270.176 136.182C268.634 136.752 267.216 137.619 266.009 138.736L283.013 157.126Z"
        fill="#C4D0FB"
      />
      <Path
        d="M158.737 95.0645C158.737 95.0645 158.126 97.4649 160.638 98.6483L158.737 95.0645Z"
        fill="#0C092A"
        stroke="#0C092A"
      />
      <Path
        d="M139.148 138.536L129.385 134.723L129.117 113.33"
        stroke="#6A5AE0"
        strokeLinecap="square"
      />
      <Path d="M105.521 134.377L104.527 119.387" stroke="#6A5AE0" strokeLinecap="square" />
      <Path
        d="M70.7669 195.664L68.512 189.581L74.0542 188.72"
        stroke="#6A5AE0"
        strokeLinecap="square"
      />
      <Path
        d="M126.665 200.906L121.497 196.277L128.657 192.696"
        stroke="#6A5AE0"
        strokeLinecap="square"
      />
      <Path d="M105.903 163.516L104.244 181.646" stroke="#6A5AE0" strokeLinecap="square" />
      <Path
        d="M150.989 148.616C150.989 148.616 154.24 146.918 159.265 147.316L164.664 149.347C164.664 149.347 169.457 153.191 162.885 159.989C156.313 166.787 145.465 162.676 145.465 162.676"
        fill="#FFCCD7"
      />
    </Svg>
  );
};

type Props = NativeStackScreenProps<RootNativeStackParamList, "Tag">;
const TagScreen = ({ route, navigation }: Props) => {
  const { tags }: { tags: string[] } = route.params;

  const { user, showSnackBar } = useProfile();

  const getTagQuestions = async () => {
    const response = await GetRequest("/getUserRecommendedTagQuestions", {
      userId: user?._id,
      tags,
    });
    return response.data;
  };

  const getUserTagVotes = async () => {
    const response = await GetRequest("/getVotesStats", {
      userId: user?._id,
      tags,
      groupBy: "date",
    });
    return response.data;
  };

  const {
    isLoading: tagLoading,
    data: tagQuestions,
  }: { isLoading: boolean; data?: QuestionRecord[] } = useQuery(
    `tagQuestions${tags.toString()}`,
    getTagQuestions,
    {
      enabled: typeof user !== "undefined",
    }
  ); // Should not have stale time

  const {
    isLoading: userTagVotesLoading,
    data: userTagVotes,
  }: { isLoading: boolean; data?: AggregateVotes[] } = useQuery(
    `userTagVotes${tags.toString()}`,
    getUserTagVotes,
    {
      enabled: typeof user !== "undefined",
    }
  ); // Should not have stale time

  const onStartTagPress = async (navigateTo: "4option" | "TrueFalse" | "Learn") => {
    if (!tagQuestions) {
      showSnackBar({ type: "ERROR", message: "Questions not loaded" });
      return;
    }

    if (navigateTo === "4option") {
      navigation.navigate("Question", {
        tags: tags,
        questionIds: tagQuestions.map((question) => question._id),
      });

      return;
    }

    if (navigateTo === "TrueFalse") {
      navigation.navigate("TrueFalse", {
        tags: tags,
      });

      return;
    }

    if (navigateTo === "Learn") {
      navigation.navigate("Learn", {
        tags: tags,
      });

      return;
    }
  };

  if (tagLoading) {
    return (
      <View
        style={{
          flex: 1,
          gap: 10,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 60 : 120,
        }}
      >
        <Skeleton width={"100%"} height={100} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={20} />
        <Skeleton width={"100%"} height={100} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={20} />
      </View>
    );
  }

  if (tagQuestions?.length === 0) {
    return (
      <NotFound
        message={`Oops, something went wrong. We could not find any question belong to this tags: ${tags.toString()}`}
      />
    );
  }

  return (
    <Container>
      <ScrollView
        testID="TagScreen"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
        }}
      >
        <View
          style={{
            position: "relative",
            marginTop: -60,
          }}
        >
          <View>
            <SVGComponent />
          </View>
        </View>

        <View style={styles.container}>
          <Text style={{ fontSize: 12, color: "#858494", fontWeight: "500" }}>CATEGORY</Text>
          <Text style={{ fontSize: 16, marginTop: 10, fontWeight: "600" }}>
            {startCase(tags.toString())}
          </Text>
          <View style={{ backgroundColor: "#EFEEFC", padding: 8, borderRadius: 10, marginTop: 15 }}>
            <View style={{ alignItems: "center" }}>
              <IconButton
                icon={() => (
                  <AntDesign name="question" size={20} color="#fff" style={{ marginRight: -1 }} />
                )}
                size={12}
                style={{
                  backgroundColor: "#6A5AE0",
                }}
              />
              <Text style={{ fontWeight: "500" }}>{tagQuestions?.length} Questions</Text>
            </View>
          </View>
          <View>
            <Text style={{ color: "#858494", marginTop: 20, fontWeight: "500" }}>DESCRIPTION</Text>
            <Text style={{ marginTop: 12, fontSize: 16, fontWeight: "400" }}>
              We found {tagQuestions?.length} questions for you. Let's have fun answering these
              questions.
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => onStartTagPress("4option")}
              style={{
                backgroundColor: "#6A5AE0",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={tagLoading}
            >
              <Text style={{ color: "#FFF" }}>4 Option</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => onStartTagPress("TrueFalse")}
              style={{
                backgroundColor: "#6A5AE0",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={tagLoading}
            >
              <Text style={{ color: "#FFF" }}>True False</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => onStartTagPress("Learn")}
              style={{
                backgroundColor: "#6A5AE0",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={tagLoading}
            >
              <Text style={{ color: "#FFF" }}>Learn</Text>
            </TouchableOpacity>
          </View>
          <Button
            onPress={() => navigation.navigate("TagLeaderboard", { tags: tags })}
            mode="outlined"
            textColor="#6A5AE0"
            style={{ marginTop: 5, borderRadius: 10, borderColor: "#6A5AE0" }}
            disabled={tagLoading}
          >
            Leaderboard
          </Button>
          <View>
            <Text style={{ color: "#858494", marginTop: 20, fontWeight: "500" }}>HISTORY</Text>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  // backgroundColor: "#E8E5FA",
                  padding: 2,
                  borderRadius: 20,
                  justifyContent: "space-around",
                  marginTop: 10,
                  gap: 2,
                }}
              >
                <View style={styles.card}>
                  <Text style={styles.cardValue}>Date</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardValue}>Vote</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardValue}>Correct</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardValue}>N/T Point</Text>
                </View>
                {/* <View style={styles.card}>
                <Text style={styles.cardValue}>C.Point</Text>
              </View> */}
              </View>
              {userTagVotes &&
                userTagVotes.map((userVote) => (
                  <View
                    key={userVote._id}
                    style={{
                      flexDirection: "row",
                      padding: 2,
                      justifyContent: "space-around",
                      gap: 2,
                    }}
                  >
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>
                        {new Date(userVote._id).getDate() +
                          "-" +
                          MONTH_NAMES[new Date(userVote._id).getMonth()]}
                      </Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userVote.totalVote}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>{userVote.totalCorrect}</Text>
                    </View>
                    <View style={styles.card}>
                      <Text style={styles.cardLabel}>
                        {Math.round(userVote.totalNewPoint)} /{" "}
                        <Text>{Math.round(userVote.totalPoint)}</Text>
                      </Text>
                    </View>
                    {/* <View style={styles.card}>  
                    <Text style={styles.cardLabel}>
                      {Math.round(
                        (userVote.totalCorrect / userVote.totalVote) * userVote.totalNewPoint // We should not calculate this here as totalVote and totalCorrect have records of recentPlay also but totalNewPoint is only non recentPlay 
                      )}
                    </Text>
                  </View> */}
                  </View>
                ))}
            </View>
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

export default TagScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    // borderTopLeftRadius: 20,
    // borderTopRightRadius: 20,
    borderRadius: 20,
    padding: 20,
    marginLeft: 5,
    marginRight: 5,
    marginTop: -5,
  },
  card: {
    backgroundColor: "#E8E5FA",
    padding: 5,
    flex: 1,
    justifyContent: "center",
  },
  cardValue: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "center",
  },
});

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useQuery } from "react-query";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { timeAgo } from "../components/utils/TimeAgo";
import { GetRequest } from "../scripts/ApiRequest";
import useProfile from "../scripts/userProfile";
import { NotificationParams, NotificationRecord } from "../types/Notification";
import { RootNativeStackParamList } from "../types/Screens";

const screenWidth = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList>;
function NotificationsScreen({ navigation }: Props) {
  const { user } = useProfile();

  const getUserNotifications = async () => {
    const response = await GetRequest("/getUserNotifications", { userId: user?._id });
    return response.data;
  };

  const readUserNotifications = async () => {
    const response = await GetRequest("/readUserNotifications", { userId: user?._id });
    return response.data;
  };

  const {
    isLoading,
    data,
    refetch,
  }: { isLoading: boolean; data?: NotificationRecord[]; refetch: any } = useQuery(
    `notifications`,
    getUserNotifications,
    {
      staleTime: 60000,
      enabled: typeof user !== "undefined",
    }
  );

  useQuery(`readNotifications`, readUserNotifications, {
    enabled: typeof user !== "undefined",
    onSuccess: () => {
      setTimeout(() => {
        refetch();
      }, 1000);
    },
  });

  const navigateTo = (notification: NotificationRecord) => {
    const params: NotificationParams = JSON.parse(notification.params);

    switch (notification.type) {
      case "GAIN_TAG_LEADERBOARD":
      case "LOOSE_TAG_LEADERBOARD":
        if (params.leaderboard === "tag" && params.tag) {
          navigation.navigate("TagLeaderboard", { tags: [params.tag] });
        }
        break;

      case "GAIN_USER_LEADERBOARD":
      case "LOOSE_USER_LEADERBOARD":
        if (params.leaderboard === "user") {
          navigation.navigate("UserLeaderboard");
        }

        break;

      case "GAIN_QUIZ_LEADERBOARD":
      case "LOOSE_QUIZ_LEADERBOARD":
        if (params.leaderboard === "quiz" && params.quizId) {
          navigation.navigate("QuizLeaderboard", { quizId: params.quizId });
        }
        break;

      case "FEEDBACK_REPLY":
        if (params.feedbackId) {
          navigation.navigate("Feedback", { _id: params.feedbackId });
        }

        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <View
        testID="skeletonContainer"
        style={{
          flex: 1,
          gap: 10,
          backgroundColor: "#6A5AE0",
          padding: 20,
          paddingTop: Platform.OS === "web" ? 0 : 20,
        }}
      >
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
      </View>
    );
  }

  return (
    <Container>
      <ScrollView
        testID="NotificationScreenContainer"
        style={{
          flex: 1,
          backgroundColor: "#6A5AE0",
        }}
      >
        <View testID="NotificationScreenList" style={styles.itemsContainer}>
          {data && data.length === 0 && (
            <View style={{ padding: 20 }}>
              <Text>No notification</Text>
            </View>
          )}
          {data &&
            data.map((notification: NotificationRecord, index: number) => (
              <TouchableOpacity
                style={[
                  styles.item,
                  !notification.seen && styles.notSeen,
                  index === data.length - 1 && styles.lastItem,
                  index === 0 && styles.firstItem,
                ]}
                key={notification._id}
                onPress={() => navigateTo(notification)}
              >
                <Text style={styles.itemText}>{notification.message}</Text>
                <Text style={styles.timeAgo}>{timeAgo(notification.updatedAt)}</Text>
              </TouchableOpacity>
            ))}
        </View>
      </ScrollView>
    </Container>
  );
}

export default NotificationsScreen;

const styles = StyleSheet.create({
  itemsContainer: {
    backgroundColor: "#fff",
    margin: 10,
    marginTop: Platform.OS === "web" ? 0 : 20,
    borderRadius: 20,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 0,
    overflow: "hidden",
  },
  item: {
    flexShrink: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#EEE",
    paddingBottom: 10,
    paddingTop: 10,
    width: "100%",
  },
  firstItem: {
    paddingTop: 20,
  },
  lastItem: {
    borderBottomWidth: 0,
    paddingBottom: 20,
  },
  notSeen: {
    backgroundColor: "#EFEEFC",
  },

  itemText: {
    color: "#6A5AE0",
    textAlign: "left",
    paddingLeft: 20,
    paddingRight: 20,
  },

  timeAgo: {
    fontSize: 10,
    color: "#CCC",
    paddingLeft: 20,
    paddingRight: 20,
  },
});

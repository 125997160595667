import { Dimensions, Platform, StyleSheet, View } from "react-native";
import Skeleton from "../utils/Skeleton";

const screenWidth = Dimensions.get("window").width - 10;
const screenHeight = Dimensions.get("window").height - 10;

function LearnLoading() {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#6A5AE0",
      }}
    >
      <View style={[styles.card, { top: 0 }]}>
        <View style={[styles.cardContent, { top: 0 }]}>
          <Skeleton width="100%" height="100%" />
        </View>
      </View>
    </View>
  );
}

export default LearnLoading;

const styles = StyleSheet.create({
  cardsContainer: {},
  card: {
    height: screenHeight - (Platform.OS === "web" ? 0 : 60),
    width: screenWidth,
    flex: 1,
    position: "absolute",
    padding: 10,
    paddingBottom: 60,
  },
  cardContent: {
    flex: 1,
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
  },
});

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { LevelMissions, Mission as MissionInterface } from "../../types/Mission";
import NotFound from "../utils/NotFound";
import Skeleton from "../utils/Skeleton";
import Mission from "./Mission";

function Missions() {
  const [missions, setMissions] = useState<MissionInterface[]>([]);
  const { user } = useProfile();

  const getUserMissions = async () => {
    const response = await GetRequest("/getUserLevelMissions", { userId: user?._id });
    return response.data;
  };

  const {
    isLoading,
    data,
    refetch,
    isError,
    isRefetching,
  }: {
    isLoading: boolean;
    data?: LevelMissions;
    refetch: any;
    isError: boolean;
    isRefetching: boolean;
  } = useQuery(`userMissions${user?._id}`, getUserMissions, {
    staleTime: 60000,
    refetchInterval: 60000,
    enabled: typeof user !== "undefined",
  });

  useEffect(() => {
    if (data) {
      const tempMissions = data.missions
        .map((mission) => {
          return {
            ...mission,
            progress: mission.params.done / mission.params.target,
          };
        })
        .sort((a, b) => b.progress - a.progress);

      setMissions(tempMissions);
    }
  }, [data]);

  if (!data || isLoading || isRefetching) {
    return <Skeleton width={"100%"} height={240} style={{ borderRadius: 20 }} />;
  }

  if (isError) {
    return (
      <NotFound
        message={`Oops something went wrong. Could not find any missions for user_id: ${user?._id}`}
      />
    );
  }

  return (
    <View
      testID="Missions"
      style={{
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 20,
      }}
    >
      <View style={styles.subHeader}>
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>
              Level {data.level} : Progress{" "}
              {missions.filter((mission) => mission.progress && mission.progress >= 1).length}/
              {missions.length}
            </Text>
          </View>
          <View>
            <MaterialCommunityIcons
              testID="calculateUserStatLast7Day"
              onPress={() => refetch()}
              name="reload"
              size={20}
              color="rgba(0,0,0, 0.2)"
            />
          </View>
        </View>
      </View>
      {missions &&
        missions
          .filter((mission) => !mission.progress || mission.progress < 1)
          .slice(0, 5)
          .map((mission) => <Mission key={mission.type} mission={mission} />)}
    </View>
  );
}

export default Missions;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    paddingTop: 5,
  },
  section: {
    padding: 0,
    margin: 0,
  },
  subHeader: {
    // color: "#000",
  },
  item: {
    border: "2px solid #EFEEFC",
    marginTop: 15,
    borderRadius: 10,
  },
  itemText: {},

  titleStyle: {},
});

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Dimensions, ScrollView, View } from "react-native";
import { Button } from "react-native-paper";
import { useQuery } from "react-query";
import PeriodSelectorButton from "../components/commons/PeriodSelectorButton";
import LeaderboardList from "../components/leaderboard/LeaderboardList";
import LeaderboardStanding from "../components/leaderboard/LeaderboardStanding";
import Container from "../components/utils/Container";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest, PostRequest } from "../scripts/ApiRequest";
import { isWebBigScreen } from "../scripts/ScreenSize";
import useProfile from "../scripts/userProfile";
import { LeaderboardRecord } from "../types/Leaderboard";
import { RootNativeStackParamList } from "../types/Screens";
import { Period } from "../types/Stat";

const SCREEN_WIDTH = Dimensions.get("window").width;

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuizLeaderboard">;
const QuizLeaderboardScreen = ({ route, navigation }: Props) => {
  const { showSnackBar } = useProfile();
  const { quizId }: { quizId: string } = route.params;
  const [period, setPeriod] = useState<Period>("last7day");

  useEffect(() => {
    navigation.setOptions({ title: `Quiz Leaderboard` });
  }, []);

  const getTagLeaderboard = async () => {
    const response = await GetRequest("/getQuizLeaderboard", {
      quizId,
      period: period,
    });
    return response.data;
  };

  const {
    isLoading: leaderboardLoading,
    data: leaderboardRecord,
    isError,
    refetch,
  }: { isLoading: boolean; isError: boolean; data?: LeaderboardRecord; refetch: any } = useQuery(
    `${quizId}${period}tagLeaderboard`,
    getTagLeaderboard,
    {
      staleTime: 60000,
    }
  );

  const onPeriodSelectorChange = (newValue: number) => {
    switch (newValue) {
      case 0:
        setPeriod("last7day");
        break;
      case 1:
        setPeriod("last30day");
        break;
      case 2:
        setPeriod("lifetime");
        break;
      default:
        setPeriod("last7day");
        break;
    }
  };

  const refetchCalculateLeaderboard = async () => {
    showSnackBar({ type: "SUCCESS", message: "Updating..." });

    await PostRequest("/calculateQuizLeaderboard", {
      quizId,
      period: period,
    });

    showSnackBar({ type: "SUCCESS", message: "Updated Successfully!" });

    refetch();
  };

  return (
    <Container>
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          backgroundColor: "#6A5AE0",
        }}
      >
        <View style={{ padding: 20 }}>
          <PeriodSelectorButton
            borderRadius={15}
            buttonTextColor={"#FFF"}
            containerBackground={"#4E42AA"}
            containerWidth={isWebBigScreen() ? 700 : Dimensions.get("window").width - 40}
            buttonActiveColor={"#9087E5"}
            buttonActiveTextColor={"#FFF"}
            containerPadding={5}
            buttonsLabels={["Weekly", "Monthly", "All Time"]}
            onChange={onPeriodSelectorChange}
          />
        </View>
        <ScrollView>
          <View style={{ position: "absolute", right: 20, zIndex: 9 }}>
            <Button mode="text" onPress={() => refetchCalculateLeaderboard()}>
              <MaterialCommunityIcons name="reload" size={20} color="rgba(255,255,255, 0.5)" />
            </Button>
          </View>
          <View style={{ padding: 20, marginTop: -20 }}>
            <LeaderboardStanding
              loading={leaderboardLoading}
              leaderboard={leaderboardRecord?.leaderboard || []}
            />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
              backgroundColor: "#EFEEFC",
              marginRight: 5,
              marginLeft: 5,
              borderRadius: 20,
              marginTop: -70,
              padding: 15,
              gap: 10,
            }}
          >
            {leaderboardLoading ? (
              <Skeleton width={"100%"} height={50} />
            ) : (
              <LeaderboardList leaderboard={leaderboardRecord?.leaderboard || []} />
            )}
          </View>
        </ScrollView>
      </View>
    </Container>
  );
};

export default QuizLeaderboardScreen;

import { Animated, DimensionValue, Dimensions, StyleProp, View, ViewStyle } from "react-native";

interface Props {
  testID?: string;
  width?: DimensionValue;
  height?: DimensionValue;
  style?: StyleProp<ViewStyle>;
}

function Skeleton({ testID, width = 100, height = 14, style }: Props) {
  const animatedValue = new Animated.Value(0);

  const animated = () => {
    animatedValue.setValue(0);
    Animated.timing(animatedValue, {
      toValue: 1,
      duration: 550,
      useNativeDriver: true,
    }).start(() => {
      setTimeout(() => {
        animated();
      }, 1000);
    });
  };

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [-10, Dimensions.get("window").width],
  });

  // const translateX2 = animatedValue.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [-10, 200],
  // });
  // const translateX3 = animatedValue.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [-10, 90],
  // });

  animated();

  return (
    <View
      testID={testID || "skeleton"}
      style={[
        {
          width: width,
          height: height,
          backgroundColor: "#ECEFF1",
          overflow: "hidden",
          opacity: 0.5,
        },
        style,
      ]}
    >
      <Animated.View
        style={{
          width: "30%",
          height: "100%",
          backgroundColor: "white",
          transform: [{ translateX: translateX }],
        }}
      ></Animated.View>
    </View>
  );
}

export default Skeleton;

import Constants from "expo-constants";
import { Linking, Platform, Text, View } from "react-native";
import { useQuery } from "react-query";
import semver from "semver";
import { GetRequest } from "../../scripts/ApiRequest";

function VersionCheck() {
  const getAppVersion = async () => {
    const response = await GetRequest("/getAppVersion");
    return response.data;
  };

  const haveUpdate = () => {
    if (!data) {
      return false;
    }

    const version =
      Constants.expoConfig && Constants.expoConfig.version ? Constants.expoConfig.version : "0";

    if (Platform.OS === "ios" && semver.gt(data["ios"], version)) {
      return true;
    }

    if (Platform.OS === "android" && semver.gt(data["android"], version)) {
      return true;
    }

    return false;
  };

  const { isLoading, data } = useQuery(`appVersion`, getAppVersion, { staleTime: 60000 });

  const appUrl =
    Platform.OS === "ios"
      ? "https://apps.apple.com/us/app/fune/id6470586383?platform=iphone"
      : "https://play.google.com/store/apps/details?id=app.fune.quiz";

  if (isLoading || !haveUpdate()) {
    return <></>;
  }

  return (
    <View
      style={{
        marginTop: 60,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Text>There is new version available.</Text>
      <Text
        style={{ color: "#fff", backgroundColor: "#6A5AE0", padding: 10, borderRadius: 20 }}
        onPress={() => Linking.openURL(appUrl)}
      >
        Update Now
      </Text>
    </View>
  );
}

export default VersionCheck;

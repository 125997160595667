import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Image, Text, TouchableOpacity, View } from "react-native";
import useProfile from "../../scripts/userProfile";
import { RootNativeStackParamList } from "../../types/Screens";
import NotificationIcon from "../home/NotificationIcon";

function SideBar() {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  const { user } = useProfile();

  const focused = true;

  const menus = [
    {
      title: "Home",
      screen: "Home",
      icon: <Ionicons name="home-outline" size={20} color={focused ? "#0C092A" : "#CCC"} />,
    },
    {
      title: "Profile",
      screen: "Profile",
      param: { userId: user?._id },
      icon: <MaterialCommunityIcons name="account-circle-outline" size={20} color="black" />,
    },
    {
      title: "True False",
      screen: "TrueFalseTags",
      icon: <Feather name="check-circle" size={20} color={focused ? "#0C092A" : "#CCC"} />,
    },
    {
      title: "Leaderboard",
      screen: "UserLeaderboard",
      icon: <MaterialIcons name="leaderboard" size={20} color={focused ? "#0C092A" : "#CCC"} />,
    },
    {
      title: "Learn",
      screen: "LearnTags",
      icon: (
        <MaterialCommunityIcons
          name="school-outline"
          size={20}
          color={focused ? "#0C092A" : "#CCC"}
        />
      ),
    },
    {
      title: "Create Quiz",
      screen: "QuizNew",
      icon: <Ionicons name="create-outline" size={20} color="black" />,
    },
    {
      title: "Join Quiz",
      screen: "QuizJoin",
      icon: <Feather name="play" size={24} color="black" />,
    },
    {
      title: "Setting",
      screen: "Setting",
      icon: <Ionicons name="settings-outline" size={20} color={focused ? "#0C092A" : "#CCC"} />,
    },
    {
      title: "Notification",
      screen: "Notifications",
      icon: <NotificationIcon size={20} iconColor={"#000"} />,
    },
  ];

  return (
    <View style={{ display: "flex", gap: 15, padding: 20 }}>
      {menus.map((menu) => (
        <TouchableOpacity
          key={menu.title}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
          onPress={
            //@ts-ignore
            () => navigation.navigate(menu.screen, menu.param)
          }
        >
          {menu.icon}
          <Text>{menu.title}</Text>
        </TouchableOpacity>
      ))}
      <Text style={{ textAlign: "center", marginTop: 10, fontWeight: "bold" }}>Download App</Text>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <Text>App Store</Text>
        <Image style={{ width: 120, height: 120 }} source={require("../../assets/AppStore.png")} />
        <Text>Google Play</Text>
        <Image
          style={{ width: 120, height: 120 }}
          source={require("../../assets/GooglePlay.png")}
        />
      </View>
    </View>
  );
}

export default SideBar;

import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { shuffle } from "lodash";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import { RootNativeStackParamList } from "../../types/Screens";
import { TagCount } from "../../types/Tag";
import Skeleton from "../utils/Skeleton";
import TagList from "./TagList";

const screenWidth = Dimensions.get("window").width;

function HotTags() {
  const navigation =
    useNavigation<NativeStackNavigationProp<RootNativeStackParamList, "TagList">>();

  const getTags = async () => {
    const response = await GetRequest("/getTags");
    return response.data;
  };

  const { isLoading: tagsLoading, data: tags }: { isLoading: boolean; data?: TagCount[] } =
    useQuery(`tags`, getTags, {
      staleTime: 60000,
    });

  if (tagsLoading || !tags) {
    return <Skeleton width={"100%"} height={100} style={{ borderRadius: 20 }} />;
  }

  return (
    <View testID="HotTags" style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>Hot Topics</Text>
        </View>
        <View>
          <Text
            style={styles.seeAll}
            onPress={() => {
              navigation.navigate("TagList", { tag: undefined });
            }}
          >
            See all
          </Text>
        </View>
      </View>
      <TagList
        tags={shuffle(tags?.filter((tag) => tag.count >= 10)).slice(0, 10)}
        navigateTo="Tag"
      />
    </View>
  );
}

export default HotTags;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    paddingTop: 20,
  },

  seeAll: {
    fontSize: 14,
    color: "#6A5AE0",
    fontWeight: "bold",
  },
});

import React from "react";

import { StyleSheet, View } from "react-native";
import { shouldShowSideBar } from "../../scripts/ScreenSize";
import SideBar from "../commons/Sidebar";

function Container(props: any) {
  const { style, children } = props;

  return (
    <View
      style={{
        backgroundColor: "#6A5AE0",
        height: "100%",
        // borderRadius: shouldShowSideBar() ? 0 : 20,
        padding: shouldShowSideBar() ? 20 : 0,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {shouldShowSideBar() && (
        <View
          style={{
            backgroundColor: "#FFF",
            width: 200,
            borderRightWidth: 1,
            borderRightColor: "#6A5AE0",
            borderRadius: 20,
          }}
        >
          <SideBar />
        </View>
      )}
      {children}
    </View>
  );
}

export default Container;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "#6A5AE0",
    // padding: 20,
    // height: "100%",
  },
});

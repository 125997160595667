import { Text, View } from "react-native";

function HorizontalLine() {
  return (
    <>
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <View style={{ height: 1, flex: 1, backgroundColor: "#CCC" }}></View>
        <Text
          style={{
            textAlign: "center",
            backgroundColor: "#FFF",
            paddingLeft: 10,
            paddingRight: 10,
            color: "#CCC",
          }}
        >
          OR
        </Text>
        <View style={{ height: 1, flex: 1, backgroundColor: "#CCC" }}></View>
      </View>
    </>
  );
}

export default HorizontalLine;

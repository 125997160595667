import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Platform, View } from "react-native";
import Greeting from "../components/home/Greeting";
import NotificationIcon from "../components/home/NotificationIcon";
import ProfileIcon from "../components/home/ProfileIcon";
import { isWebBigScreen, shouldHideHeaderBar } from "../scripts/ScreenSize";
import { RootNativeStackParamList } from "../types/Screens";
import ChangePasswordScreen from "./ChangePasswordScreen";
import ContactUsScreen from "./ContactUs";
import DebugScreen from "./DebugScreen";
import FeedbackScreen from "./FeedbackScreen";
import FeedbacksScreen from "./FeedbacksScreen";
import ForgetPasswordScreen from "./ForgetPasswordScreen";
import HomeScreen from "./HomeScreen";
import LearnScreen from "./LearnScreen";
import LearnTagsScreen from "./LearnTagsScreen";
import LoginScreen from "./LoginScreen";
import MyQuizesScreen from "./MyQuizesScreen";
import NotificationsScreen from "./NotificationScreen";
import OtherSettingsScreen from "./OtherSettingsScreen";
import ProfileScreen from "./ProfileScreen";
import QuestionAddScreen from "./QuestionAddScreen";
import QuestionCreateFromContextScreen from "./QuestionCreateFromContextScreen";
import QuestionCreateFromTopicScreen from "./QuestionCreateFromTopicScreen";
import QuestionCreateManualScreen from "./QuestionCreateManualScreen";
import QuestionEditScreen from "./QuestionEditScreen";
import QuestionListScreen from "./QuestionListScreen";
import QuestionPerformanceScreen from "./QuestionPerformanceScreen";
import QuestionScreen from "./QuestionScreen";
import QuestionTrueFalseScreen2 from "./QuestionTrueFalseScreen2";
import QuizCreateScreen from "./QuizCreateScreen";
import QuizEditScreen from "./QuizEditScreen";
import QuizJoinScreen from "./QuizJoinScreen";
import QuizLeaderboardScreen from "./QuizLeaderboardScreen";
import QuizListScreen from "./QuizListScreen";
import QuizNewScreen from "./QuizNewScreen";
import QuizPerformanceScreen from "./QuizPerformanceScreen";
import QuizResultScreen from "./QuizResultScreen";
import QuizScreen from "./QuizScreen";
import QuizShareScreen from "./QuizShareScreen";
import SettingScreen from "./SettingScreen";
import SignupScreen from "./SignupScreen";
import TagLeaderboardScreen from "./TagLeaderboardScreen";
import TagListScreen from "./TagListScreen";
import TagResultScreen from "./TagResultScreen";
import TagScreen from "./TagScreen";
import TrueFalseTagsScreen from "./TrueFalseTagsScreen";
import UpdateProfileScreen from "./UpdateProfileScreen";
import UserLeaderboardScreen from "./UserLeaderboardScreen";

const Stacks = createNativeStackNavigator<RootNativeStackParamList>();
export const StackScreens = ({
  initialRoute,
}: {
  initialRoute: keyof RootNativeStackParamList;
}) => {
  return (
    <Stacks.Navigator
      initialRouteName={initialRoute}
      screenOptions={{
        headerStyle: {
          backgroundColor: "#6A5AE0",
        },
        title: `Login${isWebBigScreen() ? " - FUNE" : ""}`,
        headerShown: !shouldHideHeaderBar(),
        headerTintColor: "#FFF",
        headerShadowVisible: false,
        headerTitleAlign: "center",
      }}
    >
      <Stacks.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerLeft: (props) => <Greeting />,
          headerRight: (props) => (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                marginRight: Platform.OS === "web" ? 15 : 0,
              }}
            >
              <NotificationIcon size={35} iconColor={"#FFF"} />
              <ProfileIcon size={35} iconColor={"#FFF"} />
            </View>
          ),
          title: `Home${isWebBigScreen() ? " - FUNE" : ""}`,
        }}
      />
      <Stacks.Screen
        name="Login"
        options={{
          title: `Login${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={LoginScreen}
      />
      <Stacks.Screen
        name="Signup"
        options={{
          title: `Signup${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={SignupScreen}
      />
      <Stacks.Screen
        name="ForgetPassword"
        options={{
          title: `Forget Password${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={ForgetPasswordScreen}
      />
      <Stacks.Screen
        name="Setting"
        options={{
          title: `Setting${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={SettingScreen}
      />
      <Stacks.Screen
        name="UserLeaderboard"
        component={UserLeaderboardScreen}
        options={{
          title: `User Leaderboard${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="TagLeaderboard"
        component={TagLeaderboardScreen}
        options={{
          title: `Tag Leaderboard${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizLeaderboard"
        component={QuizLeaderboardScreen}
        options={{
          title: `Quiz Leaderboard${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: `Profile${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: `Notification${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizResult"
        component={QuizResultScreen}
        options={{
          title: `Quiz Result${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizPerformances"
        component={QuizPerformanceScreen}
        options={{
          title: `Quiz Live Performances${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionPerformances"
        component={QuestionPerformanceScreen}
        options={{
          title: `Question Performances${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizList"
        component={QuizListScreen}
        options={{
          title: `Quiz List${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizNew"
        component={QuizNewScreen}
        options={{
          title: `Quiz New${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizCreate"
        component={QuizCreateScreen}
        options={{
          title: `Quiz Create${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizEdit"
        component={QuizEditScreen}
        options={{
          title: `Quiz Edit${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizShare"
        component={QuizShareScreen}
        options={{
          title: `Quiz Share${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuizJoin"
        component={QuizJoinScreen}
        options={{
          title: `Quiz Join${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="MyQuizes"
        component={MyQuizesScreen}
        options={{
          title: `My Quizes${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionAdd"
        component={QuestionAddScreen}
        options={{
          title: `Add Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionCreateManual"
        component={QuestionCreateManualScreen}
        options={{
          title: `Add Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionCreateFromContext"
        component={QuestionCreateFromContextScreen}
        options={{
          title: `Add Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionCreateFromTopic"
        component={QuestionCreateFromTopicScreen}
        options={{
          title: `Add Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionEdit"
        component={QuestionEditScreen}
        options={{
          title: `Edit Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="TagList"
        component={TagListScreen}
        options={{
          title: `Tag List${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="QuestionList"
        component={QuestionListScreen}
        options={{
          title: `Question List${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="TagResult"
        component={TagResultScreen}
        options={{
          headerShown: false,
          title: `Tag Result${isWebBigScreen() ? " - FUNE" : ""}`,
          // headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
      />
      <Stacks.Screen
        name="Question"
        options={{
          title: `Question${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={QuestionScreen}
      />
      <Stacks.Screen
        name="Quiz"
        options={{
          title: `Quiz${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={QuizScreen}
      />
      <Stacks.Screen
        name="Tag"
        options={{
          title: `Tag${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={TagScreen}
      />
      <Stacks.Screen
        name="ChangePassword"
        options={{
          title: "Change Password",
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={ChangePasswordScreen}
      />

      <Stacks.Screen
        name="UpdateProfile"
        options={{
          title: `Update Profile${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={UpdateProfileScreen}
      />
      <Stacks.Screen
        name="OtherSettings"
        options={{
          title: `Other Settings${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={OtherSettingsScreen}
      />
      <Stacks.Screen
        name="ContactUs"
        options={{
          title: `New Feedback${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={ContactUsScreen}
      />
      <Stacks.Screen
        name="TrueFalse"
        options={{
          title: `True False${isWebBigScreen() ? "- FUNE" : ""}`,
          headerBackTitle: "Back",
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={QuestionTrueFalseScreen2}
      />
      <Stacks.Screen
        name="TrueFalseTags"
        options={{
          title: `True False Tags${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={TrueFalseTagsScreen}
      />
      <Stacks.Screen
        name="Debug"
        options={{
          title: `Debug${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={DebugScreen}
      />
      <Stacks.Screen
        name="Learn"
        options={{
          title: `Login${isWebBigScreen() ? "- FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
          headerBackTitle: "Back",
        }}
        component={LearnScreen}
      />
      <Stacks.Screen
        name="LearnTags"
        options={{
          title: `Choose Learn Category${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={LearnTagsScreen}
      />
      <Stacks.Screen
        name="Feedbacks"
        options={{
          title: `Feedbacks${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={FeedbacksScreen}
      />
      <Stacks.Screen
        name="Feedback"
        options={{
          title: `Feedback${isWebBigScreen() ? " - FUNE" : ""}`,
          headerShown: !shouldHideHeaderBar(),
          headerTintColor: "#FFF",
        }}
        component={FeedbackScreen}
      />
    </Stacks.Navigator>
  );
};

import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { shuffle, startCase } from "lodash";
import { StyleSheet, View } from "react-native";
import { Text, TouchableRipple } from "react-native-paper";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { RootNativeStackParamList } from "../../types/Screens";
import { TagStats } from "../../types/Tag";
import Skeleton from "../utils/Skeleton";

function WorstPlayTags() {
  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();
  const titles = [
    "You can do better on these topics",
    "Let' improve these topics",
    "Let's try again on these topics",
  ];
  const { user } = useProfile();

  const getUserTagStats = async () => {
    const response = await GetRequest("/getUserTagsStats", { userId: user?._id });
    return response.data;
  };

  const { isLoading, data: tags }: { isLoading: boolean; data?: TagStats } = useQuery(
    `userTagStats`,
    getUserTagStats,
    { staleTime: 60000, enabled: typeof user !== "undefined" }
  );

  if (isLoading || !tags) {
    return <Skeleton width="100%" height={60} style={{ borderRadius: 20 }} />;
  }

  if (!isLoading && tags.leastPerform.length === 0) {
    return <></>;
  }

  return (
    <View
      testID="WorstPlayTags"
      style={{
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 20,
      }}
    >
      <View style={styles.subHeader}>
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>
              {shuffle(titles)[0]}
            </Text>
            <Text style={{ marginTop: 5, fontSize: 12, color: "#CCC" }}>
              You performed not well on these topics. Let's learn again.
            </Text>
          </View>
        </View>
      </View>
      <View
        testID="LeastPlayTagsList"
        style={{
          backgroundColor: "#C4D0FB",
          borderRadius: 20,
          padding: 16,
          marginTop: 20,
        }}
      >
        {tags &&
          shuffle(tags.leastPerform)
            .slice(0, 5)
            .map((tag) => (
              <TouchableRipple
                key={tag._id}
                rippleColor="rgba(0, 0, 0, .32)"
                style={{
                  borderRadius: 20,
                }}
                onPress={() => navigation.navigate("Learn", { tags: [tag._id] })}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // gap: 10,
                    padding: 10,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontWeight: "500",
                        paddingLeft: 10,
                        maxWidth: "100%",
                        color: "#000",
                      }}
                    >
                      {Math.round((tag.correctRatio || 0) * 100)}%
                    </Text>
                  </View>
                  <View style={{ flex: 1, flexWrap: "wrap" }}>
                    <Text
                      style={{
                        fontWeight: "500",
                        paddingLeft: 10,
                        maxWidth: "100%",
                        color: "#000",
                      }}
                    >
                      {startCase(tag._id)}
                    </Text>
                  </View>
                  <View>
                    <MaterialIcons
                      name="arrow-forward-ios"
                      size={15}
                      color="#6A5AE0"
                      style={{ marginTop: 3 }}
                    />
                  </View>
                </View>
              </TouchableRipple>
            ))}
      </View>
    </View>
  );
}

export default WorstPlayTags;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 20,
    paddingTop: 5,
  },
  section: {
    padding: 0,
    margin: 0,
  },
  subHeader: {
    // color: "#000",
  },
  seeAll: {
    fontSize: 14,
    color: "#6A5AE0",
    fontWeight: "bold",
  },
  item: {
    border: "2px solid #EFEEFC",
    marginTop: 15,
    borderRadius: 10,
  },
  itemText: {},

  titleStyle: {},
});

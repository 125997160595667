import { A } from "@expo/html-elements";
import { Text, View } from "react-native";
import { isWebBigScreen } from "../../scripts/ScreenSize";
import { getOsNameOnWeb } from "../utils/DetermineOSOnWeb";

function BannerAdsPlaceholder() {
  const InstallLink = () => {
    const platform = getOsNameOnWeb();

    if (platform === "ios") {
      return (
        <View
          style={{
            display: "flex",
            flexDirection: isWebBigScreen() ? "row" : "column",
            gap: 10,
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text>For Better Experience : </Text>
          <A href={`https://apps.apple.com/us/app/fune/id6470586383?platform=iphone`}>
            <Text
              style={{
                backgroundColor: "#6A5AE0",
                borderRadius: 15,
                color: "#FFF",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 5,
                paddingBottom: 8,
              }}
            >
              Install App
            </Text>
          </A>
        </View>
      );
    }

    if (platform === "android") {
      return (
        <View
          style={{
            display: "flex",
            flexDirection: isWebBigScreen() ? "row" : "column",
            gap: 10,
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text>For Better Experience : </Text>
          <A href={`https://play.google.com/store/apps/details?id=app.fune.quiz`}>
            <Text
              style={{
                backgroundColor: "#6A5AE0",
                borderRadius: 15,
                color: "#FFF",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 5,
                paddingBottom: 8,
              }}
            >
              Install App
            </Text>
          </A>
        </View>
      );
    }

    return (
      <View>
        <Text style={{ textAlign: "center" }}>Better Experience On App</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            marginTop: 20,
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              backgroundColor: "#6A5AE0",
              borderRadius: 15,
              color: "#FFF",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 8,
            }}
          >
            <A href={`https://play.google.com/store/apps/details?id=app.fune.quiz`}>
              Install Android App
            </A>
          </Text>
          <Text
            style={{
              backgroundColor: "#6A5AE0",
              borderRadius: 15,
              color: "#FFF",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 5,
              paddingBottom: 8,
            }}
          >
            <A href={`https://apps.apple.com/us/app/fune/id6470586383?platform=iphone`}>
              Install iOS App
            </A>
          </Text>
        </View>
      </View>
    );
    //https://apps.apple.com/us/app/fune/id6470586383?platform=iphone
    // https://play.google.com/store/apps/details?id=app.fune.quiz
  };

  return (
    <View style={{ justifyContent: "center", alignItems: "center", marginTop: 50 }}>
      <View style={{ height: 50, width: 320 }}>
        <InstallLink />
      </View>
    </View>
  );
}

export default BannerAdsPlaceholder;

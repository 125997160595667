import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import { Animated, Dimensions, PanResponder, Platform, StyleSheet, Text, View } from "react-native";
import { Button, IconButton } from "react-native-paper";
import { logEvent } from "../../scripts/analytic";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { Question } from "../../types/Question";
import { RootNativeStackParamList } from "../../types/Screens";
import MyBannerAd from "../commons/BannerAds";
import Skeleton from "../utils/Skeleton";

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;

function TrueFalseQuestionCard({
  totalQuestions,
  currentQuestionIndex,
  cardNumber,
  question,
  onSwipedComplete,
}: {
  totalQuestions: number;
  currentQuestionIndex: number;
  cardNumber: number;
  question: Question;
  onSwipedComplete: (
    questionId: string,
    isAnswerCorrect: boolean,
    correctAnswerText: string
  ) => void;
}) {
  // We should random choice only between 2 value, the correct option and a wrong option otherwise often it will random wrong as there is 3 wrong and 1 correct.
  const wrongOptions = question.options.filter((option) => option != question.answerText);
  const choices = [question.answerText, wrongOptions[1]]; //Now we left with only 2 option

  const randomNum = useRef(Math.random());

  const randomChoice = choices[Math.floor(randomNum.current * choices.length)]; // Random choice from optionss
  const correctOrWrongChoice = randomChoice == question.answerText ? "correct" : "wrong"; // Lets save as a variable if suggested answer is correct or wrong.

  const [xPosition, setXPosition] = useState(new Animated.Value(0));

  const [translations, setTranslations] = useState<string[]>([]);
  const { user, setDialog } = useProfile();

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  let cardOpacity = new Animated.Value(1);
  let rotateCard = xPosition.interpolate({
    inputRange: [-200, 0, 200],
    outputRange: ["-20deg", "0deg", "20deg"],
  });

  useEffect(() => {
    if (user?.autoTranslate && question) {
      translateQuestion();
    }
  }, [user, question]);

  let panResponder = PanResponder.create({
    onStartShouldSetPanResponder: (evt, gestureState) => false,
    onMoveShouldSetPanResponder: (evt, gestureState) => true,
    onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
    onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
    onPanResponderMove: (evt, gestureState) => {
      xPosition.setValue(gestureState.dx);
    },
    onPanResponderRelease: (evt, gestureState) => {
      const swipeRightSpeed = 1;
      const swipeLeftSpeed = -1.5;
      const swipeOffLeftLimit = 350;

      if (
        gestureState.dx < SCREEN_WIDTH - swipeOffLeftLimit &&
        gestureState.dx > -SCREEN_WIDTH + swipeOffLeftLimit &&
        gestureState.vx > swipeLeftSpeed &&
        gestureState.vx < swipeRightSpeed
      ) {
        Animated.spring(xPosition, {
          toValue: 0,
          speed: 5,
          bounciness: 10,
          useNativeDriver: false,
        }).start();
      } else if (
        gestureState.dx > SCREEN_WIDTH - swipeOffLeftLimit ||
        gestureState.vx > swipeRightSpeed
      ) {
        // swipped right
        swipeOffRight("swipe");
      } else if (
        gestureState.dx < -SCREEN_WIDTH + swipeOffLeftLimit ||
        gestureState.vx < swipeLeftSpeed
      ) {
        // swipped left
        swipeOffLeft("swipe");
      }
    },
  });

  const swipeOffRight = (type: string) => {
    logEvent("true_false_swipe", {
      userId: user?._id,
      questionId: question._id,
      direction: "right",
      answerCorrect: correctOrWrongChoice === "correct" ? true : false,
      type,
    });

    Animated.parallel([
      Animated.timing(xPosition, {
        toValue: SCREEN_WIDTH,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.timing(cardOpacity, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }),
    ]).start(() => {
      // swipped right mean swiped correct
      onSwipedComplete(
        question._id,
        correctOrWrongChoice === "correct" ? true : false,
        question.answerText || ""
      );
    });
  };

  const swipeOffLeft = (type: string) => {
    logEvent("true_false_swipe", {
      userId: user?._id,
      questionId: question._id,
      direction: "left",
      answerCorrect: correctOrWrongChoice === "correct" ? true : false,
      type,
    });

    Animated.parallel([
      Animated.timing(xPosition, {
        toValue: -SCREEN_WIDTH,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.timing(cardOpacity, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }),
    ]).start(() => {
      // swipped left
      onSwipedComplete(
        question._id,
        correctOrWrongChoice === "wrong" ? true : false,
        question.answerText || ""
      );
    });
  };

  const translateQuestion = async () => {
    const response = await GetRequest("/translateQuestion", {
      questionId: question._id,
      target: user?.language,
    });

    setTranslations(response.data);
  };

  const onTranslatePress = async () => {
    if (!user?.language) {
      setDialog({
        visible: true,
        title: "Please choose language",
        content: "It seems you haven't choose your preferred language. Choose now?",
        dismissLabel: "No",
        confirmLabel: "Yes",
        onDismiss: async () => {
          setDialog({ visible: false }); // If no then we will insert new quizuser and get that quizuserid
        },
        onConfirm: () => {
          setDialog({ visible: false });

          navigation.navigate("OtherSettings");
        },
      });
      return;
    }

    translateQuestion();
  };

  return (
    <Animated.View
      {...panResponder.panHandlers}
      style={[
        styles.card,
        {
          opacity: cardOpacity,
          transform: [{ translateX: xPosition }, { rotate: rotateCard }],
        },
      ]}
    >
      <View>
        <IconButton
          icon={"translate"}
          iconColor="#CCC"
          size={25}
          style={{
            margin: 0,
            position: "absolute",
            right: 10,
          }}
          onPress={() => onTranslatePress()}
          accessibilityLabel={"Translate"}
        />
        <Text
          style={{
            color: "#0C092A",
            marginTop: 20,
            fontWeight: "bold",
            fontSize: 20,
            paddingTop: 0,
            textAlign: "center",
          }}
        >
          {question.title}
        </Text>

        {totalQuestions - cardNumber === currentQuestionIndex ? (
          <View style={{ marginTop: 40 }}>
            <MyBannerAd />
          </View>
        ) : (
          <Skeleton style={{ marginTop: 40, height: 50, width: "100%" }}></Skeleton>
        )}
        {translations && (
          <Text style={{ color: "#BBB", padding: 20, textAlign: "center" }}>{translations[0]}</Text>
        )}
      </View>
      <View>
        <Text style={{ color: "#CCC", textAlign: "center" }}>Answer</Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 22,
            padding: 20,
            borderWidth: 3,
            borderColor: "#EEE",
            marginTop: 10,
            borderRadius: 20,
          }}
        >
          {randomChoice}
        </Text>
      </View>
      <View>
        <View
          style={{ marginTop: 50, justifyContent: "space-around", gap: 20, flexDirection: "row" }}
        >
          <Button
            testID={`FalseButton${cardNumber}`}
            mode="contained"
            buttonColor="#FF6666"
            labelStyle={{ color: "#fff" }}
            style={{ borderRadius: 15, padding: 10 }}
            onPress={() => swipeOffLeft("click")}
            accessibilityLabel={`Question ${cardNumber} False`}
          >
            FALSE
          </Button>
          <Button
            testID={`TrueButton${cardNumber}`}
            mode="contained"
            buttonColor="#53DF83"
            labelStyle={{ color: "#fff" }}
            style={{ borderRadius: 15, padding: 10 }}
            onPress={() => swipeOffRight("click")}
            accessibilityLabel={`Question ${cardNumber} True`}
          >
            TRUE
          </Button>
        </View>
        {Platform.OS !== "web" && (
          <Text style={{ textAlign: "center", fontSize: 12, paddingTop: 10, color: "#ccc" }}>
            *Swipe left or right
          </Text>
        )}
      </View>
    </Animated.View>
  );
}

export default TrueFalseQuestionCard;

const styles = StyleSheet.create({
  titleText: {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 20,
  },
  option: {
    marginTop: 16,
    padding: 5,
    borderRadius: 20,
    borderColor: "#EFEEFC",
    borderWidth: 2,
    textAlign: "left",
  },
  card: {
    position: "absolute",
    width: (SCREEN_WIDTH > 768 ? 768 : SCREEN_WIDTH) - 40,
    height: SCREEN_HEIGHT - (Platform.OS === "web" ? 150 : 240),
    borderRadius: 20,
    backgroundColor: "#fff",
    padding: 20,
    justifyContent: "space-between",
  },
});

import { Entypo, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { useQuery } from "react-query";
import { GetRequest } from "../../scripts/ApiRequest";
import useProfile from "../../scripts/userProfile";
import { NotificationRecord } from "../../types/Notification";
import { RootNativeStackParamList } from "../../types/Screens";

function NotificationIcon({
  size,
  style,
  iconColor,
}: {
  size: number;
  iconColor: string;
  style?: StyleProp<ViewStyle>;
}) {
  const [haveNoti, setHaveNoti] = useState<boolean>(false);

  const navigation = useNavigation<NativeStackNavigationProp<RootNativeStackParamList>>();

  const { user } = useProfile();

  const getUserNotifications = async () => {
    const response = await GetRequest("/getUserNotifications", { userId: user?._id });
    return response.data;
  };

  const { isLoading, data }: { isLoading: boolean; data?: NotificationRecord[] } = useQuery(
    `notifications`,
    getUserNotifications,
    {
      enabled: typeof user !== "undefined",
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    if (data) {
      const unseenNotis = data.filter((data) => data.seen === false);

      if (unseenNotis.length > 0) {
        setHaveNoti(true);
      } else {
        setHaveNoti(false);
      }
    }
  }, [data]);

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity onPress={() => navigation.navigate("Notifications")}>
        <Ionicons
          name="notifications-outline"
          size={size}
          color={iconColor}
          accessibilityLabel="Notification Bell"
        />
        {haveNoti && (
          <Entypo
            name="dot-single"
            size={size * 1.2}
            color={iconColor}
            style={{ position: "absolute", right: -6, top: -10 }}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}

export default NotificationIcon;

const styles = StyleSheet.create({
  container: {},
});

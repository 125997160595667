import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import Svg, { Circle, Defs, G, Mask, Path, RadialGradient, Stop } from "react-native-svg";
import { useQuery } from "react-query";
import Skeleton from "../components/utils/Skeleton";
import { GetRequest } from "../scripts/ApiRequest";
import { QuizUserRecord } from "../types/QuizUser";
import { RootNativeStackParamList } from "../types/Screens";

type Props = NativeStackScreenProps<RootNativeStackParamList, "QuizResult">;
function QuizResultScreen({ route, navigation }: Props) {
  const {
    quizUserId,
  }: {
    quizUserId: string;
  } = route.params;

  const getQuizUser = async () => {
    const response = await GetRequest("/getQuizUser", {
      _id: quizUserId,
    });
    return response.data;
  };

  const {
    isLoading: quizUserLoading,
    data: quizUser,
  }: { isLoading: boolean; data?: QuizUserRecord } = useQuery(
    `quizUser${quizUserId}`,
    getQuizUser,
    {
      staleTime: 60000,
    }
  );

  if (quizUserLoading) {
    return <Skeleton />;
  }

  if (!quizUser) {
    return (
      <View testID="QuizResultScreen" style={styles.container}>
        <Text style={{ textAlign: "center", fontSize: 30, fontWeight: "500" }}>Good Job!</Text>
        <View
          style={{ backgroundColor: "#FF8FA2", borderRadius: 15, marginTop: 16, paddingBottom: 20 }}
        >
          <Text style={{ textAlign: "center", color: "#fff", fontWeight: "500" }}>
            Oops, something went wrong. No result found.
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View testID="QuizResultScreen" style={styles.container}>
      <Text style={{ textAlign: "center", fontSize: 30, fontWeight: "500" }}>Good Job!</Text>
      <View
        style={{ backgroundColor: "#FF8FA2", borderRadius: 15, marginTop: 16, paddingBottom: 20 }}
      >
        <View style={{ alignItems: "center", padding: 24, paddingBottom: 10 }}>
          <Svg width="214" height="158" viewBox="0 0 214 158" fill="none">
            <Path
              d="M83.3161 14.2366L94.5068 22.4077L81.835 28.0136L83.3161 14.2366Z"
              fill="#C4D0FB"
            />
            <Path d="M147.164 17L171.164 3.14359L171.164 30.8564L147.164 17Z" fill="#FFD6DD" />
            <Path d="M55.6603 81L55.6603 98.3205L40.6603 89.6603L55.6603 81Z" fill="#C4D0FB" />
            <Circle cx="39" cy="34" r="8" stroke="#C9F2E9" strokeWidth="4" />
            <Mask id="path-5-inside-1_145_906" fill="white">
              <Path d="M16.0521 0.704839C17.9096 1.37121 19.5741 2.48539 20.8981 3.94869C22.2222 5.412 23.1649 7.17926 23.6428 9.09393C24.1207 11.0086 24.119 13.0116 23.6379 14.9254C23.1569 16.8393 22.2112 18.605 20.8847 20.0661C19.5582 21.5272 17.8919 22.6386 16.0333 23.3019C14.1747 23.9652 12.1812 24.1598 10.2294 23.8686C8.27755 23.5775 6.42767 22.8094 4.84359 21.6325C3.25951 20.4557 1.99012 18.9063 1.14786 17.1216L5.48872 15.073C5.99407 16.1438 6.75571 17.0734 7.70615 17.7795C8.6566 18.4857 9.76653 18.9465 10.9376 19.1212C12.1087 19.2959 13.3048 19.1791 14.42 18.7811C15.5351 18.3832 16.5349 17.7163 17.3308 16.8397C18.1267 15.963 18.6941 14.9036 18.9828 13.7553C19.2714 12.6069 19.2724 11.4052 18.9857 10.2564C18.6989 9.10755 18.1333 8.0472 17.3389 7.16922C16.5444 6.29123 15.5457 5.62272 14.4312 5.2229L16.0521 0.704839Z" />
            </Mask>
            <Path
              d="M16.0521 0.704839C17.9096 1.37121 19.5741 2.48539 20.8981 3.94869C22.2222 5.412 23.1649 7.17926 23.6428 9.09393C24.1207 11.0086 24.119 13.0116 23.6379 14.9254C23.1569 16.8393 22.2112 18.605 20.8847 20.0661C19.5582 21.5272 17.8919 22.6386 16.0333 23.3019C14.1747 23.9652 12.1812 24.1598 10.2294 23.8686C8.27755 23.5775 6.42767 22.8094 4.84359 21.6325C3.25951 20.4557 1.99012 18.9063 1.14786 17.1216L5.48872 15.073C5.99407 16.1438 6.75571 17.0734 7.70615 17.7795C8.6566 18.4857 9.76653 18.9465 10.9376 19.1212C12.1087 19.2959 13.3048 19.1791 14.42 18.7811C15.5351 18.3832 16.5349 17.7163 17.3308 16.8397C18.1267 15.963 18.6941 14.9036 18.9828 13.7553C19.2714 12.6069 19.2724 11.4052 18.9857 10.2564C18.6989 9.10755 18.1333 8.0472 17.3389 7.16922C16.5444 6.29123 15.5457 5.62272 14.4312 5.2229L16.0521 0.704839Z"
              stroke="#FFD6DD"
              strokeWidth="8"
              mask="url(#path-5-inside-1_145_906)"
            />
            <Mask id="path-6-inside-2_145_906" fill="white">
              <Path d="M169.056 72.3538C168.822 71.0593 168.91 69.7269 169.314 68.4747C169.717 67.2225 170.423 66.0891 171.369 65.175C172.316 64.2608 173.473 63.5941 174.738 63.2339C176.003 62.8737 177.338 62.8311 178.624 63.11C179.909 63.3888 181.106 63.9804 182.109 64.8323C183.111 65.6843 183.888 66.7704 184.371 67.9943C184.853 69.2182 185.026 70.5423 184.875 71.8492C184.724 73.156 184.252 74.4054 183.503 75.4865L180.215 73.2074C180.59 72.6668 180.826 72.0421 180.902 71.3887C180.977 70.7353 180.891 70.0732 180.649 69.4613C180.408 68.8493 180.02 68.3063 179.519 67.8803C179.017 67.4543 178.419 67.1585 177.776 67.0191C177.133 66.8797 176.466 66.901 175.833 67.0811C175.2 67.2612 174.622 67.5945 174.149 68.0516C173.676 68.5087 173.323 69.0754 173.121 69.7015C172.919 70.3276 172.875 70.9938 172.992 71.641L169.056 72.3538Z" />
            </Mask>
            <Path
              d="M169.056 72.3538C168.822 71.0593 168.91 69.7269 169.314 68.4747C169.717 67.2225 170.423 66.0891 171.369 65.175C172.316 64.2608 173.473 63.5941 174.738 63.2339C176.003 62.8737 177.338 62.8311 178.624 63.11C179.909 63.3888 181.106 63.9804 182.109 64.8323C183.111 65.6843 183.888 66.7704 184.371 67.9943C184.853 69.2182 185.026 70.5423 184.875 71.8492C184.724 73.156 184.252 74.4054 183.503 75.4865L180.215 73.2074C180.59 72.6668 180.826 72.0421 180.902 71.3887C180.977 70.7353 180.891 70.0732 180.649 69.4613C180.408 68.8493 180.02 68.3063 179.519 67.8803C179.017 67.4543 178.419 67.1585 177.776 67.0191C177.133 66.8797 176.466 66.901 175.833 67.0811C175.2 67.2612 174.622 67.5945 174.149 68.0516C173.676 68.5087 173.323 69.0754 173.121 69.7015C172.919 70.3276 172.875 70.9938 172.992 71.641L169.056 72.3538Z"
              stroke="#C4D0FB"
              strokeWidth="4"
              mask="url(#path-6-inside-2_145_906)"
            />
            <Circle cx="158" cy="117" r="8" stroke="#C9F2E9" strokeWidth="4" />
            <Mask id="path-8-inside-3_145_906" fill="white">
              <Path d="M25.2359 125.324C23.9346 125.131 22.701 124.62 21.6444 123.836C20.5878 123.052 19.7409 122.02 19.1786 120.83C18.6163 119.641 18.356 118.331 18.4208 117.017C18.4855 115.703 18.8734 114.426 19.5499 113.297C20.2264 112.169 21.1708 111.225 22.2994 110.549C23.4279 109.872 24.7058 109.485 26.0198 109.421C27.3339 109.356 28.6435 109.617 29.8327 110.18C31.0219 110.742 32.054 111.59 32.8375 112.647L29.6243 115.029C29.2325 114.5 28.7165 114.077 28.1219 113.795C27.5273 113.514 26.8725 113.384 26.2154 113.416C25.5584 113.448 24.9195 113.642 24.3552 113.98C23.7909 114.318 23.3187 114.79 22.9805 115.354C22.6422 115.918 22.4483 116.557 22.4159 117.214C22.3835 117.871 22.5137 118.526 22.7948 119.121C23.076 119.715 23.4994 120.232 24.0277 120.624C24.556 121.016 25.1728 121.271 25.8235 121.368L25.2359 125.324Z" />
            </Mask>
            <Path
              d="M25.2359 125.324C23.9346 125.131 22.701 124.62 21.6444 123.836C20.5878 123.052 19.7409 122.02 19.1786 120.83C18.6163 119.641 18.356 118.331 18.4208 117.017C18.4855 115.703 18.8734 114.426 19.5499 113.297C20.2264 112.169 21.1708 111.225 22.2994 110.549C23.4279 109.872 24.7058 109.485 26.0198 109.421C27.3339 109.356 28.6435 109.617 29.8327 110.18C31.0219 110.742 32.054 111.59 32.8375 112.647L29.6243 115.029C29.2325 114.5 28.7165 114.077 28.1219 113.795C27.5273 113.514 26.8725 113.384 26.2154 113.416C25.5584 113.448 24.9195 113.642 24.3552 113.98C23.7909 114.318 23.3187 114.79 22.9805 115.354C22.6422 115.918 22.4483 116.557 22.4159 117.214C22.3835 117.871 22.5137 118.526 22.7948 119.121C23.076 119.715 23.4994 120.232 24.0277 120.624C24.556 121.016 25.1728 121.271 25.8235 121.368L25.2359 125.324Z"
              stroke="#FFD6DD"
              strokeWidth="4"
              mask="url(#path-8-inside-3_145_906)"
            />
            <Circle cx="211" cy="47" r="3" fill="#C4D0FB" />
            <Circle cx="9" cy="63" r="3" fill="#C4D0FB" />
            <Path
              d="M155.672 39.875H143.094V34.4062C143.094 33.8047 142.602 33.3125 142 33.3125H72C71.3984 33.3125 70.9062 33.8047 70.9062 34.4062V39.875H58.3281C56.7327 39.875 55.2026 40.5088 54.0744 41.6369C52.9463 42.7651 52.3125 44.2952 52.3125 45.8906V66.125C52.3125 77.2949 60.5156 86.5781 71.207 88.2734C73.3125 104.16 86.082 116.684 102.078 118.42V132.803H75.2812C72.8613 132.803 70.9062 134.758 70.9062 137.178V141.594C70.9062 142.195 71.3984 142.688 72 142.688H142C142.602 142.688 143.094 142.195 143.094 141.594V137.178C143.094 134.758 141.139 132.803 138.719 132.803H111.922V118.42C127.918 116.684 140.687 104.16 142.793 88.2734C153.484 86.5781 161.688 77.2949 161.688 66.125V45.8906C161.688 44.2952 161.054 42.7651 159.926 41.6369C158.797 40.5088 157.267 39.875 155.672 39.875ZM70.9062 78.1016C65.834 76.4746 62.1562 71.7168 62.1562 66.125V49.7188H70.9062V78.1016ZM151.844 66.125C151.844 71.7305 148.166 76.4883 143.094 78.1016V49.7188H151.844V66.125Z"
              fill="url(#paint0_angular_145_906)"
            />
            <Mask
              id="mask0_145_906"
              maskUnits="userSpaceOnUse"
              x="87"
              y="55"
              width="40"
              height="40"
            >
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.63 63.8887C125.304 66.3945 126.379 69.228 126.799 72.1717C127.024 73.7475 127.062 75.3548 126.904 76.9604C126.543 80.6187 125.184 84.0879 123 87.0001C122.435 87.7535 121.815 88.4697 121.142 89.1422L117.789 85.789L114.629 82.629C116.394 80.864 117.492 78.5416 117.737 76.0576C117.952 73.8786 117.498 71.6936 116.447 69.789C116.3 69.5222 116.141 69.261 115.971 69.0061C114.584 66.9307 112.521 65.4003 110.132 64.6757C107.743 63.9511 105.177 64.0772 102.871 65.0324C100.565 65.9876 98.6618 67.7129 97.4851 69.9142C96.3085 72.1155 95.9315 74.6568 96.4185 77.1049C96.4783 77.4056 96.5507 77.7026 96.6353 77.9953C97.2391 80.085 98.4632 81.951 100.156 83.34C102.085 84.9235 104.504 85.789 107 85.789V85.8001L111.583 90.5232L116.167 95H107.211H107V95.0001C106.049 95.0001 105.104 94.9323 104.172 94.7991C100.568 94.2843 97.1539 92.7923 94.3122 90.4603C93.0651 89.4368 91.9552 88.2735 91.0001 87.0001C89.216 84.6212 87.9724 81.8577 87.3844 78.9019C86.4817 74.3637 87.1805 69.6529 89.3617 65.5721C91.5429 61.4914 95.0716 58.2932 99.3464 56.5225C103.621 54.7518 108.378 54.5181 112.806 55.8613C117.234 57.2044 121.059 60.0414 123.63 63.8887Z"
                fill="white"
              />
            </Mask>
            <G mask="url(#mask0_145_906)">
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.909 96.6667L131.333 68.8221L125.187 63L92.6665 95.1479L102.909 96.6667Z"
                fill="white"
              />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.155 102.667L136.333 74.8221L130.241 69L98 101.148L108.155 102.667Z"
                fill="white"
              />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.3396 94.0413L126.284 61.8645L120.685 55.8495L88.7406 88.0263L94.3396 94.0413Z"
                fill="white"
              />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.8438 88.6667L120.667 56.5414L112.398 53L84.3335 81.331L88.8438 88.6667Z"
                fill="white"
              />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.3335 81.3333L112.667 53L97.4883 54.5179L84.3335 68.9375V81.3333Z"
                fill="white"
              />
            </G>
            <Defs>
              {/*@ts-ignore*/}
              <RadialGradient
                id="paint0_angular_145_906"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(107 88) rotate(-90) scale(23)"
              >
                {/*@ts-ignore*/}
                <Stop stopColor="#FFD317" />
              </RadialGradient>
            </Defs>
          </Svg>
        </View>
        <Text style={{ textAlign: "center", color: "#fff", fontWeight: "500" }}>
          You get {Math.round(quizUser.totalPoint)} Quiz Points
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          gap: 20,
          justifyContent: "space-between",
          marginTop: 20,
          padding: 10,
        }}
      >
        <View style={{ flexDirection: "column", gap: 20 }}>
          <View style={styles.statContainer}>
            <Text style={styles.label}>CORRECT ANSWER</Text>
            <View style={styles.valueContainer}>
              <Text style={styles.value}>{quizUser.totalCorrect}</Text>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Text style={styles.label}>CORRECT RATIO</Text>
            <View style={styles.valueContainer}>
              <Text style={styles.value}>
                {Math.round((quizUser.totalCorrect / quizUser.totalVote) * 100)}%
              </Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "column", gap: 20 }}>
          <View style={styles.statContainer}>
            <Text style={styles.label}>WRONG ANSWER</Text>
            <View style={styles.valueContainer}>
              <Text style={styles.value}>{quizUser.totalWrong}</Text>
            </View>
          </View>
          <View style={styles.statContainer}>
            <Text style={styles.label}>TOTAL VOTE</Text>
            <View style={styles.valueContainer}>
              <Text style={styles.value}>{quizUser.totalVote}</Text>
            </View>
          </View>
        </View>
      </View>
      <Button
        mode="contained"
        buttonColor="#6A5AE0"
        textColor="#fff"
        style={{ borderRadius: 15 }}
        onPress={() => navigation.navigate("QuizLeaderboard", { quizId: quizUser.quizId })}
      >
        VIEW LEADERBOARD
      </Button>
      <Button
        mode="outlined"
        textColor="#6A5AE0"
        style={{ borderRadius: 15, marginTop: 20, borderColor: "#6A5AE0" }}
        onPress={() => navigation.popToTop()}
      >
        DONE
      </Button>
    </View>
  );
}

export default QuizResultScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 24,
    paddingTop: Platform.OS === "web" ? 40 : 120,
  },

  statContainer: {},

  label: {
    color: "#858494",
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },

  valueContainer: {
    backgroundColor: "#EFEEFC",
    borderRadius: 5,
    marginTop: 10,
  },

  value: {
    color: "#6A5AE0",
    fontWeight: "bold",
    marginTop: 5,
    textAlign: "center",
    padding: 10,
    fontSize: 20,
  },
});
